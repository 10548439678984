import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	notificationListSidebar,
	chatListSidebar,
} from '../../../../actions/sidebars';
import navClose from '../../../../assets/images/svg/nav-close.svg';
import NotificationListItem from './NotificationListItem';
import moment from 'moment';
import { convertDateForMessage } from '../../../../utils/date';
import { friendDeclineForm, friendAcceptForm } from '../../../../actions/forms';
import {
	onRemoveFriendNotitication,
	onNotificationDeactivated,
} from '../../../../actions/notification';
import { openChatHistory } from '../../../../actions/chathistory';

// import profilePic48 from "../../../../assets/images/avatar-demo.png";
import './NotificationList.scss';
import { followForm } from '../../../../actions/forms';
import { withTranslation } from 'react-i18next';

class NotificationList extends Component {
	constructor(props) {
		super(props);
		this.hideRightSidebar = this.hideRightSidebar.bind(this);
		this.getNotificationList = this.getNotificationList.bind(this);
		this.removeNotification = this.removeNotification.bind(this);
	}

	hideRightSidebar() {
		this.props.notificationListSidebarClose();
	}

	removeNotification(notificationId) {
		this.props.onNotificationDeactivated(notificationId);
	}

	// addDefaultSrc(ev) {
	//   ev.target.src = profilePic48;
	// }

	getNoNotifications() {
		return (
			<div className='noNotifications'>
				<div className='noNotificationsText'>
					<p style={{ padding: '10px' }}>
						{this.props.t('noNotificationsText')}
					</p>
				</div>
			</div>
		);
	}

	getUserNotifications() {
		if (
			!this.props.notifications.friends ||
			(this.props.notifications.friends.constructor === Array &&
				this.props.notifications.friends.length === 0)
		) {
			return null;
		}
		let notificationText, buttons;
		const { allOnlineUserList, t } = this.props;
		const list = this.props.notifications.friends.map((notification, index) => {
			const createdAt = convertDateForMessage(notification.createdAt, true);
			switch (notification.type) {
				case 'requestReceived':
					notificationText = (
						<span>
							{React.createElement(
								'span',
								{ className: 'bold' },
								notification.name
							)}{' '}
							sent you friend request
						</span>
					);

					buttons = (
						<div className='row col-12 justify-content-end'>
							<div>
								<button
									onClick={(e) => {
										e.preventDefault();
										this.props.friendAcceptFormSubmit({
											requestorId: notification.profileId,
										});
										this.props.onRemoveFriendNotitication(notification.id);
									}}
									className='btn btn-secondary rounded-xs w-100'
								>
									{t('accept')}
								</button>
							</div>
							<div className='ml-1'>
								<button
									className='btn btn-outline-secondary rounded-xs  align-items-center justify-content-center btn-block'
									onClick={(e) => {
										e.preventDefault();
										this.props.friendDeclineFormSubmit({
											requestorId: notification.profileId,
										});
										this.props.onRemoveFriendNotitication(notification.id);
									}}
								>
									{t('cancel')}
								</button>
							</div>
						</div>
					);
					break;

				case 'requestAccepted':
					notificationText = (
						<span>
							You and{' '}
							{React.createElement(
								'span',
								{ className: 'bold' },
								notification.name
							)}{' '}
							are now friends
						</span>
					);

					buttons = (
						<div className='col-3 ml-auto'>
							<button
								onClick={(e) => {
									e.preventDefault();
									this.props.notificationListSidebarClose();
									this.props.chatListSidebarOpen();
									this.props.openChatHistory({
										id: notification.requesteeId,
										userName: notification.name,
										profileUrl: notification.avatarUrl,
									});
									this.props.onNotificationDeactivated(notification.id);
								}}
								className={
									'btn  rounded-xs  align-items-center justify-content-center btn-block startChatBtnMinimum btn-outline-secondary'
								}
							>
								{t('startChat')}
							</button>
						</div>
					);
					break;

				case 'requestSent':
					notificationText = (
						<span>
							Friend request sent to{' '}
							{React.createElement(
								'span',
								{ className: 'bold' },
								notification.name
							)}{' '}
						</span>
					);

					buttons = '';
					break;
			}
			return (
				<div
					className='row no-gutters d-flex justify-content-between align-items-center message-list bb-1 position-relative'
					key={index}
				>
					<div className='media col'>
						<figure>
							<img
								className='profile-pic-48'
								src={
									notification.avatarUrl
										? notification.avatarUrl
										: 'https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png'
								}
								alt={notification.name}
								onError={(e) =>
									(e.target.src =
										'https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png')
								}
							/>

							{notification.type === 'requestAccepted' ? (
								allOnlineUserList.includes(notification.requesteeId) ? (
									<figcaption className='online-48' />
								) : null
							) : null}
							{notification.type === 'requestReceived' ? (
								allOnlineUserList.includes(notification.profileId) ? (
									<figcaption className='online-48' />
								) : null
							) : null}
						</figure>

						<div className='media-body ml-3'>
							<h6>{notificationText}</h6>
							<p className='mb-0 fs-12 time-indication'>{createdAt}</p>
						</div>
					</div>
					{buttons}

					{notification.type !== 'requestReceived' ? (
						<div
							className='close_notification'
							onClick={(e) => {
								e.preventDefault();
								this.removeNotification(notification.id);
							}}
						>
							<span className='a icon-close'>
								<img src={navClose} alt='' />
							</span>
						</div>
					) : null}
				</div>
			);
		});

		return (
			<div>
				{/* <div className="d-flex  justify-content-between align-items-center message-header">
      <h5 className="bold ml-4">Friend Requests</h5>
      </div> */}
				{list}
			</div>
		);
	}

	getNotificationList(list) {
		return list.map((notification) => {
			return (
				<NotificationListItem
					key={notification.id}
					userId={this.props.user.id}
					event={notification.payload.event}
					isRead={notification.isRead}
					content={notification.content}
					title={notification.title}
					link={notification.link || {}}
					payload={notification.payload || {}}
					id={notification.id}
					type={notification.type}
					createdAt={notification.createdAt}
					startAt={notification.startAt || moment().format()}
					action={notification.action || ''}
					actionButton={notification.actionButton}
					router={this.props.router}
					notificationsOpen={this.props.notifications.open}
					onNotificationsOpen={this.props.onNotificationsOpen}
					onNotificationDeactivated={this.props.onNotificationDeactivated}
					followFormSubmit={this.props.followFormSubmit}
					userFollowingsList={this.props.userFollowingsList}
					allOnlineUserList={this.props.allOnlineUserList}
					t={this.props.t}
				/>
			);
		});
	}

	render() {
		let challengeNotificationArray = [];
		let listNotificationArray = [];
		this.props.notifications.list.map((notification) => {
			if (
				notification.payload.event === 'invitation received' ||
				notification.payload.event === 'today challenge'
			) {
				challengeNotificationArray.push(notification);
			} else {
				listNotificationArray.push(notification);
			}
		});

		// const challengeNotification = this.props.notifications.list.filter(item => item.payload.event === "invitation received" || item.payload.event === "today challenge");
		// console.log(this.props.notifications.list,"listing");
		// console.log(challengeNotification,"challengeNotification");
		const friendsNotifFound =
			this.props.notifications.friends !== undefined
				? this.props.notifications.friends.length
				: 0;
		const { t } = this.props;
		return (
			<>
				<nav
					className={
						'chat-list-slider' +
						(this.props.isNotificationListSidebarOpen ? ' active' : '')
					}
				>
					<div className='fixed-sidebar-scroll-landscap chat-screen'>
						{/* <div className="dismiss">
              <span className="icon-close">
                <img src={navClose} alt="" onClick={this.hideRightSidebar} />
              </span>
            </div> */}
						<section className='d-flex flex-column '>
							<section className='row no-gutters'>
								<div className='col-sm-12 text-center'>
									<div className='sidebar-header'>
										<div className='d-flex  justify-content-between align-items-center message-header'>
											<h6 className='bold'>{t('notifications')}</h6>
											<span className='icon-close'>
												<img
													src={navClose}
													alt=''
													onClick={this.hideRightSidebar}
												/>
											</span>
										</div>
										<form action='#'>
											<div className='form-group without-lable '>
												<div className='input-group-wrapper search'>
													<div className='effect-1' />
												</div>
											</div>
										</form>
									</div>
								</div>
							</section>
							<section className='message-info'>
								{challengeNotificationArray
									? this.getNotificationList(challengeNotificationArray)
									: null}
								{friendsNotifFound === 0 ? null : this.getUserNotifications()}
								{
									listNotificationArray
										? this.getNotificationList(listNotificationArray)
										: null
									//listNotificationArray ? this.getNoNotifications() : this.getNotificationList()
								}

								{listNotificationArray.length === 0 &&
								challengeNotificationArray.length === 0
									? this.getNoNotifications()
									: null}
							</section>
						</section>
					</div>
				</nav>
			</>
		);
	}
}
const mapDispatchToProps = {
	disableOuterClick: true,
	notificationListSidebarOpen: notificationListSidebar.open,
	notificationListSidebarClose: notificationListSidebar.close,
	chatListSidebarOpen: chatListSidebar.open,
	friendDeclineFormSubmit: friendDeclineForm.submit,
	friendDeclineFormReset: friendDeclineForm.reset,
	onRemoveFriendNotitication: onRemoveFriendNotitication,
	onNotificationDeactivated: onNotificationDeactivated,
	friendAcceptFormSubmit: friendAcceptForm.submit,
	friendAcceptFormReset: friendAcceptForm.reset,
	openChatHistory: openChatHistory,
	followFormSubmit: followForm.submit,
};

const mapStateToProps = (state) => {
	return {
		isNotificationListSidebarOpen: state.sidebars.isNotificationListSidebarOpen,
		notifications: state.notifications,
		user: state.user.item,
		friendDeclineForm: state.forms.friendDeclineForm,
		friendAcceptForm: state.forms.friendAcceptForm,
		userFollowingsList: state.profiles.followingsList.items,
		allOnlineUserList: state.socketusers.allOnlineUserList,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(NotificationList));
