import React, { Component } from 'react';
import './ModalV2.scss';
import IconClose from '../../assets/images/svg/nav-close.svg';
import UpdateDimension from '../Utils/UpdateDimension';
import platformParams from '../../utils/platformParams';

class ModalV2 extends Component {
	constructor(props) {
		super(props);
		this.hideProxy = this.hideProxy.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.state = {
			isPortrait: false,
		};
	}
	componentWillMount() {
		if (!this.props.disableOuterClick) {
			document.addEventListener('mousedown', this.hideProxy, false);
		}
	}
	componentWillUnmount() {
		if (!this.props.disableOuterClick) {
			document.removeEventListener('mousedown', this.hideProxy, false);
		}
	}
	updateDimensions() {
		this.setState({
			isPortrait: window.innerHeight >= 530 && window.innerWidth > 320,
		});
	}
	componentDidMount() {
		this.updateDimensions();
	}
	hideProxy = (e) => {
		if (this.props.disableOuterClick) {
			return;
		}
		if (
			(this.modalWeb && this.modalWeb.contains(e.target)) ||
			(this.modalMobile && this.modalMobile.contains(e.target))
		) {
			return;
		}
		if (this.props.isOpen) {
			this.props.handleHide();
		}
		return;
	};

	render() {
		return (
			<>
				<UpdateDimension onUpdate={this.updateDimensions} hasSetState={true}>
					{this.props.isOpen ? (
						<div className='d-block d-lg-none'>
							<div className='modalV2'>
								<div
									className={`container-fluid popup-responsive  ${
										this.state.isPortrait ? ' position-fixed' : ''
									}`}
									ref={(modal) => {
										this.modalMobile = modal;
									}}
								>
									<div className='row'>
										<div className='container'>
											<div className='col-sm-12 pt-4'>
												{this.props.children}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</UpdateDimension>
				<div className='d-lg-block'>
					<div
						className={
							this.props.modalClass +
							' fade modalV2' +
							(this.props.isOpen ? ' show' : '')
						}
						tabIndex='-1'
						style={
							this.props.isOpen ? { display: 'block' } : { display: 'none' }
						}
					>
						<div
							className={this.props.modalDialogClass}
							ref={(modal) => {
								this.modalWeb = modal;
							}}
						>
							<div className={this.props.modalContentClass}>
								<div className={this.props.modalBodyClass}>
									<div className='modal-head'>
										<div></div>
										<img
											src={platformParams['headerLogoSvg']}
											alt=''
											className='mainLogo'
										/>
										<div></div>
									</div>
									{this.props.showCloseBtn ? (
										<div
											className='popup-dismiss'
											data-dismiss='modal'
											aria-label='Close'
											onClick={this.props.handleHide}
										>
											<img src={IconClose} alt='' />
										</div>
									) : (
										''
									)}

									{this.props.children}
								</div>
							</div>
						</div>
					</div>
					{this.props.backdrop && this.props.isOpen ? (
						<div className='modal-backdrop fade show' />
					) : (
						''
					)}
				</div>
			</>
		);
	}
}
const defaultProps = {
	disableOuterClick: false,
	backdrop: true,
	isOpen: false,
	handleHide: () => {},
	modalClass: 'modal',
	modalDialogClass: 'modal-dialog modal-dialog-centered',
	modalContentClass: 'modal-content',
	modalBodyClass: 'modal-body',
	modalCrossSvgClass: false,
	showCloseBtn: true,
	withError: false,
	showSocialBtns: true,
};
ModalV2.defaultProps = defaultProps;
export default ModalV2;
