import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import navArrowDown from "./../../assets/images/svg/retro-nav-arrow-down.svg";
import "./Select2Component.scss";

class Select2Component extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);    
    this.handleChange = this.handleChange.bind(this);
    
    this.state = {
      dropdownListVisible: false,
    };
  }

  handleChange(event, id) {
    // console.log(event.currentTarget.textContent, id);
    const text = event.currentTarget.textContent;    
    this.props.onChange(text, id);

    this.setState({
      dropdownListVisible: false
    });
  }

  componentWillUnmount(){
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  handleClick() {
    if (!this.state.dropdownListVisible) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      dropdownListVisible: !prevState.dropdownListVisible
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.setState(prevState => ({
      dropdownListVisible: false
    }));
  }

  render() {
    return (
      <div className="select2">
        <div
          className={
            this.state.dropdownListVisible
              ? "dropdown dropdown--open"
              : "dropdown"
          }
          ref={node => {
            this.node = node;
          }}
        >
          <input
            type="text"
            className="form-control effect-16 select-2-input"
            name="seen-value"
            placeholder={this.props.labelText}
            readOnly            
            onClick={this.handleClick}
            value={this.props.selectedValue}
            tabIndex={-1}
          />
          <img
            src={navArrowDown}
            className={
              this.state.dropdownListVisible
                ? "down-arrow-dropdown openArrow"
                : "down-arrow-dropdown"
            }
            alt=""
            onClick={this.handleClick}
          />
          <input
            type="hidden"
            className="form-control"
            name="hidden-value"            
            readOnly
            tabIndex={-1}
          />
          <span className="focus-border" />
          <div className="dropdown__list">
            <p className="selection-heading">{this.props.labelText}</p>
            <ul className="list-unstyled">
              {this.props.selectOptions.map(msgTemplate => {
                return (
                  <li
                    key={msgTemplate.id}
                    data-value={msgTemplate.value}
                    value={msgTemplate.value}                    
                    onClick={e => this.handleChange(e, msgTemplate.id)}
                  >
                    {msgTemplate.value}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Select2Component.defaultProps = {
  labelText : "-- Please Select --"
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Select2Component)
);
