import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { login, register } from "../../../../actions/modals";
import {
  messageListSidebar,
  notificationListSidebar
} from "../../../../actions/sidebars";
import moment from "moment";
import { toggleHyveRegister } from "../../../../actions/toggles";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { unsubscribe } from "../../../../actions/modals";
import { isNgCountry } from "../../../../utils/misc";
import platformParams from "../../../../utils/platformParams";
import { famobi_detection } from "../../../../utils/famobiDetect";
import Select2Component from "../../../../components/Select2/Select2Component";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.deviceInfo = famobi_detection;
    this.state = { 
      toggleMenu: false,
      currentLanguage: 'English',
      languageOptions: [      
        {id: 'en', value: 'English'}, 
        {id: 'bn', value: 'Bengali'},
        {id: 'zh_cn', value: 'Chinese'},
        {id: 'fr', value: 'French'}, 
        {id: 'hn', value: 'Hindi'},
        {id: 'pr', value: 'Portuguese (Brazil)'},
        {id: 'rs', value: 'Russian'},
        {id: 'es', value: 'Spanish (Latin America)'}
      ]
    };
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentDidMount() {    
    // console.log(i18n.languages);
    // console.log(i18n.language);
    this.getCurrentLanguage();
  }

  getCurrentLanguage() {
    for (var i = 0; i < this.state.languageOptions.length; i++) {
      if(i18n.language === this.state.languageOptions[i].id) {
        return (
          this.setState({
            currentLanguage: this.state.languageOptions[i].value
          })
        );
      }
      else {
        this.setState({
          currentLanguage: 'English'
        });
      }
    }
  }

  handleLanguageChange(value, id) {
    i18n.changeLanguage(id);
    this.setState({
      currentLanguage: value
    });
  }

  render() {
    const { t } = this.props;
    const {currentLanguage, languageOptions} = this.state;

    let fullScreenClass = "d-block";
    if (this.props.isGameFullScreen) {
      fullScreenClass = "d-none";
    }
    const totalNoficationUnread =
      this.props.notifications.unread + this.props.notifications.unreadFriends;
    const lang = i18n.language;
    return (
      <>
        <footer
          className={
            "container-fluid bg-gradient-white" +
            (this.props.match.path === "/" ? "" : " d-none d-lg-block")
          }
        >
          <div className="row">
            <div className="container">
              <div className="row">
                <section
                  className={
                    "col-md-4 d-flex flex-column justify-content-center justify-content-md-start footer-sec-1 pl-custom" +
                    platformParams['className']
                  }
                >
                  <div className="footer-logo">
                    <img
                      src={platformParams['logoFooterImage']}
                      alt=""
                      width="100%"
                    />
                  </div>

                  <div className="select-dropdown">
                    <p>Language: </p>
                    <Select2Component
                      labelText="Select language"
                      selectOptions={languageOptions}
                      onChange={this.handleLanguageChange}
                      selectedValue={currentLanguage}
                    />
                  </div>
                </section>
                <section
                  className={
                    "col-md-3 d-flex justify-content-center justify-content-md-start footer-sec-2 " +
                    platformParams['className']
                  }
                >
                  {this.props.isLoggedIn ? (
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <h4>{t("play")}</h4>
                      </li>
                      <li>
                        <a href={"/" + lang + "/challenges/live"}>
                          {t("liveTournaments")}
                        </a>
                      </li>
                      <li>
                        <a href={"/" + lang + "/challenges/upcoming"}>
                          {t("upcomingTournaments")}
                        </a>
                      </li>
                      <li>
                        <a href={"/" + lang + "/challenges/quickplay"}>
                          {t("quickPlay")}
                        </a>
                      </li>
                      { !isNgCountry() ?
                        <li>
                          <a href={"/" + lang + "/giveaways"}>
                            {t("giveaways")}
                          </a>
                        </li>
                        : <></>
                      }
                    </ul>
                  ) : (
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <h4>{t("play")}</h4>
                      </li>
                      <li>
                        <a href="https://staging.miggster.join.arcadex.co" className="a">
                          {t("live")} {t("navTournaments")}
                        </a>
                      </li>
                      <li>
                        <a href="https://staging.miggster.join.arcadex.co" className="a">
                          {t("upcoming")} {t("navTournaments")}
                        </a>
                      </li>
                      <li>
                        <a href="https://staging.miggster.join.arcadex.co" className="a">
                          {t("quickPlay")}
                        </a>
                      </li>
                      {
                        !isNgCountry() ?
                          <li>
                            <a href="https://staging.miggster.join.arcadex.co" className="a">
                              {t("giveaways")}
                            </a>
                          </li>
                          : <></>
                      }

                    </ul>
                  )}
                </section>
                <section
                  className={
                    "col-md-3 pl-custom d-flex justify-content-center justify-content-md-start footer-sec-3 " +
                    platformParams['className']
                  }
                >
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <h4>{t("support")}</h4>
                    </li>
                    {this.props.isLoggedIn ? null : (
                      <li>
                        {/* <span
                          className="a"
                          onClick={() => {
                            this.props.resetHyveRegister();
                            this.props.registerOpen();
                          }}
                        >
                          Join Now
                        </span> */}
                        <a href="https://staging.miggster.join.arcadex.co" className="a">
                          {t("joinNow")}
                        </a>
                      </li>
                    )}

                    {/* <li>
                      <a href={this.props.isLoggedIn ?"/dashboard":"/"}>Premium Account</a>
                    </li> */}
                    <li>
                      <a href={"/" + lang + "/help-support"}>
                        {t("helpSupport")}
                      </a>
                    </li>
                    <li>
                    <a href={"/" + lang + "/terms-conditions"}>
                        {t("termsCondition")}
                      </a>
                    </li>
                    <li>
                    <a href={"https://miggster.freshdesk.com/en/support/solutions/67000388168"} target="_blank" rel="noopener noreferrer">
                        {t("privacyPolicy")}
                      </a>
                    </li>
                    {this.props.isLoggedIn ? <li>
                      {/* <a href="#">{t("unsubscribe")}</a> */}

                      <span
                        className="a"
                        onClick={() => {
                          this.props.unsubscribeOpen();
                        }}
                      >
                        {t("unsubscribe")}
                      </span>
                    </li> : <></>}
                  </ul>
                </section>
                <section
                  className={
                    "col-md-2 d-flex d-flex justify-content-center justify-content-md-end footer-sec-4 " +
                    platformParams['className']
                  }
                >
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <h4>{t("company")}</h4>
                    </li>
                    <li>
                      <a href={"/" + lang + "/about-us"}>{t("navAboutUs")}</a>
                    </li>
                    <li>
                      <a href={"/" + lang + "/news"}>{t("navNews")}</a>
                    </li>
                    <li>
                      <a href={"/" + lang + "/contact-us"}>
                        {t("navContactUs")}
                      </a>
                    </li>
                    <li>
                      <ul className="list-inline socialMedia mt-2">
                        <li className="list-inline-item instagram">
                          <a
                            href="https://www.instagram.com/miggstergaming/"
                            target="_blank" rel="noopener noreferrer"
                          >
                          </a>
                        </li>
                        <li className="list-inline-item facebook pl-1">
                          <a
                            href="https://www.facebook.com/MiggsterGaming/"
                            target="_blank" rel="noopener noreferrer"
                          >
                          </a>
                        </li>
                        <li className="list-inline-item twitter pl-1">
                          <a
                            href="https://twitter.com/miggstergaming"
                            target="_blank" rel="noopener noreferrer"
                          >
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
            <div
              className={
                "col-md-12 text-center text-white copyright"
              }
            >
              <p>
                © {moment().year()}{" "}
                {platformParams['titleX']} by GAMEOUT
              </p>
            </div>
          </div>
        </footer>
        <footer className="container-fluid bg-miggsterBGDarkGrey fixed-bottom d-block d-lg-none">
          {!this.props.isLoggedIn ? (
            <div className="col-sm-12 d-block d-lg-none bg-miggsterBGDarkGrey p-3 footer-wrapper fixed-bottom">
              <div className="row gutter-space">
                { !isNgCountry()
                  ?
                  <div className="col-5">
                    <button
                      onClick={() => {
                      // this.props.loginOpen();
                      this.props.history.push("/" + lang + "/billing-login");
                      }}
                      data-toggle="modal"
                      data-target="#login"
                      className="btn btn-grey footer light btn-block"
                    >
                      {t("signin")}
                    </button>
                  </div>
                  :
                  <></>
                }

                <div className="col">
                  <a
                    href="https://staging.miggster.join.arcadex.co"
                    className={"btn btn-" +  platformParams['baseButtonClassSufix'] + " footer btn-block light"}
                  >
                    {t("joinNow")}
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                  "container-fluid fixed-bottom " +
                  fullScreenClass +
                  " d-lg-none"
              }
              >
              <div className={"row footer-afterLogin"+(this.deviceInfo.is.iPhoneX === true ? " pb-20":" ")}>
                  <div className="col">
                    <a href={"/" + lang + "/dashboard"} className={
                      this.props.match.path === "/" + lang + "/dashboard" ? "dashboard active" : "dashboard"
                      }>
                        <figure className="d-flex flex-column justify-content-center align-items-center">
                          <span className="icon"></span>
                          <figcaption
                              className={
                              this.props.match.path === "/" + lang + "/dashboard"
                                  ? (" text-" + platformParams['linkClassSufix'])
                                  : ""
                              }
                          >
                              {t("navDashboard")}
                          </figcaption>
                        </figure>
                    </a>
                  </div>
                  {
                    !isNgCountry() ?
                      <div className="col">
                        <a href={"/" + lang + "/giveaways"} className={
                          this.props.match.path === "/" + lang + "/giveaways" ? "giveaways active" : "giveaways"
                          }>
                          <figure className="d-flex flex-column justify-content-center align-items-center">
                            <span className="icon"></span>
                            <figcaption
                                className={
                                this.props.match.path === "/" + lang + "/giveaways"
                                    ? (" text-" + platformParams['linkClassSufix'])
                                    : ""
                                }
                            >
                                {t("giveaways")}
                            </figcaption>
                          </figure>
                        </a>
                      </div>
                      : <></>
                  }
                  {/* <div className="col">
                    <a href={"/" + lang + "/freetoplay"} className={
                      this.props.match.path === "/" + lang + "/freetoplay" ? "games active" : "games"
                      }>
                        <figure className="d-flex flex-column justify-content-center align-items-center">
                          <span className="icon"></span>
                          <figcaption
                              className={
                              this.props.match.path === "/" + lang + "/freetoplay"
                                  ? (" text-" + platformParams['linkClassSufix'])
                                  : ""
                              }
                          >
                              {t("navGames")}
                          </figcaption>
                        </figure>
                    </a>
                  </div> */}
                  <div className="col">
                    <a
                        href="javascript:void(0);"
                        onClick={() => {
                        this.props.messageListSidebarOpen();
                        }}
                        className="chat"
                    >
                      <figure className="d-flex flex-column justify-content-center align-items-center">
                        <span className="icon"></span>
                        {this.props.messagehistory.unread > 0 ? (
                        <span
                            className={
                            "badge text-white badge-notification-count-responsive message-count " +
                            ( "bg-" + platformParams['linkClassNoti'])
                            }
                        >
                            {this.props.messagehistory.unread}
                        </span>
                        ) : (
                        ""
                        )}
                        <figcaption>{t("messages")}</figcaption>
                      </figure>
                    </a>
                  </div>
                  <div className="col">
                    <a
                        ref="javascript:void(0);"
                        onClick={() => {
                        this.props.notificationListSidebarOpen();
                        }}
                        className="notifications"
                    >
                      <figure className="d-flex flex-column justify-content-center align-items-center">
                        <span className="icon"></span>
                        {totalNoficationUnread > 0 ? (
                        <span
                            className={
                            "badge text-white badge-notification-count-responsive message-count " +
                            ( "bg-" + platformParams['linkClassNoti'])
                            }
                        >
                            {totalNoficationUnread}
                        </span>
                        ) : (
                        ""
                        )}
                        <figcaption>{t("notifications")}</figcaption>
                      </figure>
                    </a>
                  </div>
                </div>
              </div>
          )}
        </footer>
      </>
    );
  }
}
const mapDispatchToProps = {
  loginOpen: login.open,
  registerOpen: register.open,
  messageListSidebarOpen: messageListSidebar.open,
  notificationListSidebarOpen: notificationListSidebar.open,
  resetHyveRegister: toggleHyveRegister.reset,
  unsubscribeOpen: unsubscribe.open
};

const mapStateToProps = state => {
  return {
    userData: state.user.item || {},
    isLoggedIn: state.login.isLoggedIn || false,
    isLogging: state.login.isLogging || false,
    isGameFullScreen: state.toggles.gameFullScreen || false,
    messagehistory: state.messagehistory,
    notifications: state.notifications,
    currentLang: state.login.currentLang
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Footer))
);
