import React, { Component } from "react";
import { connect } from "react-redux";
import { apiError } from "../../../actions/modals";
import "./ApiErrorWithLoginModal.scss";
import ModalUS from "../ModalUS";

class ApiErrorWithLoginModal extends Component {
  modalFooter() {
    const { t } = this.props;
    return (
      <div className="modal-footer">
        <ul className="list-group w-100 list-group-flush m-0">
          <li className="list-group-item text-center p-4 bg-mtnThemeMainColor">
            <span className="a text-white" onClick={()=>{this.props.apiErrorModalClose()}}>
             <b> OK </b>
            </span>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <>
        <ModalUS
          handleHide={() => {
            this.props.apiErrorModalClose();
         }}
          isOpen={this.props.isApiErrorModalOpen}
          disableOuterClick={false}
          modalClass="modal modal-unfollow modal-change-password unsubscribeModal"
          withFooter={true}
          footer={this.modalFooter()}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4"></div>
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h5>Sorry, we are currently upgrading this server, please try again in 15 minutes</h5>
              </div>
            </div>
          
            <div className="row">
              <div className="col-sm-12" />
            </div>
          </div>
        </ModalUS>
      </>
    );
  }
}

const mapDispatchToProps = {
  apiErrorModalOpen: apiError.open,
  apiErrorModalClose: apiError.close,
};

const mapStateToProps = state => {
  return {
      isApiErrorModalOpen: state.modals.isApiErrorModalOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((ApiErrorWithLoginModal));