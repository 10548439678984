import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "../components/navbars/NavBar";
import Footer from "../components/navbars/Footer/Footer";
import IncludeModals from "../components/IncludeModals";
import ToastrContainer from "../../components/Toastr/ToastrContainer";
import platformParams from "../../utils/platformParams";
import { getProfileDataForm } from "../../actions/forms";
// Retrofy added in
import loadingGIF from "../../assets/images/Loaders/MiggsterPlusLogo_Loader.gif";

class MainLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
		};
	}
	componentDidMount() {
		if (this.props.isLoggedIn) {
			this.props
				.getProfileDataFormSubmit({ profileId: this.props.profileId })
				.then(() => {
					this.setState({ isLoading: false });
				});
		}

		// Scroll back to top of page
		window.scrollTo(0, 0);

		const mainContainer =
			document.getElementsByClassName("mainContainer")[0];
		const loader = document.getElementsByClassName("loader-container")[0];

		// Delay the loader fade out on all pages, and longer delay on game pages
		let pathCheck = /(\/challenge\/)|(\/free-game-detail\/)/;
		let currentPath = window.location.pathname;
		let result = currentPath.match(pathCheck);
		if (result) {
			setTimeout(() => {
				loader.classList.add("hide");
				mainContainer.classList.add("fadein");
			}, 4000);
		} else {
			setTimeout(() => {
				loader.classList.add("hide");
				mainContainer.classList.add("fadein");
			}, 1500);
		}
	}
	render() {
		if (
			this.props.isAnyModalOpen ||
			this.props.isAnySidebarOpen ||
			this.props.isGameFullScreen
		) {
			document.body.classList.add("disable-scroll");
		} else {
			document.body.classList.remove("disable-scroll");
		}
		return (
			<>
				<section
					className={
						"bg-miggsterBGLightGrey mainSection" +
						(!this.props.isLoggedIn ? " premium" : " premium")
					}
				>
					{/* {this.props.fetchCount > 0 ? (
            <div
              className={
                "load-bar " +
                platformParams['baseColor']
              }
            >
              <div className="bar" />
              <div className="bar" />
            </div>
          ) : (
            ""
          )} */}
					{/* <div className="go-premium">
            sfjksopdgj dfjg pdjfgopjdfopj 
          </div> */}
					<NavBar />
					<div
						className={
							(this.props.isAnyModalOpen ||
							this.props.isAnySidebarOpen ||
							this.props.isGameFullScreen
								? "disable-scroll"
								: "") +
							" " +
							(this.props.isAnyModalOpen &&
							!this.props.isUnsubscribeOpen
								? "d-none d-lg-block"
								: "")
						}
					>
						<div className="loader-container">
							<div className="text-center">
								<div className="col-sm-12">
									{/* Retrofy commented out */}
									{/* <img className={"loader-" + platformParams['className']} src={platformParams['loadingGif']} alt="" />    */}
									<img
										className={
											"loader-" +
											platformParams["className"]
										}
										src={loadingGIF}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className={"mainContainer"}>
							{this.props.isLoggedIn && this.state.isLoading
								? // <div className="text-center">
								  //   <div className="col-sm-12">
								  //     <img className={"loader-" + platformParams['className']} src={platformParams['loadingGif']} alt="" />
								  //   </div>
								  // </div>
								  ""
								: this.props.children}
						</div>

						<Footer />
						<section className="container-fluid">
							<div className="row">
								<div className="blank-space d-block d-lg-none" />
							</div>
						</section>
					</div>
					<IncludeModals />
					<div
						className={
							this.props.fetchCount > 0 ? "ui-blocker" : "d-none"
						}
					/>
					<div
						className={
							"overlay" +
							(this.props.isAnySidebarOpen ? " active" : "")
						}
					/>
				</section>
				<ToastrContainer />
			</>
		);
	}
}

const mapDispatchToProps = {
	getProfileDataFormSubmit: getProfileDataForm.submit,
};

const mapStateToProps = (state) => {
	const { modals, sidebars } = state;

	const { isAnyModalOpen } = modals || {
		isAnyModalOpen: false,
	};
	const { isAnySidebarOpen } = sidebars || {
		isAnySidebarOpen: false,
	};
	return {
		profileId: state.user.item ? state.user.item.id : 0,
		isLoggedIn: state.login.isLoggedIn,
		isAnyModalOpen,
		isAnySidebarOpen,
		isUnsubscribeOpen: state.modals.isUnsubscribeOpen,
		fetchCount: state.loaders.fetchCount,
		submitCount: state.loaders.submitCount,
		isGameFullScreen: state.toggles.gameFullScreen,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
