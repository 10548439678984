import React, { Component } from "react";
import { connect } from "react-redux";
import { reportPlayer } from "../../../actions/modals";
import { toggleOtherReason } from "../../../actions/toggles";
import { reportPlayerForm } from "../../../actions/forms";
import { AllMsgClear } from "../../../actions/alerts";
import "./ReportPlayerModal.scss";
import Modal from "../Modal";
import { APP_MESSAGES } from "../../../services/config/messages";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import Select2Component from "../../Select2/Select2Component";
import { withTranslation } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class ReportPlayerModal extends Component {
  constructor(props) {
    super(props);

    this.showReportPlayer = this.showReportPlayer.bind(this);
    this.hideReportPlayer = this.hideReportPlayer.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);

    this.state = {
      isLandScape: false,
      isReasonDisplay: false
    };
  }

  handleFieldChange(value) {
    this.props.toggleOtherReasonReset();
    if (value === "Other") {
      this.props.toggleOtherReason();
    }

    this.props.reportPlayerFormUpdateField(
      "reason",
      value === "Other" ? value : value
    );
  }

  showReportPlayer() {
    this.props.reportPlayerOpen();
  }

  hideReportPlayer() {
    this.props.reportPlayerClose();
    this.props.AllMsgClear();
    this.props.toggleOtherReasonReset();
    this.props.reportPlayerFormUpdateField("otherReason", "");
  }

  render() {
    let Success;
    let Error;

    Error = this.props.reportPlayerForm.fields.reason.errorMsg
      ? APP_MESSAGES[this.props.reportPlayerForm.fields.reason.errorMsg](
          this.props.reportPlayerForm.fields.reason.errorPayload,
          () => {
            this.props.reportPlayerFormResetError("reason");
          }
        )
      : props => <>{""}</>;
    Success = this.props.reportPlayerForm.fields.reason.successMsg
      ? APP_MESSAGES[this.props.reportPlayerForm.fields.reason.successMsg](
          this.props.reportPlayerForm.fields.reason.successPayload,
          () => {
            this.props.reportPlayerFormResetSuccess("reason");
          }
        )
      : props => <>{""}</>;

    const selectOptions = [
      { id: 1, value: "Language abuse" },
      { id: 2, value: "Cheating" },
      { id: 3, value: "Spam" },
      { id: 4, value: "Other" }
    ];
    const { t } = this.props;
    return (
      <>
        <Modal
          handleHide={this.hideReportPlayer}
          isOpen={this.props.isReportPlayerOpen}
          disableOuterClick={false}
          modalClass="modal modal-change-password"
        >
          <div className="card-popup login-form" style={{ minHeight: "290px" }}>
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="bold">{t("reportPlayer")}</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!this.props.reportPlayerForm.isSubmitting) {
                      if (!this.props.reportPlayerForm.fields.reason.value) {
                        this.props.reportPlayerFormSetError(
                          "reason",
                          "NOT_EMPTY"
                        );
                        return false;
                      }

                      if (
                        this.props.reportPlayerForm.fields.reason.value ===
                          "Other" &&
                        !this.props.reportPlayerForm.fields.otherReason.value.trim()
                      ) {
                        this.props.reportPlayerFormSetError(
                          "reason",
                          "NOT_EMPTY"
                        );
                        return false;
                      }

                      let textReason = "";
                      if (
                        this.props.reportPlayerForm.fields.reason.value ===
                          "Language abuse" ||
                        this.props.reportPlayerForm.fields.reason.value ===
                          "Cheating" ||
                        this.props.reportPlayerForm.fields.reason.value ===
                          "Spam"
                      ) {
                        textReason = this.props.reportPlayerForm.fields.reason
                          .value;
                      } else {
                        textReason =
                          "Other:" +
                          this.props.reportPlayerForm.fields.otherReason.value;
                      }

                      this.props.reportPlayerFormSubmit({
                        reporteeId: this.props.reportPlayerForm.fields
                          .reporteeId.value,
                        textReason: textReason,
                        tournamentUrl: window.location.href
                      });
                    }
                  }}
                  tabIndex={-1}
                >
                  <div className="form-group with-lable mt-3">
                    <Select2Component
                      selectOptions={selectOptions}
                      onChange={this.handleFieldChange}
                      selectedValue={
                        this.props.reportPlayerForm.fields.reason.value
                      }
                    />
                  </div>

                  {this.props.isOtherReason ? (
                    <AppInputComponent
                      labelText="Reason"
                      divClass="form-group with-lable-without-z-index mt-5"
                      value={
                        this.props.reportPlayerForm.fields.otherReason.value
                      }
                      onChange={event => {
                        this.props.reportPlayerFormSetError("reason", "");
                        this.props.reportPlayerFormUpdateField(
                          "otherReason",
                          event.target.value
                        );
                      }}
                      tabIndex={-1}
                    />
                  ) : (
                    ""
                  )}

                  <button
                    type="submit"
                    className={"btn btn-" +  platformParams['baseButtonClassSufix2']+ " light btn-block mt-2 mt-sm-5"}
                  >
                    {t("report")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  reportPlayerOpen: reportPlayer.open,
  reportPlayerClose: reportPlayer.close,
  AllMsgClear,
  reportPlayerFormSubmit: reportPlayerForm.submit,
  reportPlayerFormReset: reportPlayerForm.reset,
  reportPlayerFormResetField: reportPlayerForm.resetField,
  reportPlayerFormUpdateField: reportPlayerForm.updateField,
  reportPlayerFormSetError: reportPlayerForm.setError,
  reportPlayerFormSetSuccess: reportPlayerForm.setSuccess,
  reportPlayerFormResetError: reportPlayerForm.resetError,
  reportPlayerFormResetSuccess: reportPlayerForm.resetSuccess,
  toggleOtherReasonReset: toggleOtherReason.reset,
  toggleOtherReason: toggleOtherReason.toggle
};

const mapStateToProps = state => {
  return {
    isOtherReason: state.toggles.otherReason,
    isForgotPassMailSend: state.modals.isForgotPassMailSend,
    isReportPlayerOpen: state.modals.isReportPlayerOpen,
    isLogging: state.login.isLogging,
    reportPlayerForm: state.forms.reportPlayerForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ReportPlayerModal));
