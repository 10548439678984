// check if a number is a number
export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

// convert to camel case
export const camelize = string =>
  string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    .replace(/\s+/g, "");

// create a game logo name class that is readable
export const createGameLogoClassName = (name, suffix = "Logo") => {
  //
  return `${camelize(name.toLowerCase())}${suffix}`;
};

/**
 * Parsing a query to get its parts
 * @param {string} qstr
 */
export const parseQuery = qstr => {
  var query = {};
  var a = (qstr[0] === "?" ? qstr.substr(1) : qstr).split("&");
  for (var i = 0; i < a.length; i++) {
    var b = a[i].split("=");
    query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || "");
  }
  return query;
};

/**
 * Lower all characters and capitalize first letter
 * @param {string} string
 */
export const lowercaseAllAndCapitalizeFirst = string => {
  if (!string) return "";
  return capitalizeFirstLetter(string.toLowerCase());
};

/**
 * Capitalize first letter of a string
 * @param {string} string
 */
export const capitalizeFirstLetter = string => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Trim string whitespaces
 * @param {string} string
 * - tests
 */
export const trim = string => string.replace(/^\s+|\s+$/g, "");

export function nRandomNumFromRange(aLength, nCount) {
  let n = [];
  if (aLength > 0) {
    let min = 0;
    let max = Math.min(nCount, aLength);
    let optionArr = [...Array(aLength).keys()];
    for (let i = 0; i < max; i++) {
      let randomNum = Math.floor(
        Math.random() * (optionArr.length - min) + min
      );
      n.push(optionArr[randomNum]);
      optionArr.splice(randomNum, 1);
    }
  }
  return n;
}

/**
 * Reduce Title By given length
 * @param {title} string
 * @param {length} int
 */
export function refactorTitle(title, length = 25) {
  return title.length > length ? title.slice(0, length) + "..." : title;
}
export function refactorContent(title, length = 60) {
  return title.length > length ? title.slice(0, length) + "..." : title;
}

export function ordinalSuffix(i, tag = false) {
  let j = i % 10,
    k = i % 100;
  if (i === 0) {
      return tag === true ? "-" : "-";
  }
  if (j === 1 && k !== 11) {
    return tag === true ? i + "<sup>st</sup>" : i + "st";
  }
  if (j === 2 && k !== 12) {
    return tag === true ? i + "<sup>nd</sup>" : i + "nd";
  }
  if (j === 3 && k !== 13) {
    return tag === true ? i + "<sup>rd</sup>" : i + "rd";
  }
  return tag === true ? i + "<sup>th</sup>" : i + "th";
}

export function findWithAttr(array, attr, value) {
  if (array !== undefined) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
  }
  return -1;
}

export function leftJoin(arr1, arr2, key1, key2, arrFields = []) {
  if (typeof arr2 === "undefined") return arr1;
  const res = arr1.map(a1 => {
    const filtered = arr2.filter(a2 => {
      return a1[key1] === a2[key2] || parseInt(a1[key1]) === parseInt(a2[key2]);
    });
    if (filtered.length > 0) {
      if (arrFields.length > 0) {
        // copy only fields from desired fields list
        let obj = {};
        arrFields.map(f => {
          return (obj[f] = filtered[0][f]);
        });
        return Object.assign({}, a1, obj);
      } else {
        // join all fields
        return Object.assign({}, a1, filtered[0]);
      }
    }
    return a1;
  });
  return res;
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function getContainerWidth() {
  // const windowWidth = document.body.clientWidth;
  const windowWidth = window.innerWidth;
  switch (true) {
    case windowWidth >= 0 && windowWidth < 576:
      return windowWidth;
    case windowWidth >= 576 && windowWidth < 768:
      return 540;
    case windowWidth >= 768 && windowWidth < 992:
      return 720;
    case windowWidth >= 992 && windowWidth < 1200:
      return 960;
    case windowWidth >= 1200:
    default:
      return 1200;
  }
}
export function getContainerLeftWidth() {
  // const windowWidth = document.body.clientWidth;
  const windowWidth = window.innerWidth;
  const containerWidth = getContainerWidth();
  // console.log("windowWidth: ", windowWidth);
  // console.log("containerWidth: ", containerWidth);
  return (windowWidth - containerWidth) / 2;
}

export function getAllowedCountries(){
  // let countries = process.env.REACT_APP_ALLOWED_COUNTRIES.split(",");
  // countries.splice(countries.indexOf("234"), 1 );
  // console.log("countries:", countries);
  return process.env.REACT_APP_ALLOWED_COUNTRIES.split(",");
}


// Nigeria (234) - ng
export function isNgCountry(){
    return process.env.REACT_APP_ALLOWED_COUNTRIES.indexOf("234") >= 0 ? true : false;
    // return true;
}

// South Africa (27) - za
export function isZaCountry(){
    // console.log(process.env.REACT_APP_ALLOWED_COUNTRIES);
    return process.env.REACT_APP_ALLOWED_COUNTRIES.indexOf("27") >= 0 ? true : false;
}

// Egypt (20) - eg
export function isEgCountry(){
    // console.log(process.env.REACT_APP_ALLOWED_COUNTRIES);
    return process.env.REACT_APP_ALLOWED_COUNTRIES.indexOf("20") >= 0 ? true : false;
}

export function isEnabledLanguageChange(){
    return process.env.REACT_APP_LANGUAGE_SUPPORT === "ON" ? true : false;
}

export function getDay(day){
  let frequency = "";
  switch(day){
    case "WEEKLY":
      frequency = "week";
      break;
    case "MONTHLY":
      frequency = "month";
      break;
    default:
      frequency = "day";
      break;
  }
  return frequency;
}

export const formatNumberForZA = number => {
  number =  "" + Number(number);
  if( number.indexOf('27') !== 0 ){
    number = "27" + number;
  }
  return number;
}