import React from "react";
import { MobileOperatorList, ProductsByMobileOperatorList } from "../redux-config/listActionMap";
import {
  SubscribeNowForm,
  BillingSubscriptionForm,
  BillingRegistrationForm,
  BillingLoginForm,
  BillingInfoForm,
  NGBillingInfoForm,
  BillingResetPasswordForm,
  BillingForgotPasswordForm,
  UnsubscribeForm,
  BillingHoldForm,
  SubscribeWrapmeForm,
  BillingNGHoldForm,
  NGLoginForm,
  NGAutoLoginForm,
  NGGetDOIUrlForm,
  MTNXBillingHoldForm,
  BillingLoginViaUUIDForm
} from "../redux-config/formActionMap";
import {
  getMobileOperatorList,
  getProductsByMobileOperatorList,
  onSubscribeNowRequest,
  onBillingSubscriptionRequest,
  onBillingRegistrationRequest,
  onBillingLoginRequest,
  onBillingInfoRequest,
  onNGBillingInfoRequest,
  onBillingResetPasswordRequest,
  onBillingForgotPasswordRequest,
  onUnsubscribeRequest,
  onHoldRequest,
  onSubscribeWrapmeRequest,
  onNGHoldRequest,
  onNGAutoLoginRequest,
  onNGGetDOIUrlFormRequest,
  onGetSubscribedToSite,
  onBillingLoginViaUSSDRequest
} from "../services/vas.service";
import { ListActionCreator, FormActionCreator } from "./creators";
import { vasError, modalCloseAll, unsubscribe, unsubscribeSuccess } from "./modals";
import { axiosInstance } from "../utils/axios";
import { loginSuccess, uuidLoginFailure } from "./user";
import { tutorialRequest } from "./tutorials";
import { sidebarCloseAll } from "./sidebars";
import { initialiseSocket } from "./socket";
import i18n from "../i18n";
import { logoutUserIfNeeded } from "./user";
import { isNgCountry } from "../utils/misc";
import { Trans } from "react-i18next";
import { onNGLogInUserRequest } from "../services/users.service";

export const mobileOperatorList = ListActionCreator({
  ...MobileOperatorList,
  service: getMobileOperatorList,
  dataMapper: json => json.data.data
});

export const productsByMobileOperatorList = ListActionCreator({
  ...ProductsByMobileOperatorList,
  service: getProductsByMobileOperatorList,
  dataMapper: json => json.data.data
})

export const subscribeNowForm = FormActionCreator({
  ...SubscribeNowForm,
  service: onSubscribeNowRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.code === "SUB02") {
        dispatch(
          billingRegistrationForm.updateField(
            "mondiaCustomerId",
            response.data.data.mondiaCustomerId
              ? response.data.data.mondiaCustomerId
              : ""
          )
        );
        dispatch(
          subscribeWrapmeForm.updateField(
            "phonenumber",
            response.data.data.username
              ? response.data.data.username
              : ""
          )
        );
        const lang = i18n.language;
        window.location.href = "/" + lang + "/billing-registration";
      } else {
        if (response.data.data.verificationOnHold) {
          dispatch(
            billingHoldForm.updateField(
              "subscriptionId",
              response.data.data.subscriptionId
                ? response.data.data.subscriptionId
                : ""
            )
          );
          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaCustomerId",
              response.data.data.mondiaCustomerId
                ? response.data.data.mondiaCustomerId
                : ""
            )
          );
          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaSubscriptionId",
              response.data.data.subscriptionId
                ? response.data.data.subscriptionId
                : ""
            )
          );
          window.location.href = response.data.data.mtnxDoiURL;
          // const lang = i18n.language;
          // if(isNgCountry()){
          //   window.location.href = "/" + lang + "/ng-billing-holt";
          // }
          // else{
          //   window.location.href = "/" + lang + "/billing-holt";
          // }
        } else if (
          response.data.data.mondiaCustomerId &&
          response.data.data.subscriptionId
        ) {
          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaCustomerId",
              response.data.data.mondiaCustomerId
                ? response.data.data.mondiaCustomerId
                : ""
            )
          );

          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaSubscriptionId",
              response.data.data.subscriptionId
                ? response.data.data.subscriptionId
                : ""
            )
          );
          const lang = i18n.language;
          window.location.href = "/" + lang + "/billing-subscription";
        }
      }
    } else {
      if (response.data.error.code == "V001") {
        dispatch(
          extraActions.setError(SubscribeNowForm, "mondiaCustomerId", "V002")
        );
      } else {
        dispatch(
          extraActions.setError(
            SubscribeNowForm,
            "mondiaCustomerId",
            response.data.error.code
          )
        );
      }

      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingSubscriptionForm = FormActionCreator({
  ...BillingSubscriptionForm,
  service: onBillingSubscriptionRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.mondiaCustomerId) {
        dispatch(
          billingRegistrationForm.updateField(
            "mondiaCustomerId",
            response.data.data.mondiaCustomerId
              ? response.data.data.mondiaCustomerId
              : ""
          )
        );
        dispatch(
          subscribeWrapmeForm.updateField(
            "phonenumber",
            response.data.data.username
              ? response.data.data.username
              : ""
          )
        );
        const lang = i18n.language;
        window.location.href = "/" + lang + "/billing-registration";
      }
    } else {
      if (response.data.error.code == "V001") {
        dispatch(extraActions.setError(BillingSubscriptionForm, "otp", "V002"));
      } else {
        dispatch(
          extraActions.setError(
            BillingSubscriptionForm,
            "otp",
            response.data.error.code
          )
        );
      }
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingRegistrationForm = FormActionCreator({
  ...BillingRegistrationForm,
  service: onBillingRegistrationRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.token) {
        axiosInstance.setToken(response.data.data.token);
      }

      const hasCreateName = response.data.data.hasInitialPreferences
        ? true
        : false;
      const hasCreateAvatar = response.data.data.hasInitialPreferences
        ? true
        : false;
      const hasCreateTutorial = response.data.data.hasInitialPreferences
        ? true
        : false;

      let param = {
        data: response.data.data
      };
      dispatch(loginSuccess(param));
      setTimeout(() => {
        dispatch(initialiseSocket());
      }, 500);

      let tutorialsParam = {
        hasCreatenamePreferences: hasCreateName,
        hasCreateavatarPreferences: hasCreateAvatar,
        hasCreatetutorialPreferences: hasCreateTutorial
      };
      dispatch(tutorialRequest(tutorialsParam));
      dispatch(extraActions.reset(BillingRegistrationForm));
      dispatch(sidebarCloseAll());
      if (
        response.data.data.hasInitialPreferences &&
        response.data.data.profileTutorials
      ) {
        dispatch(modalCloseAll());
      }
    } else {
      if (response.data.error.code == "V001") {
        dispatch(
          extraActions.setError(BillingRegistrationForm, "phonenumber", "V002")
        );
      } else {
        dispatch(
          extraActions.setError(
            BillingRegistrationForm,
            "phonenumber",
            response.data.error.code
          )
        );
      }
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingLoginViaUUIDForm = FormActionCreator({
  ...BillingLoginViaUUIDForm,
  service: onBillingLoginViaUSSDRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {

      // Check sub is still active. If not, redirect to join link with pre-populated data
      let subEndDate = new Date(response.data.data.subscriptionEndTime);
      let now = new Date();
      if(now > subEndDate) {
        let countryId = response.data.data.billingCountryId;
        let msisdn = response.data.data.phoneNumber;
        let email = response.data.data.email;
        let name = response.data.data.name;
        let uri = `?countryID=${countryId}&msisdn=${msisdn}&email=${email}&name=${name}&resub=true`;
        let url = process.env.REACT_APP_ENV == "PROD_MTNX" ? 'https://join.miggster.com/' : 'https://staging.miggster.join.arcadex.co/';

        window.location.href = url + uri;
      }

      console.log('profile token', response.data.data.token, response.data.data["0"]);
      if (response.data.data.token) {
        axiosInstance.setToken(response.data.data.token);
      }

      let param = {
        data: response.data.data
      };
      console.log('login success', param);
      dispatch(loginSuccess(param));
      setTimeout(() => {
        dispatch(initialiseSocket());
      }, 500);

      const value = response.data.data.hasInitialPreferences ? true : false;
      const tutorialsParam = {
        hasCreatenamePreferences: value,
        hasCreateavatarPreferences: value,
        hasCreatetutorialPreferences: value,
      };
      dispatch(tutorialRequest(tutorialsParam));
      dispatch(extraActions.reset(BillingLoginForm));
      dispatch(sidebarCloseAll());
      if (
        response.data.data.hasInitialPreferences &&
        response.data.data.profileTutorials
      ) {
        dispatch(modalCloseAll());
      }
    } else {
      dispatch(uuidLoginFailure());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingLoginForm = FormActionCreator({
  ...BillingLoginForm,
  service: onBillingLoginRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {

      // Check sub is still active. If not, redirect to join link with pre-populated data
      let subEndDate = new Date(response.data.data.subscriptionEndTime);
      let now = new Date();
      if(now > subEndDate) {
        let countryId = response.data.data.billingCountryId;
        let msisdn = response.data.data.phoneNumber;
        let email = response.data.data.email;
        let name = response.data.data.name;
        let uri = `?countryID=${countryId}&msisdn=${msisdn}&email=${email}&name=${name}&resub=true`;
        let url = process.env.REACT_APP_ENV == "PROD_MTNX" ? 'https://join.miggster.com/' : 'https://staging.miggster.join.arcadex.co/';
        window.location.href = url + uri;
      }

      if (response.data.data.token) {
        axiosInstance.setToken(response.data.data.token);
      }

      const hasCreateName = response.data.data.hasInitialPreferences
        ? true
        : false;
      const hasCreateAvatar = response.data.data.hasInitialPreferences
        ? true
        : false;
      const hasCreateTutorial = response.data.data.hasInitialPreferences
        ? true
        : false;

      let param = {
        data: response.data.data
      };
      dispatch(loginSuccess(param));
      setTimeout(() => {
        dispatch(initialiseSocket());
      }, 500);

      let tutorialsParam = {
        hasCreatenamePreferences: hasCreateName,
        hasCreateavatarPreferences: hasCreateAvatar,
        hasCreatetutorialPreferences: hasCreateTutorial
      };
      dispatch(tutorialRequest(tutorialsParam));
      dispatch(extraActions.reset(BillingLoginForm));
      dispatch(sidebarCloseAll());
      if (
        response.data.data.hasInitialPreferences &&
        response.data.data.profileTutorials
      ) {
        dispatch(modalCloseAll());
      }
    } else {
      if (response.data.error.code == "V001") {
        dispatch(
          extraActions.setError(BillingLoginForm, "phonenumber", "V002")
        );
      } else {
        dispatch(
          extraActions.setError(
            BillingLoginForm,
            "phonenumber",
            response.data.error.code
          )
        );
        if(response.data.error.code == "BI08") {
          window.location = response.data.error.url;
        }
      }
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingInfoForm = FormActionCreator({
  ...BillingInfoForm,
  service: onBillingInfoRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.verificationOnHold) {
        dispatch(
          billingHoldForm.updateField(
            "subscriptionId",
            response.data.data.subscriptionId
              ? response.data.data.subscriptionId
              : ""
          )
        );

        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaCustomerId",
            response.data.data.mondiaCustomerId
              ? response.data.data.mondiaCustomerId
              : ""
          )
        );
        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaSubscriptionId",
            response.data.data.subscriptionId
              ? response.data.data.subscriptionId
              : ""
          )
        );
        dispatch(
          subscribeWrapmeForm.updateField(
            "phonenumber",
            response.data.data.username
              ? response.data.data.username
              : ""
          )
        );
        window.location.href = response.data.data.mtnxDoiURL;
        // const lang = i18n.language;
        // if(isNgCountry()){
        //   window.location.href = "/" + lang + "/ng-billing-holt";
        // }
        // else{
        //   window.location.href = "/" + lang + "/billing-holt";
        // }
      } 
      else if (typeof (response.data.data.autoLogin) !== "undefined") {
        let profile = response.data.data["0"];
        // auto Login
        // _fnAutoLogin(profile, dispatch, extraActions);
        if (profile.token){
          axiosInstance.setToken(profile.token);
        }
        const hasCreateName = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateAvatar = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateTutorial = profile.hasInitialPreferences
          ? true
          : false;
        let param = {
          data: profile
        };
      
        dispatch(loginSuccess(param));
        setTimeout(() => {
          dispatch(initialiseSocket());
        }, 500);
        
        let tutorialsParam = {
          hasCreatenamePreferences: hasCreateName,
          hasCreateavatarPreferences: hasCreateAvatar,
          hasCreatetutorialPreferences: hasCreateTutorial
        };
        dispatch(tutorialRequest(tutorialsParam));
        let successLogin = (
          <Trans i18nKey="successLogin">You are successfully login.</Trans>
        );
        dispatch(extraActions.setSuccess(NGLoginForm, "msisdn", successLogin));
        dispatch(extraActions.reset(NGLoginForm));
        dispatch(sidebarCloseAll());
        if (
          profile.hasInitialPreferences &&
          profile.profileTutorials
        ) {
          dispatch(modalCloseAll());
        }
      }
      else if ( response.data.data.mondiaCustomerId) {
        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaCustomerId",
            response.data.data.mondiaCustomerId
              ? response.data.data.mondiaCustomerId
              : ""
          )
        );

        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaSubscriptionId",
            response.data.data.subscriptionId
              ? response.data.data.subscriptionId
              : ""
          )
        );
        const lang = i18n.language;
        window.location.href = "/" + lang + "/billing-subscription";
      } 
    } else {
      if (response.data.error.code == "V001") {
        dispatch(extraActions.setError(BillingInfoForm, "phonenumber", "V002"));
      } else {
        dispatch(
          extraActions.setError(
            BillingInfoForm,
            "phonenumber",
            response.data.error.code
          )
        );
      }
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});


export const ngBillingInfoForm = FormActionCreator({
  ...NGBillingInfoForm,
  service: onNGBillingInfoRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.verificationOnHold) {
        dispatch(
          billingNGHoldForm.updateField(
            "subscriptionId",
            response.data.data.subscriptionId
              ? response.data.data.subscriptionId
              : ""
          )
        );
        
        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaCustomerId",
            response.data.data.mondiaCustomerId
              ? response.data.data.mondiaCustomerId
              : ""
          )
        );
        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaSubscriptionId",
            response.data.data.subscriptionId
              ? response.data.data.subscriptionId
              : ""
          )
        );

        const lang = i18n.language;
        window.location.href = "/" + lang + "/ng-billing-holt";
      } 
      else if (typeof (response.data.data.autoLogin) !== "undefined") {
        let profile = response.data.data["0"];
        // auto Login
        // _fnAutoLogin(profile, dispatch, extraActions);
        if (profile.token){
          axiosInstance.setToken(profile.token);
        }
        const hasCreateName = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateAvatar = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateTutorial = profile.hasInitialPreferences
          ? true
          : false;
        let param = {
          data: profile
        };
      
        dispatch(loginSuccess(param));
        setTimeout(() => {
          dispatch(initialiseSocket());
        }, 500);
        let tutorialsParam = {
          hasCreatenamePreferences: hasCreateName,
          hasCreateavatarPreferences: hasCreateAvatar,
          hasCreatetutorialPreferences: hasCreateTutorial
        };
        dispatch(tutorialRequest(tutorialsParam));
        let successLogin = (
          <Trans i18nKey="successLogin">You are successfully login.</Trans>
        );
        dispatch(extraActions.setSuccess(NGLoginForm, "msisdn", successLogin));
        dispatch(extraActions.reset(NGLoginForm));
        dispatch(sidebarCloseAll());
        if (
          profile.hasInitialPreferences &&
          profile.profileTutorials
        ) {
          dispatch(modalCloseAll());
        }
      }
      else if ( response.data.data.mondiaCustomerId) {
        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaCustomerId",
            response.data.data.mondiaCustomerId
              ? response.data.data.mondiaCustomerId
              : ""
          )
        );

        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaSubscriptionId",
            response.data.data.subscriptionId
              ? response.data.data.subscriptionId
              : ""
          )
        );
        const lang = i18n.language;
        window.location.href = "/" + lang + "/billing-subscription";
      } 
    } else {
      if (response.data.error.code == "V001") {
        dispatch(extraActions.setError(BillingInfoForm, "phonenumber", "V002"));
      } else {
        dispatch(
          extraActions.setError(
            BillingInfoForm,
            "phonenumber",
            response.data.error.code
          )
        );
      }
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const ngGetDOIUrlForm = FormActionCreator({
  ...NGGetDOIUrlForm,
  service: onNGGetDOIUrlFormRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if(response.data.success ){
      if(response.data.data.redirectUrl){
        window.location.href = response.data.data.redirectUrl;
      }
    }
    else{
      dispatch(
        extraActions.setError(
          NGGetDOIUrlForm,
          "msisdn",
          "redirect url not found"
        )
      )
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const ngLoginForm = FormActionCreator({
  ...NGLoginForm,
  service: onNGLogInUserRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if(response.data.success ){
      if (typeof (response.data.data.autoLogin) !== "undefined" && response.data.data.autoLogin) {
        let profile = response.data.data["0"];
        // auto Login
        // _fnAutoLogin(profile, dispatch, extraActions);
        if (profile.token){
          axiosInstance.setToken(profile.token);
        }
        const hasCreateName = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateAvatar = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateTutorial = profile.hasInitialPreferences
          ? true
          : false;
        let param = {
          data: profile
        };
      
        dispatch(loginSuccess(param));
        setTimeout(() => {
          dispatch(initialiseSocket());
        }, 500);
        let tutorialsParam = {
          hasCreatenamePreferences: hasCreateName,
          hasCreateavatarPreferences: hasCreateAvatar,
          hasCreatetutorialPreferences: hasCreateTutorial
        };
        dispatch(tutorialRequest(tutorialsParam));
        let successLogin = (
          <Trans i18nKey="successLogin">You are successfully login.</Trans>
        );
        dispatch(extraActions.setSuccess(NGLoginForm, "phoneNumber", successLogin));
        dispatch(extraActions.reset(NGLoginForm));
        dispatch(sidebarCloseAll());
        if (
          profile.hasInitialPreferences &&
          profile.profileTutorials
        ) {
          dispatch(modalCloseAll());
        }
      }
    }
    else{
      dispatch(
        extraActions.setError(
          NGLoginForm,
          "phoneNumber",
          "not found"
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});


// login or subscribe
export const billingNGAutoLoginForm = FormActionCreator({ 
  ...NGAutoLoginForm,
  service: onNGAutoLoginRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (typeof (response.data.data.autoLogin) !== "undefined" && response.data.data.autoLogin) {
        let profile = response.data.data["0"];
        // auto Login
        // _fnAutoLogin(profile, dispatch, extraActions);
        if (profile.token){
          axiosInstance.setToken(profile.token);
        }
        const hasCreateName = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateAvatar = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateTutorial = profile.hasInitialPreferences
          ? true
          : false;
        let param = {
          data: profile
        };
      
        dispatch(loginSuccess(param));
        setTimeout(() => {
          dispatch(initialiseSocket());
        }, 500);
        let tutorialsParam = {
          hasCreatenamePreferences: hasCreateName,
          hasCreateavatarPreferences: hasCreateAvatar,
          hasCreatetutorialPreferences: hasCreateTutorial
        };
        dispatch(tutorialRequest(tutorialsParam));
        let successLogin = (
          <Trans i18nKey="successLogin">You are successfully login.</Trans>
        );
        dispatch(extraActions.setSuccess(NGLoginForm, "msisdn", successLogin));
        dispatch(extraActions.reset(NGLoginForm));
        dispatch(sidebarCloseAll());
        if (
          profile.hasInitialPreferences &&
          profile.profileTutorials
        ) {
          dispatch(modalCloseAll());
        }
      }else{
        dispatch(
          extraActions.setError(
            NGAutoLoginForm,
            "msisdn",
            "not found"
          )
        );
      }
    } else {
      if (response.data.error.code == "V001") {
        dispatch(extraActions.setError(BillingInfoForm, "phonenumber", "V002"));
      } else {
        dispatch(
          extraActions.setError(
            BillingInfoForm,
            "phonenumber",
            response.data.error.code
          )
        );
      }
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingForgotPasswordForm = FormActionCreator({
  ...BillingForgotPasswordForm,
  service: onBillingForgotPasswordRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.otpToken) {
        dispatch(
          billingForgotPasswordForm.updateField(
            "otptoken",
            response.data.data.otpToken ? response.data.data.otpToken : ""
          )
        );

        var pageURL = window.location.href;
        var lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

        if (lastURLSegment !== "billing-resetpassword") {
          const lang = i18n.language;
          window.location.href = "/" + lang + "/billing-resetpassword";
        }
      }
    } else {
      dispatch(
        extraActions.setError(
          BillingForgotPasswordForm,
          "phonenumber",
          response.data.error.code
        )
      );
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingResetPasswordForm = FormActionCreator({
  ...BillingResetPasswordForm,
  service: onBillingResetPasswordRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const lang = i18n.language;

      window.location.href = "/" + lang + "/billing-login";
    } else {
      dispatch(
        extraActions.setError(
          BillingResetPasswordForm,
          "otp",
          response.data.error.code
        )
      );
      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingUnsubscribeForm = FormActionCreator({
  ...UnsubscribeForm,
  service: onUnsubscribeRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      // dispatch(logoutUserIfNeeded());
      dispatch(unsubscribe.close());
      dispatch(unsubscribeSuccess.open());
    } else {
      dispatch(
        extraActions.setError(
          UnsubscribeForm,
          "phonenumber",
          response.data.error.code
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingHoldForm = FormActionCreator({
  ...BillingHoldForm,
  service: onHoldRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        billingHoldForm.updateField(
          "status",
          response.data.data.verificationStatus
        )
      );
      dispatch(
        subscribeWrapmeForm.updateField(
          "phonenumber",
          response.data.data.username
            ? response.data.data.username
            : ""
        )
      );
      if (response.data.data.verificationStatus == "failed") {
        dispatch(vasError.open());
        dispatch(extraActions.setError(BillingHoldForm, "onhold", "ST001"));
      }
    } else {
      dispatch(
        extraActions.setError(
          BillingHoldForm,
          "onhold",
          response.data.error.code
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const billingNGHoldForm = FormActionCreator({
  ...BillingNGHoldForm,
  service: onNGHoldRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        billingNGHoldForm.updateField(
          "status",
          response.data.data.verificationStatus
        )
      );
      if (response.data.data.verificationStatus === "failed") {
        dispatch(extraActions.setError(BillingNGHoldForm, "onhold", "ST001"));
        dispatch(vasError.open());
      }
      else if (response.data.data.verificationStatus === "verified") {
        let profile = response.data.data["0"];
        if (profile) {
          if (profile.token){
            axiosInstance.setToken(profile.token);
          }
          const hasCreateName = profile.hasInitialPreferences
            ? true
            : false;
          const hasCreateAvatar = profile.hasInitialPreferences
            ? true
            : false;
          const hasCreateTutorial = profile.hasInitialPreferences
            ? true
            : false;
          let param = {
            data: profile
          };
        
          dispatch(loginSuccess(param));
        
          setTimeout(() => {
            dispatch(initialiseSocket());
          }, 500);
          let tutorialsParam = {
            hasCreatenamePreferences: hasCreateName,
            hasCreateavatarPreferences: hasCreateAvatar,
            hasCreatetutorialPreferences: hasCreateTutorial
          };
          dispatch(tutorialRequest(tutorialsParam));
          let successLogin = (
            <Trans i18nKey="successLogin">You are successfully login.</Trans>
          );
          dispatch(extraActions.setSuccess(NGLoginForm, "msisdn", successLogin));
          dispatch(extraActions.reset(NGLoginForm));
          dispatch(sidebarCloseAll());
          if (
            profile.hasInitialPreferences &&
            profile.profileTutorials
          ) {
            dispatch(modalCloseAll());
          }
        } else {
          dispatch(
            extraActions.setError(
                NGLoginForm,
              "msisdn",
              response.data.error && response.data.error.code
                ? response.data.error.code
                : "CONTACT_SUPPORT",
              {
                text:
                  response.data.info !== undefined ? response.data.info.text : "",
                shortCode:
                  response.data.info !== undefined
                    ? response.data.info.shortCode
                    : "",
                reason:
                  response.data.banReason !== undefined
                    ? response.data.banReason
                    : ""
              }
            )
          );
        }
      }
    } else {
      dispatch(
        extraActions.setError(
          BillingNGHoldForm,
          "onhold",
          response.data.error.code
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const subscribeWrapmeForm = FormActionCreator({
  ...SubscribeWrapmeForm,
  service: onSubscribeWrapmeRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (typeof (response.data.data.autoLogin) !== "undefined" && response.data.data.autoLogin) {
        let profile = response.data.data["0"];
        // auto Login
        // _fnAutoLogin(profile, dispatch, extraActions);
        if (profile.token){
          axiosInstance.setToken(profile.token);
        }
        const hasCreateName = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateAvatar = profile.hasInitialPreferences
          ? true
          : false;
        const hasCreateTutorial = profile.hasInitialPreferences
          ? true
          : false;
        let param = {
          data: profile
        };
      
        dispatch(loginSuccess(param));
        setTimeout(() => {
          dispatch(initialiseSocket());
        }, 500);
        let tutorialsParam = {
          hasCreatenamePreferences: hasCreateName,
          hasCreateavatarPreferences: hasCreateAvatar,
          hasCreatetutorialPreferences: hasCreateTutorial
        };
        dispatch(tutorialRequest(tutorialsParam));
        let successLogin = (
          <Trans i18nKey="successLogin">You are successfully login.</Trans>
        );
        dispatch(extraActions.setSuccess(NGLoginForm, "phoneNumber", successLogin));
        dispatch(extraActions.reset(NGLoginForm));
        dispatch(sidebarCloseAll());
        if (
          profile.hasInitialPreferences &&
          profile.profileTutorials
        ) {
          dispatch(modalCloseAll());
        }
      }
      else if (response.data.data.code === "SUB013") {
        dispatch(
          subscribeWrapmeForm.updateField(
            "phonenumber",
            response.data.data.username
              ? response.data.data.username
              : ""
          )
        );
        dispatch(
          billingSubscriptionForm.updateField(
            "mondiaSubscriptionId",
            response.data.data.mondiaSubscriptionId
              ? response.data.data.mondiaSubscriptionId
              : ""
          )
        );
        dispatch(
          subscribeWrapmeForm.updateField(
            "phonenumber",
            response.data.data.username
              ? response.data.data.username
              : ""
          )
        );
        const lang = i18n.language;
        window.location.href = "/" + lang + "/billing-registration";
      }
      else {
        if (response.data.data.verificationOnHold) {
          if(isNgCountry()){
            dispatch(
              billingNGHoldForm.updateField(
                "subscriptionId",
                response.data.data.subscriptionId
                  ? response.data.data.subscriptionId
                  : ""
              )
            );
          }else{
            dispatch(
              billingHoldForm.updateField(
                "subscriptionId",
                response.data.data.subscriptionId
                  ? response.data.data.subscriptionId
                  : ""
              )
            );
          }
         
          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaCustomerId",
              response.data.data.mondiaCustomerId
                ? response.data.data.mondiaCustomerId
                : ""
            )
          );
          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaSubscriptionId",
              response.data.data.subscriptionId
                ? response.data.data.subscriptionId
                : ""
            )
          );

          const lang = i18n.language;
          if(isNgCountry()){
            window.location.href = "/" + lang + "/ng-billing-holt";
          }
          else{
            window.location.href = "/" + lang + "/billing-holt";
          }
        } else if (
          response.data.data.mondiaCustomerId &&
          response.data.data.subscriptionId
        ) {
          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaCustomerId",
              response.data.data.mondiaCustomerId
                ? response.data.data.mondiaCustomerId
                : ""
            )
          );

          dispatch(
            billingSubscriptionForm.updateField(
              "mondiaSubscriptionId",
              response.data.data.subscriptionId
                ? response.data.data.subscriptionId
                : ""
            )
          );
          const lang = i18n.language;
          window.location.href = "/" + lang + "/billing-subscription";
        }
      }
    } else {
      if (response.data.error.code === "SUB02"){
        dispatch(
          subscribeWrapmeForm.updateField(
            "phonenumber",
            response.data.error.username
              ? response.data.error.username
              : ""
          )
        );
        dispatch(extraActions.setError(SubscribeWrapmeForm, "token", "SUB02"));
      }
      else if (response.data.error.code == "SUB05") {
        dispatch(extraActions.setError(SubscribeWrapmeForm, "token", "SUB05"));
      } 
      else if (response.data.error.code == "SUB016") {
        dispatch(extraActions.setError(SubscribeWrapmeForm, "token", "SUB016"));
      } 
      else if (response.data.error.code == "V001") {
        dispatch(extraActions.setError(SubscribeWrapmeForm, "token", "V002"));
      } 
      else {
        dispatch(
          extraActions.setError(
            SubscribeWrapmeForm,
            "token",
            response.data.error.code
          )
        );
      }

      dispatch(vasError.open());
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const mtnxBillingHoldForm = FormActionCreator({
  ...MTNXBillingHoldForm,
  service: onGetSubscribedToSite,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        mtnxBillingHoldForm.updateField(
          "status",
          response.data.data.subscribedStatus
        )
      );
      dispatch(
        billingRegistrationForm.updateField(
            "subscriptionId",
            response.data.data.subscriptionId
        )
      )
      dispatch(
        billingRegistrationForm.updateField(
            "phonenumber",
            response.data.data.phoneNumber
        )
      )
      if (response.data.data.subscribedStatus == "failed") {
        dispatch(vasError.open());
        dispatch(extraActions.setError(MTNXBillingHoldForm, "onhold", "ST001"));
      }
    } else {
      dispatch(
        extraActions.setError(
          MTNXBillingHoldForm,
          "onhold",
          response.data.error.code
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});
