import React, { Component } from "react";
import "./TermsConditionsComponent.scss";
import ScheduleDownload from "../../assets/pdfs/miggster-schedule.pdf";
import RulesDownload from "../../assets/pdfs/miggster-terms-and-conditions.pdf";
// import CompetitionDownload from "../../assets/pdfs/AU$1MILLION-terms-and-conditions.pdf";
import platformParams from "../../utils/platformParams";

class TermsContent extends Component {
  render() {
    return (
      <>
        <section className={this.props.setclass}>
          <div className={this.props.setclass!=="container-fluid"?"padder-bottom":""}>
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center ">
                  <div className="col-sm-12">
                    {/* DOWNLOADS */}
                    <h4 className="bold mb-4">Promotional Competition &amp; Tournaments</h4>
                    <div className="downloads-container">
                      <a href={RulesDownload} target="_blank" rel="noopener noreferrer" className="download-btn">Rules</a>
                      <a href={ScheduleDownload} target="_blank" rel="noopener noreferrer" className="download-btn">Schedule</a>
                      {/* <a href={CompetitionDownload} target="_blank" rel="noopener noreferrer" className="download-btn">Competition</a> */}
                    </div>

                    {/* 
                      !!!!!!!!NB!!!!!!!!!
                      if you are looking for the old Ts&Cs look back in the version control to any commit before this one

                      commit before this one: 7157014d54d07d49a44737bcb59ceaef9b29327b
                    */}

                    {/* Customer Service */}
                    <h6 className="bold mb-4">Customer Service &amp; Service Management</h6>
                    <ul className="w-100 mb-4 text-muted list-unstyled">
                      <li className="mb-2">
                        If you have any questions concerning this Agreement please contact support on support@gameout.com via email.
                      </li>
                    </ul>
                    <div className="downloads-container">
                      <a href={"https://miggster.freshdesk.com/en/support/solutions/67000388166"} target="_blank" rel="noopener noreferrer" className="terms-btn">Terms &amp; Conditions</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TermsContent;
