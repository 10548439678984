import React, { Component } from "react";
import { connect } from "react-redux";
import {
  profileEditSidebar,
  profileSidebar,
  friendListSidebar
} from "../../../../actions/sidebars";
import {
  profileEditForm,
  usernameAvailableForm,
  editAvatarForm
} from "../../../../actions/forms";
import navClose from "../../../../assets/images/svg/nav-close.svg";
// import profilePic120 from "../../../../assets/images/avatar-demo.png";
// Retrofy commented out
// import goldTokens from "../../../../assets/images/c-default.png";
// Eurofy added in
import goldTokens from "../../../../assets/images/football-credits.png";
import { logoutUserIfNeeded } from "../../../../actions/user";
import { changePassword, maintenanceModeModal } from "../../../../actions/modals";
import UpdateDimension from "../../../../components/Utils/UpdateDimension";
import { withTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import { fileUpload } from "../../../../services/fileUpload.service";
import { isNgCountry } from "../../../../utils/misc";
import platformParams from "../../../../utils/platformParams";

class RightSidebarProfile extends Component {
  constructor(props) {
    super(props);
    this.showRightSidebar = this.showRightSidebar.bind(this);
    this.hideRightSidebar = this.hideRightSidebar.bind(this);
    this.hideRightSidebarProxy = this.hideRightSidebarProxy.bind(this);
    this.updateDimension = this.updateDimension.bind(this);
    this.Capitalize = this.Capitalize.bind(this);
    this.prepareForm = this.prepareForm.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.addDefaultSrc = this.addDefaultSrc.bind(this);
    this.state = {
      isLandscape: false
    };
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  componentWillMount() {
    if (!this.props.disableOuterClick) {
      document.addEventListener("mousedown", this.hideRightSidebarProxy, false);
    }
  }
  componentWillUnmount() {
    if (!this.props.disableOuterClick) {
      document.removeEventListener(
        "mousedown",
        this.hideRightSidebarProxy,
        false
      );
    }
  }
  updateDimension() {
    this.setState({ isLandscape: window.innerWidth > window.innerHeight });
  }
  showRightSidebar() {
    this.props.profileSidebarOpen();
  }
  hideRightSidebar() {
    this.props.profileSidebarClose();
  }
  prepareForm() {
    this.props.usernameAvailableFormReset();
    this.props.profileEditFormReset();
    this.props.profileEditFormUpdateField("firstName", this.props.firstName);
    this.props.profileEditFormUpdateField("lastName", this.props.lastName);
    this.props.profileEditFormUpdateField("username", this.props.name);
    this.props.profileEditFormUpdateField("mobile", this.props.mobile);
    this.props.profileEditFormUpdateField("instagram", this.props.instagram);
    this.props.profileEditFormUpdateField("twitter", this.props.twitter);
    this.props.profileEditFormUpdateField("facebook", this.props.facebook);
    this.props.profileEditSidebarOpen();
  }

  hideRightSidebarProxy = e => {
    if (this.props.disableOuterClick) {
      return;
    }
    if (this.rightSidebar && this.rightSidebar.contains(e.target)) {
      return;
    }
    if (this.props.isProfileSidebarOpen) {
      this.hideRightSidebar();
    }
    return;
  };
  addDefaultSrc(ev) {
    ev.target.src = "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png";
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      const fileType = event.target.files[0].type.split("/")[1].toLowerCase();

      if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        this.props.createAvatarFormSetError("avatar", "FILE_TYPE");
        return false;
      } else if (fileSize > 2) {
        this.props.createAvatarFormSetError("avatar", "FILE_SIZE");
        return false;
      }
      // this.props.createAvatarFormResetError("avatar");
      fileUpload(event.target.files)
        .then(url => {
          this.props.createAvatarFormUpdateField("avatar", url);

          // if (this.props.createAvatarForm.isSubmitting) {
          //   return (
          //     <div className="text-center">
          //       <div className="col-sm-12">
          //         <img className={"loader-" + platformParams['className']} src={platformParams['loadingGif']} alt="" /> 
          //       </div>
          //     </div>
          //   );
          // }

          if (!this.props.createAvatarForm.isSubmitting) {
            this.props.createAvatarFormSubmit({
              avatarUrl: this.props.createAvatarForm.fields.avatar.value
            });
          }
        })
        .catch(() => {
          // console.log(this);
        });
      // this.props.createAvatarFormReset(createAvatarForm);
    } else {
      this.props.createAvatarFormUpdateField("avatar", "");
    }
  }

  render() {
    const { t } = this.props;
    const lang = i18n.language;
    return (
      <UpdateDimension hasSetState={true} onUpdate={this.updateDimension}>
        <>
          <nav
            className={
              "profile-slider" +
              (this.props.isProfileSidebarOpen ? " active" : "")
            }
            ref={rightSidebar => {
              this.rightSidebar = rightSidebar;
            }}
          >
            <div className="fixed-sidebar-scroll-landscap active">
              <div className="dismiss">
                <span className="icon-close">
                  <img src={navClose} alt="" onClick={this.hideRightSidebar} />
                </span>
              </div>
              <section
                className={
                  "d-flex flex-column justify-content-between h-100 profile-slider-container " +
                  (this.state.isLandscape ? " landscape" : "")
                }
              >
                <section className="row no-gutters">
                  <div className="col-sm-12 text-center">
                    <div
                      className={ "sidebar-header " + platformParams['className'] }
                    >
                      <figure className="mt-4">
                        <img
                          src={this.props.avatarUrl}
                          className="profile-pic-120"
                          alt=""
                          onError={this.addDefaultSrc}
                        />
                        <figcaption className="online-120" />
                      </figure>

                      <h4 className={"bold mt-2 profile-name text-" + platformParams['textColorClassSufix']}>
                        {this.Capitalize(this.props.loginUserName)}
                      </h4>
                      <div className="mt-1">
                        <small className={"text-" + platformParams['textColorClassSufix']}>
                          <span className={"bold text-" + platformParams['textColorClassSufix']}>
                            {this.props.followersCount}
                          </span>{" "}
                          Fans &nbsp;&nbsp;{" "}
                          <span className={"bold text-" + platformParams['textColorClassSufix']}>
                            {this.props.followingCount}
                          </span>{" "}
                          {t("following")}
                        </small>
                      </div>
                      <div className="text-center edit-buttons mt-1">
                        <span className="a"
                          onClick={this.prepareForm}
                        >
                          {t("editProfile")}
                        </span>
                        <span className="a">
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                        </span>
                        <span
                          className="a"
                          onClick={() => {
                            this.props.changePasswordOpen();
                          }}
                        >
                          {t("changePassword")}
                        </span>
                        <span
                          className="a">
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                        </span>
                        <input
                          type="file"
                          accept="image/*"
                          name="img[]"
                          className="file d-none"
                          onChange={this.uploadFile}
                          ref={input => (this.inputElement = input)}
                        />

                        <span
                          className="a" onClick={() => this.inputElement.click()}>
                          {t("changeProfilePic")}
                        </span>
                      </div>

                      {/* <div className="text-center edit-buttons mt-1">
                        <span></span>
                        <input
                          type="file"
                          accept="image/*"
                          name="img[]"
                          className="file d-none"
                          onChange={this.uploadFile}
                          ref={input => (this.inputElement = input)}
                        />

                        <span
                          className={
                            "a  semi-bold text-" + 
                            platformParams['textColorClassSufix'] }
                          onClick={() => this.inputElement.click()}
                        >
                          {t("changeProfilePic")}
                        </span>
                        <span></span>
                      </div> */}
                    </div>
                  </div>
                </section>
                <section className="player-content">
                  <div className="profile-challenge-info-container">
                    <div className="profile-challenge-info">
                      <div className="retro-profile-img-container">
                        <img
                          src={platformParams['gameController3x']}
                          alt=""
                          className="retro-profile-img"
                        />
                      </div>
                      <div className="retro-profile-info-container">
                        <h5
                          className={
                            "text-" + 
                            platformParams['textColorClassSufix'] + " uppercase"
                          }
                        >
                          {t("challengePage.gamePlayed")}
                        </h5>
                        <h2 className="bold mt-1">{this.props.gamesPlayed}</h2>
                      </div>
                    </div>
                    <div className="profile-challenge-info">
                      <div className="retro-profile-img-container">
                        <img
                          src={platformParams['trophy3x']}
                          alt=""
                          className="retro-profile-img"
                        />
                      </div>
                      <div className="retro-profile-info-container">
                        <h5
                          className={
                            "text-" + 
                            platformParams['textColorClassSufix'] + " uppercase"
                          }
                        >
                          {t("navTournaments")}
                        </h5>
                        <h2 className="bold mt-1">{this.props.tournamentsCount}</h2>
                      </div>
                    </div>
                    <div className="profile-challenge-info">
                      <div className="retro-profile-img-container">
                        <img
                          src={platformParams['prize3x']}
                          alt=""
                          className="retro-profile-img"
                        />
                      </div>
                      <div className="retro-profile-info-container">
                        <h5
                          className={
                            "text-" + 
                            platformParams['textColorClassSufix'] + " uppercase"
                          }
                        >
                          {t("prizeWon")}
                        </h5>
                        <h2 className="bold mt-1">{this.props.prizesWon}</h2>
                      </div>
                    </div>
                    {/* Retrofy commented out */}
                    {/* <div className="col-6 text-center d-flex align-items-center flex-column mb-4 mb-md-4">
                      <img
                        src={platformParams['gameControllerSvg']}
                        alt=""
                        className="d-block d-md-none"
                      />
                      <img
                        src={platformParams['gameController3x']}
                        alt=""
                        className="d-none d-md-block"
                      />
                      <h6
                        className={
                          "semi-bold mt-1 mt-lg-3 text-" + 
                          platformParams['textColorClassSufix']
                        }
                      >
                        {t("challengePage.gamePlayed")}
                      </h6>
                      <h2 className="bold mt-1">{this.props.gamesPlayed}</h2>
                    </div>
                    <div className="col-6 text-center d-flex align-items-center flex-column mb-4 mb-md-4">
                      <img
                        src={platformParams['trophySvg']}
                        alt=""
                        className="d-block d-md-none"
                      />
                      <img
                        src={platformParams['trophy3x']}
                        alt=""
                        className="d-none d-md-block"
                      />
                      <h6
                        className={
                          "semi-bold mt-1 mt-lg-3 text-" + 
                          platformParams['textColorClassSufix']
                        }
                      >
                        {t("navTournaments")}
                      </h6>
                      <h2 className="bold mt-1">{this.props.tournamentsCount}</h2>
                    </div>
                    <div className="col-12 text-center d-flex align-items-center flex-column mb-5 mb-md-4">
                      <img src={platformParams['prizeSvg']} alt="" className="d-block d-md-none" />
                      <img
                        src={platformParams['prize3x']}
                        alt=""
                        className="d-none d-md-block"
                      />
                      <h6
                        className={
                          "semi-bold mt-1 mt-lg-3 text-" + 
                          platformParams['textColorClassSufix']
                        }
                      >
                        {t("prizeWon")}
                      </h6>
                      <h2 className="bold mt-1">{this.props.prizesWon}</h2>
                    </div> */}
                  </div>
                </section>
                <section className="row no-gutters side-links-wrapper">
                  <div className="col-sm-12">
                    <ul className="list-unstyled">
                      <span
                        className="a"
                        onClick={this.props.friendListSidebarOpen}
                      >
                        <li className="border-top">
                          <ul className="list-inline d-flex side-links">
                            <li className="list-inline-item flex-fill right-arrow">
                              {t("myFriend")}
                            </li>
                          </ul>
                        </li>
                      </span>
                      {
                        process.env.REACT_APP_PLATFORM != "arcadex" ? 
                          <span
                          className="a"
                          onClick={() => {
                            this.props.logoutUserIfNeeded();
                          }}
                          >
                            <li className="border-top">
                              <ul className="list-inline d-flex side-links">
                                <li className="list-inline-item flex-fill">
                                  {t("logout")}
                                </li>
                              </ul>
                            </li>
                          </span>
                          : <></>
                      }
                    </ul>
                  </div>
                  <div
                    className={
                      "col-sm-12 coin-counter " + platformParams['className']
                    }
                  >
                    <div className="row no-gutters d-flex align-items-center justify-content-between">
                      <div className="col-auto">
                        <h5 className="bold text-white">
                          {t("youHave")} {this.props.tokensInWallet}{" "}
                          {t("tokens")}
                        </h5>
                        {
                          !isNgCountry() ?
                            <a href={"/" + lang + "/giveaways"}>
                              <small className="text-white">
                                {t("enterGiveaways")}
                              </small>
                            </a>
                            : <></>  
                        }
                      </div>
                      <div className="col-auto">
                        <img src={goldTokens} alt="" className="token-img" />
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </nav>
        </>
      </UpdateDimension>
    );
  }
}
const mapDispatchToProps = {
  disableOuterClick: false,
  profileSidebarOpen: profileSidebar.open,
  profileSidebarClose: profileSidebar.close,
  profileEditSidebarOpen: profileEditSidebar.open,
  friendListSidebarOpen: friendListSidebar.open,
  logoutUserIfNeeded,
  changePasswordOpen: changePassword.open,
  maintenanceModeOpen: maintenanceModeModal.open,
  profileEditFormUpdateField: profileEditForm.updateField,
  profileEditFormReset: profileEditForm.reset,
  usernameAvailableFormReset: usernameAvailableForm.reset,

  createAvatarFormSubmit: editAvatarForm.submit,
  createAvatarFormReset: editAvatarForm.reset,
  createAvatarFormResetField: editAvatarForm.resetField,
  createAvatarFormUpdateField: editAvatarForm.updateField,
  createAvatarFormSetError: editAvatarForm.setError,
  createAvatarFormSetSuccess: editAvatarForm.setSuccess,
  createAvatarFormResetError: editAvatarForm.resetError,
  createAvatarFormResetSuccess: editAvatarForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    tokensInWallet: state.user.item.points ? state.user.item.points : 0,
    isProfileSidebarOpen: state.sidebars.isProfileSidebarOpen,
    loginUserName: state.user.item.name ? state.user.item.name : "",
    avatarUrl: state.user.item.avatarUrl
      ? state.user.item.avatarUrl
      : "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png",
    gamesPlayed: state.user.item.gamesPlayed ? state.user.item.gamesPlayed : 0,
    tournamentsCount: state.user.item.tournamentsCount ? state.user.item.tournamentsCount : 0,
    prizesWon: state.user.item.prizesWon ? state.user.item.prizesWon : 0,
    overallRank: state.user.item.overallRank ? state.user.item.overallRank : 0,
    followersCount: state.user.item.followersCount
      ? state.user.item.followersCount
      : 0,
    followingCount: state.user.item.followingsCount
      ? state.user.item.followingsCount
      : 0,
    profileEditForm: state.forms.profileEditForm,
    firstName: state.user.item.firstName ? state.user.item.firstName : "",
    lastName: state.user.item.lastName ? state.user.item.lastName : "",
    name: state.user.item.name ? state.user.item.name : "",
    mobile: state.user.item ? "" : "",
    instagram: state.user.item ? state.user.item.socialInstagram : "",
    twitter: state.user.item ? state.user.item.socialTwitter : "",
    facebook: state.user.item ? state.user.item.socialFacebook : "",
    userId: state.user.item ? state.user.item.id : 0,
    createAvatarForm: state.forms.editAvatarForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RightSidebarProfile));
