import React, { Component } from "react";
import { connect } from "react-redux";
import {
  messageListSidebar,
  chatListSidebar
} from "../../../../actions/sidebars";
import navClose from "../../../../assets/images/svg/nav-close.svg";
import arrowRightSvg from "../../../../assets/images/svg/nav-arrow-right.svg";
import "./ChatList.scss";
import sendIcon from "../../../../assets/images/svg/send.svg";
// import profilePic48 from "../../../../assets/images/avatar-demo.png";
import ReactDOM from "react-dom";
import { convertDateForMessage } from "../../../../utils/date";
import {
  loadMoreChat,
  closeChatHistory,
  messageSent
} from "../../../../actions/chathistory";
import { messageHistoryRequest } from "../../../../actions/messagehistory";
import { withTranslation,Trans } from "react-i18next";
import platformParams from "../../../../utils/platformParams";
// import i18n from "../../i18n";

class ChatList extends Component {
  constructor(props) {
    super(props);
    this.hideRightSidebar = this.hideRightSidebar.bind(this);
    this.showMessageListSidebar = this.showMessageListSidebar.bind(this);
    this.getChatMessages = this.getChatMessages.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.addDefaultSrc = this.addDefaultSrc.bind(this);
    this.state = {
      message: ""
    };
  }

  scrollToBottom = () => {
    const messagesContainer = ReactDOM.findDOMNode(this.messagesContainer);
    if (messagesContainer)
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
  };

  // componentWillReceiveProps(nextProps)
  // {
  //   if(this.props.chatHistory.page !== nextProps.chatHistory.page)
  //   {
  //     setTimeout(
  //       function() {
  //         if(this.messagesContainer)
  //         this.messagesContainer.scrollTop = 800;
  //       }.bind(this),
  //       300
  //     );
  //   }

  //   if(this.props.chatUserId == "" && nextProps.chatUserId !== "")
  //   {
  //     setTimeout(
  //       function() {
  //         console.log("WER");
  //         this.scrollToBottom();
  //       }.bind(this),
  //       300
  //     );

  //   }
  //   console.log(this.props.chatUserId,"this.props")
  //   console.log(nextProps.chatUserId,"nextProps")
  // }

  onScroll = () => {
    const scrollTop = this.messagesContainer.scrollTop;
    if (scrollTop === 0) {
      console.log('Loading more chats...')
      this.props.loadMoreChat(this.props.chatUserId);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.chatHistory.vScrollPosition === "bottom" &&
      prevProps.totalMessages !== this.props.totalMessages
    ) {
      this.scrollToBottom();
    }

    if (this.props.chatHistory.vScrollPosition === "top") {
      this.messagesContainer.scrollTop = 800;
    }
  }

  updateMessageText(message) {
    this.setState({ message: message });
  }

  addDefaultSrc(ev) {
    ev.target.src = "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png";
  }

  formattedImgUrl(imageurl) {
    let formattedImgUrl = '';
    let tempImgUrl = imageurl;
    if (((tempImgUrl.match(/http/g) || []).length) == 2) {
      formattedImgUrl = tempImgUrl.substring(tempImgUrl.lastIndexOf("http"));
    } else if (((tempImgUrl.match(/\/images/g) || []).length) == 0) {
      formattedImgUrl = tempImgUrl.replace("images/", "/images/");
    } else {
      formattedImgUrl = imageurl;
    }

    return formattedImgUrl;
  }

  showMessageListSidebar() {
    this.props.messageHistoryRequest();
    this.props.closeChatHistory();
    this.props.chatListSidebarClose();
    this.props.messageListSidebarOpen();
  }

  hideRightSidebar() {
    this.props.closeChatHistory();
    this.props.chatListSidebarClose();
  }

  getChatMessages() {
    let chat =
      this.props.chatHistory.items.length > 0
        ? this.props.chatHistory.items
        : [];
    if (chat.length == 0) {
      return ( <div className="text-center chatLoader">
                <div className="col-sm-12 ">
                 <img className={"loader-" + platformParams['className']} src={platformParams['loadingGif']} alt=""  /> 
               </div>
              </div>);
    }
    
    // Replace invalid profile avatars with correct format
    let formattedImgUrl = this.formattedImgUrl(chat[0].friend_avatar_url);
    let formattedFriendImgUrl = this.formattedImgUrl(this.props.user.avatarUrl);

    let chatProfileUrl = chat[0].friend_avatar_url
      ? formattedImgUrl
      : "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png";
    let userProfileUrl = this.props.user.avatarUrl
      ? formattedFriendImgUrl
      : "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png";

    return chat.map((message, index) => {
      const time = convertDateForMessage(message.time_of_creation);
      message.system = false;
      message.sender = false;
      if (message.type == 2) {
        message.system = true;
      }
      if (message.from_user_id == this.props.user.id) {
        message.sender = true;
      }

      if (message.system) {
        return (
          <div
            className={"clearfix " + (message.sender ? "sender" : "")}
            key={index}
          >
            <div className="col-sm-12">
              <div className={"media my-2"}>
                <div className={`media-body mr-3 ${this.props.chatHistory.items.length === 1 ? "intro" : "intro"}`}>
                  <div className="message-wrapper replay mt-2 text-center">
                    <p className="mb-0">
                      {
                        <Trans i18nKey="sayHi">
                          Say hi to your new friend!
                        </Trans>
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* {time ? (
              <div className="col-sm-12 text-center">
                <p className="fs-12 semi-bold date-time mb-0">{time}</p>
              </div>
            ) : (
              ""
            )} */}
          </div>
        );
      } else if (message.sender) {
        return (
          <div
            className={"clearfix " + (message.sender ? "sender" : "")}
            key={index}
          >
            <div className="col-sm-12">
              <div className="media my-2">
                <div className="media-body mr-3">
                  <p className="mb-0 fs-12 bold text-right">
                    {this.props.user.name}
                  </p>
                  <div className="message-wrapper replay mt-2">
                    <p className="mb-0">{message.text || ""}</p>
                  </div>
                  {time ? (
                    <p className="fs-12 semi-bold date-time mb-0 text-right my-1">
                      {time}
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                <img
                  src={message.sender ? userProfileUrl : chatProfileUrl}
                  alt=""
                  className="profile-pic-48"
                  onError={(e)=>(e.target.src="https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png")}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={"clearfix" + (message.sender ? " sender" : "")}
            key={index}
          >
            <div className="col-sm-12">
              <div className="media my-2">
                <img
                  src={message.sender ? userProfileUrl : chatProfileUrl}
                  alt={message.friend_name}
                  className="profile-pic-48"
                  onError={(e)=>(e.target.src="https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png")}
                />
                <div className="media-body ml-3">
                  <p className="mb-0 fs-12 bold">{message.friend_name}</p>
                  <div className="message-wrapper message mt-2">
                    <p className="mb-0">{message.text || ""}</p>
                  </div>
                  {time ? (
                    <p className="fs-12 semi-bold date-time mb-0 my-1">
                      {time}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  }

  render() {
    const chatUserDetail = this.props.chatHistory.chatUserDetail;
    const chatRoomId = this.props.chatHistory.chatRoomId;
    const allOnlineUserList = this.props.allOnlineUserList;

    let chat = this.props.chatHistory.items.length > 0 ? this.props.chatHistory.items : [];
    let formattedImgUrl = '';
    if (chat.length > 0) {
      // Replace invalid profile avatars with correct format
      formattedImgUrl = this.formattedImgUrl(chat[0].friend_avatar_url);
    }

    if (!this.props.chatUserId) {
      return null;
    }
    const { t } = this.props;
    return (
      <>
        <nav
          className={
            "chat-window-slider" +
            (this.props.isChatListSidebarOpen ? " active" : "")
          }
          ref={el => {
            this.el = el;
          }}
        >
          <div className="fixed-sidebar-scroll-landscap chat-screen active">
            <div className="dismiss d-lg-block">
              <span className="icon-close">
                <img src={navClose} alt="" onClick={this.hideRightSidebar} />
              </span>
            </div>
            <section className="d-flex flex-column h-100">
              <div className="row no-gutters ">
                <div className="col-sm-12 text-center">
                  <div className="sidebar-header">
                    <div className="message-header bb-1">
                      <div className="media align-items-center">
                        <span
                          onClick={this.showMessageListSidebar}
                          className="back-arrow"
                        >
                          <img src={arrowRightSvg} alt="" />
                        </span>

                        <img
                          src={
                            formattedImgUrl
                              ? formattedImgUrl
                              : "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png"
                          }
                          className="profile-pic-48"
                          alt=""
                          onError={this.addDefaultSrc}
                        />
                        {allOnlineUserList.includes(
                          this.props.chatUserId.toString()
                        ) ? (
                          <figure>
                            <figcaption className="online-48"></figcaption>
                          </figure>
                        ) : null}
                        <div className="media-body text-left ml-3">
                          <h6 className="bold">{chatUserDetail.userName}</h6>
                          <p className="mb-0 fs-12 time-indication">
                            {allOnlineUserList.includes(
                              this.props.chatUserId.toString()
                            )
                              ? "Online"
                              : "Offline"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`
                  row 
                  no-gutters 
                  chat-window 
                  background-reverse 
                  d-flex 
                  ${this.props.chatHistory.items.length > 1 ? 
                    ("chatjustify-content-start align-items-start align-content-start") : 
                    ("chatjustify-content-center align-items-center align-content-center")
                  }`}
                ref={el => {
                  this.messagesContainer = el;
                }}
                onScroll={this.onScroll}
              >
                {this.getChatMessages()}
              </div>

              <div className="row no-gutters mt-auto footer-text-view">
                <div className="col-sm-12 d-flex justify-content-start align-items-center">
                  <form action="#" className="form-inline w-100">
                    <div className="mr-auto w-75">
                      <div className="form-group without-lable">
                        <div className="input-group-wrapper w-100">
                          <textarea
                            type="text"
                            value={this.state.message}
                            className="form-control effect-1 w-100 textareaFiled"                            
                            placeholder={t("placeTypeNumber")}

                            rows="1"
                            cols="1"
                            onKeyUp={e => {
                              if (e.keyCode === 13 && e.shiftKey === false) {
                                if (this.state.message.trim() !== "") {
                                  this.props.messageSent(
                                    this.state.message.substr(
                                      0,
                                      this.state.message.length - 1
                                    ),
                                    chatRoomId
                                  );
                                  this.updateMessageText("");
                                }
                              }
                            }}
                            onChange={event => {
                              this.updateMessageText(event.currentTarget.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" ml-3">
                      <span
                        className={`a ${this.state.message.trim() !== "" ? " send is-active" : " send"}`}
                        onClick={e => {
                          if (this.state.message.trim() !== "") {
                            this.props.messageSent(
                              this.state.message,
                              chatRoomId
                            );
                            this.updateMessageText("");
                          }
                        }}
                      >
                        <img src={sendIcon} alt="" />
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </nav>
      </>
    );
  }
}

const mapDispatchToProps = {
  disableOuterClick: true,
  messageListSidebarOpen: messageListSidebar.open,
  chatListSidebarOpen: chatListSidebar.open,
  chatListSidebarClose: chatListSidebar.close,
  messageSent: messageSent,
  loadMoreChat: loadMoreChat,
  closeChatHistory: closeChatHistory,
  messageHistoryRequest: messageHistoryRequest
};

const mapStateToProps = state => {
  const chatHistory = state.chathistory;
  const totalMessages = state.chathistory.items.length;

  return {
    isChatListSidebarOpen: state.sidebars.isChatListSidebarOpen,
    user: state.user.item,
    userId: state.user.item ? state.user.item.id : 0,
    chatUserId: state.chathistory.chatUserId,
    chatHistory: chatHistory,
    totalMessages: totalMessages,
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatList));
