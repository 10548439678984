export const LoginForm = {
  code: "LOGIN",
  stateKey: "forms",
  stateSubKey: "loginForm",
  fields: ["email", "password"]
};
export const LoginHyveForm = {
  code: "LOGIN_HYVE",
  stateKey: "forms",
  stateSubKey: "loginHyveForm",
  fields: ["email", "password", "mobile"]
};
export const RegisterForm = {
  code: "REGISTER",
  stateKey: "forms",
  stateSubKey: "registerForm",
  fields: ["email", "username", "password", "phone", "terms", "policy"]
};
export const RegisterHyveForm = {
  code: "REGISTER_HYVE",
  stateKey: "forms",
  stateSubKey: "registerHyveForm",
  fields: ["email", "username", "password", "phone", "terms", "policy"]
};

export const BanUserForm = {
  code: "BAN_CURRENT_USER",
  stateKey: "forms",
  stateSubKey: "banUserForm",
  fields: ["profileId", "reasonId", "comment"]
};

export const SubscribeForm = {
  code: "SUBSCRIBE",
  stateKey: "forms",
  stateSubKey: "subscribeForm",
  fields: ["email"]
};

export const JoinChallengePasswordForm = {
  code: "JOIN_CHALLENGE_PASSWORD",
  stateKey: "forms",
  stateSubKey: "joinChallengePasswordForm",
  fields: ["password", "joinChallengePasswordformStatus"]
};

export const FinishGameChallengeForm = {
  code: "FINISH_GAME_CHALLENGE",
  stateKey: "forms",
  stateSubKey: "finishGameChallengeForm",
  fields: ["profileId", "startGameTime", "score"]
};
export const CreateUsernameForm = {
  code: "CREATE_USERNAME",
  stateKey: "forms",
  stateSubKey: "createUsernameForm",
  fields: ["username"]
};
export const UsernameAvailableForm = {
  code: "USERNAME_EXISTS",
  stateKey: "forms",
  stateSubKey: "usernameAvailableForm",
  fields: ["username"]
};
export const CreateAvatarForm = {
  code: "CREATE_AVATAR",
  stateKey: "forms",
  stateSubKey: "createAvatarForm",
  fields: ["avatar"]
};
export const EditAvatarForm = {
  code: "EDIT_AVATAR",
  stateKey: "forms",
  stateSubKey: "editAvatarForm",
  fields: ["avatar"]
};
export const CreateTutorialForm = {
  code: "CREATE_TUTORIAL",
  stateKey: "forms",
  stateSubKey: "createTutorialForm",
  fields: ["tutorial"]
};
export const ForgotPasswordForm = {
  code: "FORGOT_PASSWORD",
  stateKey: "forms",
  stateSubKey: "forgotPasswordForm",
  fields: ["email", "resendEmail"]
};
export const ResetPasswordForm = {
  code: "RESET_PASSWORD",
  stateKey: "forms",
  stateSubKey: "resetPasswordForm",
  fields: ["newPassword", "renewPassword"]
};
export const ChangePasswordForm = {
  code: "CHANGE_PASSWORD",
  stateKey: "forms",
  stateSubKey: "changePasswordForm",
  fields: ["currentPassword", "newPassword", "renewPassword"]
};
export const QuestionForm = {
  code: "QUESTION",
  stateKey: "forms",
  stateSubKey: "questionForm",
  fields: ["playerName", "email", "question"]
};
export const ReportPlayerForm = {
  code: "REPORT_PLAYER",
  stateKey: "forms",
  stateSubKey: "reportPlayerForm",
  fields: ["reporterId", "reporteeId", "reason", "otherReason", "tournamentUrl"]
};
export const ProfileEditForm = {
  code: "PROFILE_EDIT",
  stateKey: "forms",
  stateSubKey: "profileEditForm",
  fields: [
    "firstName",
    "lastName",
    "username",
    "mobile",
    "instagram",
    "facebook",
    "twitter"
  ]
};
export const GetProfileDataForm = {
  code: "GET_PROFILE_DATA",
  stateKey: "forms",
  stateSubKey: "getProfileDataForm",
  fields: ["data"]
};
export const JoinRafflesForm = {
  code: "JOIN_RAFFLES",
  stateKey: "forms",
  stateSubKey: "joinRafflesForm",
  fields: ["raffleId", "userId"]
};

export const FollowForm = {
  code: "FOLLOW",
  stateKey: "forms",
  stateSubKey: "followForm",
  fields: ["followerId", "followeeId"]
};

export const UnfollowForm = {
  code: "UNFOLLOW",
  stateKey: "forms",
  stateSubKey: "unfollowForm",
  fields: ["followerId", "followeeId", "avatarUrl", "userName"]
};
export const FollowBrandForm = {
  code: "FOLLOW_BRAND",
  stateKey: "forms",
  stateSubKey: "followBrandForm",
  fields: ["followeeId"]
};

export const UnfollowBrandForm = {
  code: "UNFOLLOW_BRAND",
  stateKey: "forms",
  stateSubKey: "unfollowBrandForm",
  fields: ["followeeId", "avatarUrl", "userName"]
};

export const AddAsFriendForm = {
  code: "ADD_AS_FRIEND",
  stateKey: "forms",
  stateSubKey: "addAsFriendForm",
  fields: ["profileFirstId", "profileSecondId"]
};
export const VerifyPrizeForm = {
  code: "CHECK_PRIZE",
  stateKey: "forms",
  stateSubKey: "verifyPrizeForm",
  fields: ["notificationId", "challengeId", "verifyResponse"]
};
export const GetLastPrizeForm = {
  code: "GET_LAST_PRIZE",
  stateKey: "forms",
  stateSubKey: "getLastPrizeForm",
  fields: ["profile_id"]
};

export const GetClaimPrizeForm = {
  code: "GET_CLAIM_PRIZE",
  stateKey: "forms",
  stateSubKey: "getClaimPrizeForm",
  fields: [
    "firstName",
    "sirName",
    "contactNumber",
    "contractProvider",
    "email",
    "residence",
    "bankName",
    "branchCode",
    "accHolderName",
    "accNumber",
    "accType",
    "challengeId"
  ]
};

export const NewsDetailsForm = {
  code: "NEWS_DETAILS_FORM",
  stateKey: "forms",
  stateSubKey: "newsDetailsForm",
  fields: ["newsId"]
};
export const FriendDeclineForm = {
  code: "FRIEND_DECLINE",
  stateKey: "forms",
  stateSubKey: "friendDeclineForm",
  fields: ["requestorId", "requesteeId"]
};

export const FriendAcceptForm = {
  code: "FRIEND_ACCEPT",
  stateKey: "forms",
  stateSubKey: "friendAcceptForm",
  fields: ["requestorId", "requesteeId"]
};

export const UnFriendForm = {
  code: "UN_FRIEND",
  stateKey: "forms",
  stateSubKey: "unFriendForm",
  fields: ["profileId", "profileIdToUnfriend"]
};

export const PublishRaffleForm = {
  code: "PUBLISH_RAFFLE",
  stateKey: "forms",
  stateSubKey: "publishRaffleForm",
  fields: [
    //ajax params
    "brandId",
    "timeOfStart",
    "entryFee",
    "itemDescription",
    "itemUrl",
    "durationId",
    "points",
    //extra
    "HH",
    "MM"
  ]
};

export const ContactForm = {
  code: "CONTACT_US",
  stateKey: "forms",
  stateSubKey: "contactForm",
  fields: ["name", "email", "message"]
};

export const SubscribeNowForm = {
  code: "SUBSCRIBE_NOW",
  stateKey: "forms",
  stateSubKey: "subscribenowform",
  fields: ["mondiaCustomerId", "mondiaMobileOperatorId", "email", "affiliateId", "productId"]
};

export const NGLoginForm = {
  code: "NG_LOGIN",
  stateKey: "forms",
  stateSubKey: "ngLoginForm",
  fields: ["phoneNumber", "mondiaMobileOperatorId", "affiliateId", "productId"]
};

export const NGGetDOIUrlForm = {
  code: "NG_GET_DOI_URL_FORM",
  stateKey: "forms",
  stateSubKey: "ngGetDOIUrlForm",
  fields: ["msisdn", "mondiaMobileOperatorId", "redirectUrl"]
};

export const NGAutoLoginForm = {
  code: "NG_AUTO_LOGIN",
  stateKey: "forms",
  stateSubKey: "ngAutoLoginForm",
  fields: ["msisdn"]
};

export const SubscribeWrapmeForm = {
  code: "SUBSCRIBE_WRAPME",
  stateKey: "forms",
  stateSubKey: "subscribewrapmeform",
  fields: ["mondiaCustomerId", "mondiaMobileOperatorId", "email", "token", "phonenumber"]
};

export const BillingSubscriptionForm = {
  code: "BILLING_SUBSCRIPTION",
  stateKey: "forms",
  stateSubKey: "billingsubscriptionform",
  fields: ["mondiaCustomerId", "mondiaSubscriptionId", "otp"]
};

export const BillingRegistrationForm = {
  code: "BILLING_REGISTRATION",
  stateKey: "forms",
  stateSubKey: "billingregistrationform",
  fields: [
    "mondiaCustomerId",
    "subscriptionId",
    "password",
    "repassword",
    "phonenumber"
  ]
};

export const BillingLoginForm = {
  code: "BILLING_LOGIN",
  stateKey: "forms",
  stateSubKey: "billingloginform",
  fields: ["mondiaCountryId", "phonenumber", "password"]
};

export const BillingLoginViaUUIDForm = {
  code: "BILLING_LOGIN_VIA_UUID",
  stateKey: "forms",
  stateSubKey: "billingloginviaussdform",
  fields: ["uuid"]
};

export const BillingInfoForm = {
  code: "BILLING_INFO",
  stateKey: "forms",
  stateSubKey: "billinginfoform",
  fields: ["phonenumber", "mondiaCountryId", "mondiaMobileOperatorId", "affiliateId", "authToken", "productId"]
};

export const NGBillingInfoForm = {
  code: "BILLING_INFO",
  stateKey: "forms",
  stateSubKey: "billinginfoform",
  fields: ["phonenumber", "mondiaCountryId", "mondiaMobileOperatorId", "affiliateId", "productId"]
};

export const BillingForgotPasswordForm = {
  code: "BILLING_FORGOT_PASSWORD",
  stateKey: "forms",
  stateSubKey: "billingforgotpasswordform",
  fields: ["phonenumber", "otptoken", "mondiaCountryId"]
};

export const BillingResetPasswordForm = {
  code: "BILLING_RESET_PASSWORD",
  stateKey: "forms",
  stateSubKey: "billingresetpasswordform",
  fields: ["otp", "password", "repassword"]
};

export const UnsubscribeForm = {
  code: "UNSUBSCRIBE_FORM",
  stateKey: "forms",
  stateSubKey: "unsubscribeform",
  fields: ["phonenumber"]
};

export const BillingHoldForm = {
  code: "BILLINGHOLD_FORM",
  stateKey: "forms",
  stateSubKey: "billingholdform",
  fields: ["onhold", "subscriptionId", "status"]
};

export const BillingNGHoldForm = {
  code: "BILLING_NG_HOLD_FORM",
  stateKey: "forms",
  stateSubKey: "billingNGHoldform",
  fields: ["onhold", "subscriptionId", "status"]
};

export const MTNXBillingHoldForm = {
  code: "MTNXBILLINGHOLD_FORM",
  stateKey: "forms",
  stateSubKey: "mtnxBillingHoldForm",
  fields: ["onhold", "subscriptionId", "phoneNumber", "status"]
};
