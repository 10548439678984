import React, { Component } from "react";

class CreateTutorialCardComponent extends Component {
  
  render() {
    const { figcaption, figImg, figdescription, onNext } = this.props;

    return (
      <div className="card-popup ">
        <div className="row">
          <div className="col-sm-12 text-center px-5">
            <h1 className="">{figcaption}</h1>
            
          </div>
        </div>
        <div className="row gutter-space onboarding-responsive">
          <div className="col text-center">
            <img src={figImg} className={"onboarding-image "+(this.props.keyIndex ? "":"profile-pic-200")} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center figure-description px-5">
            <h5>{figdescription}</h5>
          </div>
        </div>
      </div>
    );
  }
}
export default CreateTutorialCardComponent;
