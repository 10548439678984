import React, {Component} from "react";
import {connect} from "react-redux";
import {apiError} from "../../../actions/modals";
import ModalERROR from "../ModalERROR";
import {withTranslation} from "react-i18next";


class ApiErrorModal extends Component {
    constructor(props) {
        super(props);
        // this.showApiErrorModal = this.showApiErrorModal.bind(this);
        // this.hideApiErrorModal = this.hideApiErrorModal.bind(this);
    }

    // showApiErrorModal() {
    //     this.props.apiErrorModalOpen();
    // }

    // hideApiErrorModal() {
    //     this.props.apiErrorModalClose();
    // }

    render() {
        const {t} = this.props;
        return (
            <>
             <ModalERROR
              handleHide={() => {
                  this.props.apiErrorModalClose();
              }}
              isOpen={this.props.isApiErrorModalOpen}
              redirectTo={null}
             >
              <div>Sorry, we are currently upgrading this server, please try again in 15 minutes.</div>
          </ModalERROR>
            </>
        );
    }
}

const mapDispatchToProps = {
    apiErrorModalOpen: apiError.open,
    apiErrorModalClose: apiError.close,
};

const mapStateToProps = state => {
    return {
        isApiErrorModalOpen: state.modals.isApiErrorModalOpen
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ApiErrorModal));
