/* eslint eqeqeq: 0 */
// comment to force commit 
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from './store';
import App from './App';
import SocketHoc from './hoc/SocketHoc';
import './styles/master.scss';
import { axios } from './utils/axios';
import { logoutUserIfNeeded } from './actions/user';
import { apiError } from './actions/modals';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router';
import socket from './socket';
import { messageHistoryRequest } from './actions/messagehistory';
import { emitInitSocket } from './actions/socket';
import { notificationsRequest } from './actions/notification';

const { store, persistor } = createStore();
axios.defaults.validateStatus = (status) => {
	if (status >= 400) {
		if (
			localStorage.getItem('timestamp') !== '' ||
			localStorage.getItem('timestamp') !== null
		) {
			var timeStamp = localStorage.getItem('timestamp');
			var secondsPast = (new Date().getTime() - timeStamp) / 1000;
			if (secondsPast > 10) {
				localStorage.setItem('timestamp', new Date().getTime());
			}
		} else {
			localStorage.setItem('timestamp', new Date().getTime());
		}
	}
	return true;
};
axios.interceptors.response.use((response) => {
	if (
		response.data &&
		response.data.error &&
		response.data.error.code &&
		(response.data.error.code === 'A002' ||
			response.data.error.code === 'A001' ||
			response.data.error.code === 'JWT1' ||
			response.data.error.code === 'JWT2' ||
			response.data.error.code === 'JWT3' ||
			response.data.error.code === 'JWT5')
		// && axios.defaults.headers.common["Authorization"]
	) {
		store.dispatch(logoutUserIfNeeded());
	}
	return response;
});

socket.on('auth complete', () => {
	store.dispatch(messageHistoryRequest());
	store.dispatch(notificationsRequest());
});

socket.io.on('reconnect', () => {
	// it takes up to 10 seconds for the server side services to spool up
	console.log('reconnected');
	setTimeout(() => store.dispatch(emitInitSocket()), 10000);
});

socket.io.on('reconnect_attempt', (attemptNumber) => {
	console.log('Reconnection attemp:', attemptNumber);
});

const MyRedirect = withRouter((props) => {
	return <Redirect {...props} to={props.to + props.location.search} />;
});
const AppWithSocket = SocketHoc(App);
render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router>
						<Switch>
							<Route path='/:locale' component={AppWithSocket} />
							<MyRedirect to='/en' />
						</Switch>
					</Router>
					{/* <AppWithSocket /> */}
				</PersistGate>
			</Provider>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
