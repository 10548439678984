export const ERROR_DISPLAY_SCOPE = {
  login: "login",
  register: "register",
  subscribe: "subscribe",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
  createUserName: "createUserName",
  createAvatar: "createAvatar",
  joinChallenge: "joinChallenge",
  challengePassword: "challengePassword"
};

export const SUCCESS_DISPLAY_SCOPE = {
  login: "login",
  register: "register",
  forgotPassword: "forgotPassword",
  resetPassword: "resetPassword",
  subscribe: "subscribe",
  createUserName: "createUserName",
  createAvatar: "createAvatar",
  joinChallenge: "joinChallenge",
  challengePassword: "challengePassword"
};

export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 20;

export const MIN_USERNAME_LENGTH = 4;
export const MAX_USERNAME_LENGTH = 15;

export const MIN_NAME_LENGTH = 1;
export const MAX_NAME_LENGTH = 15;

export const MIN_NUMBER_NAME_LENGTH = 1;
export const MAX_NUMBER_NAME_LENGTH = 20;
