import React, { Component } from "react";
import { connect } from "react-redux";
import { endGameModal } from "../../../actions/modals";
import "./EndGameModal.scss";
import Modal from "../Modal";
import goldTokenImage from "../../../assets/images/gold-tokens-new.png";
import highScoreImage from "../../../assets/images/high-score.png";
import { ordinalSuffix, findWithAttr, isEmpty } from "../../../utils/misc";
import { toggleGameFullScreen } from "../../../actions/toggles";
import platformParams from "../../../utils/platformParams";

class EndGameModal extends Component {
  constructor(props) {
    super(props);
    this.hideEndGameModal = this.hideEndGameModal.bind(this);
    this.state = {
      isLandScape: false
    };
  }

  hideEndGameModal() {
    this.props.endGameModalClose();
  }

  render() {
    if (isEmpty(this.props.challengeDetails.currentUserLeaderBoard)) return null;
  
    const userIndex = findWithAttr(
      this.props.challengeDetails.currentUserLeaderBoard,
      "profileId",
      this.props.userData.id
    );
    
    let placed = "0";
    if (userIndex >= 0) {
      placed = userIndex + 1;
    }

    let highScore = 0;

    if (!isEmpty(this.props.ChallengesLeaderboardsViewAll)) {
      highScore = this.props.ChallengesLeaderboardsViewAll[0]
        .points;
    }

    return (
      <>
        <Modal
          handleHide={this.hideEndGameModal}
          isOpen={this.props.isEndGameModalOpen}
          disableOuterClick={true}
          modalClass="modal score-card"
        >
          <div className="card-popup ">
            <div className="row">
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-sm-10 text-center mt-3 gift-wrapper">
                    <h6 className="bold mb-5">You Scored</h6>
                    <div className="gift-img">
                      <img src={platformParams['trophy3x']} alt="" />
                    </div>
                    <h1 className="display-3 my-4 bold text-danger">
                      {this.props.finishGameChallengeForm.fields.score.value > 0 ? this.props.finishGameChallengeForm.fields.score.value : 0}
                    </h1>
                    <div className="row">
                      <div className="col gold-token  br-1">
                        <img src={goldTokenImage} alt="" className="mb-2" />

                        {/* Custom tournament hide rank - commented out */}
                        {/* {this.props.challengeDetails.id == 1341 ? (
                          <h4 className="bold mb-1">??</h4>
                        ) : (
                          <h4
                            className="bold mb-1"
                            dangerouslySetInnerHTML={{
                              __html: ordinalSuffix(
                                this.props.challengeDetails.currentUserLeaderBoard
                                  ? this.props.challengeDetails
                                      .currentUserLeaderBoard.rank
                                  : "0",
                                true
                              )
                            }}
                          />
                        )} */}

                        <h4
                          className="bold mb-1"
                          dangerouslySetInnerHTML={{
                            __html: ordinalSuffix(
                              this.props.challengeDetails.currentUserLeaderBoard
                                ? this.props.challengeDetails
                                    .currentUserLeaderBoard.rank
                                : "0",
                              true
                            )
                          }}
                        />

                        <p className="fs-12 earned-token mb-1 semi-bold">
                          Placed
                        </p>
                      </div>
                      <div className="col gold-token ">
                        <img src={highScoreImage} alt="" className="mb-2" />

                        {/* Custom tournament hide high score - commented out */}
                        {/* {this.props.challengeDetails.id == 1341 ? (
                          <h4 className="bold mb-1">??</h4> 
                        ) : (
                          <h4 className="bold mb-1">{highScore}</h4> 
                        )} */}

                        <h4 className="bold mb-1">{highScore}</h4>
                        <p className="fs-12 earned-token mb-1 semi-bold">
                          High Score
                        </p>
                      </div>
                    </div>
                    <span
                      className={"a btn btn-" + platformParams['baseButtonClassSufix'] + " btn-block light mt-5 text-white"}
                      onClick={e => {
                        e.preventDefault();
                        this.props.gameFullScreenReset();
                        this.props.gameFullScreenToggle();
                        this.props.endGameModalClose();
                      }}
                    >
                      Play Again
                    </span>

                    <span
                      className="a btn btn-link my-4 text-dark bold cancel-btn"
                      onClick={e => {
                        e.preventDefault();
                        this.props.endGameModalClose();
                      }}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  endGameModalClose: endGameModal.close,
  gameFullScreenReset: toggleGameFullScreen.reset,
  gameFullScreenToggle: toggleGameFullScreen.toggle
};

const mapStateToProps = state => {
  return {
    isEndGameModalOpen: state.modals.isEndGameModalOpen,
    finishGameChallengeForm: state.forms.finishGameChallengeForm,
    challengeDetails: state.challenges.challengeDetails.item || null,
    ChallengesLeaderboardsViewAll: state.challenges.ChallengesLeaderboardsViewAll.items || null,
    userData: state.user.item
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndGameModal);
