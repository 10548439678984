export const Login = {
  code: "LOGIN",
  stateKey: "modals",
  stateSubKey: "isLoginOpen"
};
export const Register = {
  code: "REGISTER",
  stateKey: "modals",
  stateSubKey: "isRegisterOpen"
};
export const ForgotPassword = {
  code: "FORGOT_PASSWORD",
  stateKey: "modals",
  stateSubKey: "isForgotPasswordOpen"
};
export const ResetPassword = {
  code: "RESET_PASSWORD",
  stateKey: "modals",
  stateSubKey: "isResetPasswordOpen"
};
export const ChangePassword = {
  code: "CHANGE_PASSWORD",
  stateKey: "modals",
  stateSubKey: "isChangePasswordOpen"
};
// export const CreateNameAndAvatar = {
//   code: "CREATE_NAME_AND_AVATAR",
//   stateKey: "modals",
//   stateSubKey: "isCreateNameAndAvatarOpen"
// };
export const CreateName = {
  code: "CREATE_NAME",
  stateKey: "modals",
  stateSubKey: "isCreateNameOpen"
};
export const CreateAvatar = {
  code: "CREATE_AVATAR",
  stateKey: "modals",
  stateSubKey: "isCreateAvatarOpen"
};
export const CreateTutorial = {
  code: "CREATE_TUTORIAL",
  stateKey: "modals",
  stateSubKey: "isCreateTutorialOpen"
};

export const JoinChallengePassword = {
  code: "JOIN_CHALLENGE_PASSWORD",
  stateKey: "modals",
  stateSubKey: "isJoinChallengePasswordOpen"
};

export const QuickChallengeJoin = {
  code: "QUICK_CHALLENGE_JOIN",
  stateKey: "modals",
  stateSubKey: "isQuickChallengeJoinOpen"
};

export const EndGameModal = {
  code: "END_GAME_MODAL",
  stateKey: "modals",
  stateSubKey: "isEndGameModalOpen"
};

export const EndGameAnimateModal = {
  code: "END_GAME_ANIMATE_MODAL",
  stateKey: "modals",
  stateSubKey: "isEndGameAnimateModalOpen"
};

export const ReportPlayer = {
  code: "REPORT_PLAYER",
  stateKey: "modals",
  stateSubKey: "isReportPlayerOpen"
};

export const Unfollow = {
  code: "UNFOLLOW",
  stateKey: "modals",
  stateSubKey: "isUnfollowOpen"
};
export const UnfollowBrand = {
  code: "UNFOLLOW_BRAND",
  stateKey: "modals",
  stateSubKey: "isUnfollowBrandOpen"
};
export const VerifyPrize = {
  code: "VERIFY_PRIZE",
  stateKey: "modals",
  stateSubKey: "isVerifyPrizeOpen"
};
export const ActivateUserModal = {
  code: "ACTIVATE_USER",
  stateKey: "modals",
  stateSubKey: "isActiveUserOpen"
};
export const VasError = {
  code: "VAS_ERROR",
  stateKey: "modals",
  stateSubKey: "isVasErrorModalOpen"
};
export const Unsubscribe = {
  code: "UNSUBSCRIBE",
  stateKey: "modals",
  stateSubKey: "isUnsubscribeOpen"
};
export const UnsubscribeSuccess = {
  code: "UNSUBSCRIBE_SUCCESS",
  stateKey: "modals",
  stateSubKey: "isUnsubscribeSuccessOpen"
};
export const SubscribeFailed = {
  code: "SUBSCRIBE_FAILED",
  stateKey: "modals",
  stateSubKey: "isSubscribeFailedOpen"
};

export const MaintenanceModeModal = {
  code: "MAINTENANCE_MODE_ENABLED",
  stateKey: "modals",
  stateSubKey: "isMaintenanceModeModalOpen"
};
export const MultipleTabEnforcedModal = {
  code: "MULTIPLE_TAB_ENFORCED",
  stateKey: "modals",
  stateSubKey: "isMultipleTabsEnforcedModalOpen"
};
export const ApiErrorModal = {
  code: "API_ERROR",
  stateKey: "modals",
  stateSubKey: "isApiErrorModalOpen"
};
