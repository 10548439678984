import { combineReducers } from "redux";
import raffles from "./raffles";
import user from "./user";
import login from "./login";
import sidebars from "./sidebars";
import modals from "./modals";
import forms from "./forms";
import tutorials from "./tutorials";
import redirect from "./redirect";
import alerts from "./alerts";
import challenges from "./challenges";
import profiles from "./profiles";
import brands from "./brands";
import news from "./news";
import categories from "./categories";
import toggles from "./toggles";
import loaders from "./loaders";
import toastr from "./toastr";
import friends from "./friends";
import socketusers from "./socketusers";
import notifications from "./notifications";
import { LOGOUT_SUCCESS } from "../types/login";
import messagehistory from "./messagehistory";
import chathistory from "./chathistory";
import vas from "./vas";
import vas_blacklist from "./vas_blacklist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const userConfig = {
  key: 'user',
  storage,
  blacklist: ['uuidLoginError']
}

const appReducer = combineReducers({
  raffles,
  user: persistReducer(userConfig, user),
  login,
  notifications,
  sidebars,
  modals,
  forms,
  redirect,
  alerts,
  challenges,
  brands,
  news,
  profiles,
  categories,
  toggles,
  loaders,
  toastr,
  tutorials,
  friends,
  socketusers,
  messagehistory,
  chathistory,
  vas,
  vas_blacklist,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
