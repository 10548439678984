import { LOGIN_USER_DETAIL_UPDATE, LOGOUT_USER_DETAIL, UUID_LOGIN_FAILURE } from "../types/user";

const initialState = {
  item: {},
  uuidLoginError: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_DETAIL_UPDATE:
      return {
        ...state,
        item: { ...state.item, ...action.userdata.data },
        uuidLoginError: false,
      };
    case LOGOUT_USER_DETAIL:
      return {
        ...initialState,
        uuidLoginError: false,
      };
    case UUID_LOGIN_FAILURE:
      return {
        ...state,
        uuidLoginError: true,
      }
    default:
      return state;
  }
};
export default user;
