import React, { Component } from "react";
import "./MessageList.scss";
// import ProfileImage from "../../../../assets/images/avatar-demo.png";
class MessageListItem extends Component {
  // addDefaultSrc(ev) {
  //   ev.target.src = ProfileImage;
  // }
  render() {
    const {allOnlineUserList} = this.props;
    const profileDetail = {
      id:this.props.userId,
      userName:this.props.userName,
      profileUrl:this.props.profileUrl,
      isRead: this.props.isRead,
    }

    // Replace invalid profile avatar with correct format
    let tempImgUrl = this.props.profileUrl;
    let formattedImgUrl = '';
    if (((tempImgUrl.match(/http/g) || []).length) == 2) {
      formattedImgUrl = tempImgUrl.substring(tempImgUrl.lastIndexOf("http"));
    } else if (((tempImgUrl.match(/\/images/g) || []).length) == 0) {
      formattedImgUrl = tempImgUrl.replace("images/", "/images/");
    } else {
      formattedImgUrl = this.props.profileUrl;
    }
    
    return (
      <>
        <div className={`a ${this.props.isRead ? "read-message" : "unread-message"}`} 
         onClick={e => {
          this.props.showChatListSidebar(profileDetail);
        }}>
        <div className="row no-gutters d-flex justify-content-between align-items-center message-list bb-1">
          <div className="media col">
          
            {this.props.profileUrl ? (
            <img src={formattedImgUrl} alt="profile" className="profile-pic-48" onError={(e)=>(e.target.src="https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png")} />
          ) : (
            <img src={"https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png"}
               alt="profile"
               className="profile-pic-48" 
               onError={(e)=>(e.target.src="https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png")} />
          )}
           {allOnlineUserList.includes(this.props.userId)?(<figure><figcaption className="online-48"></figcaption></figure>):null}
            <div className="media-body ml-3">
              <h6 className={this.props.isRead ? "bold" : "bold"}>{this.props.userName}</h6>
              <p className="mb-0 text-truncate mw-150">
              {this.props.message}
              </p>
              <p className="mb-0 fs-12 time-indication">{this.props.time}</p>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default MessageListItem;
