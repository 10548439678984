import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  leftSidebar,
  profileSidebar,
  messageListSidebar,
  notificationListSidebar
} from "../../../../actions/sidebars";
import { openNotificationSidebar } from "../../../../actions/notification";

import {
  login,
  register,
  createAvatar,
  createTutorial,
  modalCloseAll
} from "../../../../actions/modals";
import { toggleGameFullScreen } from "../../../../actions/toggles";
import { endGameModal } from "../../../../actions/modals";
import profilePic48 from "../../../../assets/images/avatar-demo.png";
import MenuIcon from "../../../../assets/images/svg/nav-menu.svg";
import LeftArrowIcon from "../../../../assets/images/svg/nav-arrow-right.svg";
import RightSidebarProfile from "../../sidebars/RightSidebar/RightSidebarProfile";
import RightSidebarEditProfile from "../../sidebars/RightSidebar/RightSidebarEditProfile";
import FriendList from "../../sidebars/RightSidebar/FriendList";
import MessageList from "../../sidebars/RightSidebar/MessageList";
import NotificationList from "../../sidebars/RightSidebar/NotificationList";
import ChatList from "../../sidebars/RightSidebar/ChatList";
import UserList from "../../sidebars/RightSidebar/UserList";
import { logoutUserIfNeeded } from "../../../../actions/user";
import { CreateName } from "../../../../redux-config/modalActionMap";
// import { CreateNameAndAvatar } from "../../../../redux-config/modalActionMap";
import LeftSidebarWithLogin from "../../sidebars/LeftSidebar/LeftSidebarWithLogin";
import closePopupImg from "../../../../assets/images/svg/nav-close.svg";
import { SHOWN_PROFILES } from "../../../../types/tutorials";
import { createTutorialForm } from "../../../../actions/forms";
import { languagesList } from "../../../../actions/profiles";
import Select2ComponentId from "../../../../components/Select2/Select2ComponentId";
import i18n from "../../../../i18n";
import { isNgCountry, isEnabledLanguageChange } from "../../../../utils/misc";
import platformParams from "../../../../utils/platformParams";
import mPlus from "../../../../assets/images/svg/M_Plus_icon.svg"

const RedirectLang = props => {
  const lang = i18n.language;
  const { location } = props;
  const selectLanguageOptions = [
    { id: 1, value: "en" },
    { id: 2, value: "fr" }
  ];
  let pathNames = location.pathname;
  const changeLanguageI18 = lng => {
    if (pathNames.search("/" + lang + "/") > -1) {
      pathNames = pathNames.replace("/" + lang + "/", "/" + lng + "/");
    } else {
      if (!(pathNames.search("/free") > -1)) {
        pathNames = pathNames.replace("/" + lang, "/" + lng);
      }
    }
    props.history.push(pathNames);
  };
  return (
    <>
      <div className="a nav-link" style={{ width: "125px" }}>
        <Select2ComponentId
          selectOptions={selectLanguageOptions}
          onChange={event => {
            changeLanguageI18(event.text);
            localStorage.setItem("language", event.id);
          }}
          selectedValue={lang.toUpperCase()}
          labelText="lang"
        />
      </div>
    </>
  );
};
class TopNavBarWithLogin extends Component {
  constructor(props) {
    super(props);
    this.showLeftSidebar = this.showLeftSidebar.bind(this);
    this.hideLeftSidebar = this.hideLeftSidebar.bind(this);
    this.shouldShowCloseBtn = this.shouldShowCloseBtn.bind(this);
    this.gameScreenClosePopup = this.gameScreenClosePopup.bind(this);
    this.addDefaultSrc = this.addDefaultSrc.bind(this);
  }
  showLeftSidebar() {
    this.props.leftSidebarOpen();
  }
  hideLeftSidebar() {
    this.props.leftSidebarClose();
  }

  shouldShowCloseBtn() {
    return (
      this.props.isAnyModalOpen &&
      !this.props.isCreateNameOpen &&
      !this.props.isCreateAvatarOpen
      // this.props.isAnyModalOpen &&
      // !this.props.isCreateNameAndAvatarOpen
    );
  }

  gameScreenClosePopup() {
    if (this.props.isEndGameModalOpen) {
      // this.props.gameFullScreenToggle()
      this.props.endGameModalClose();
    } else {
      this.props.gameFullScreenToggle();
    }
  }
  addDefaultSrc(ev) {
    ev.target.src = "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png";
  }
  render() {
    const totalNoficationUnread =
      this.props.notifications.unread + this.props.notifications.unreadFriends;
    let envClass = platformParams['className'];
    const { t, currentLang } = this.props;
    const lang = i18n.language;
    return (
      <>
        <header className={this.props.isGameFullScreen ? " d-none " : " "}>
          <nav
            className={
              "navbar navbar-expand-lg navbar-light fixed-top nav-main bg-miggsterBGDarkGrey" +
              (this.props.isAnyModalOpen ? " isAnyModalOpen" : "")
            }
            id="link"
          >
            <div className="container">
              <span className="d-lg-none">
                <span
                  className={
                    "icon-menu d-lg-none left-sidebar-collapse" +
                    (this.props.isGameFullScreen ||
                    this.props.isCreateNameOpen ||
                    // this.props.isCreateNameAndAvatarOpen ||
                    this.props.isCreateAvatarOpen
                      ? " d-none"
                      : " d-block")
                  }
                  onClick={this.showLeftSidebar}
                >
                  <img src={MenuIcon} alt="" />
                </span>

                <span
                  className={
                    this.props.isGameFullScreen
                      ? "d-block d-lg-none left-arrow-icon"
                      : "d-none"
                  }
                  onClick={this.gameScreenClosePopup}
                >
                  <img src={LeftArrowIcon} alt="" />
                </span>
              </span>

              <Link to={"/" + lang} className="navbar-brand d-flex">
                <img
                  src={platformParams['headerLogoSvg']}
                  alt=""
                  className="mainLogo"
                />
              </Link>
              <span>
                <span
                  className={
                    this.shouldShowCloseBtn() ? "d-block d-lg-none" : "d-none"
                  }
                  onClick={() => {
                    this.props.modalCloseAll();
                    if (
                      this.props.isCreateTutorialOpen &&
                      !this.props.createTutorialForm.isSubmitting
                    ) {
                      this.props.createTutorialFormSubmit({
                        shownTutorial: SHOWN_PROFILES
                      });
                    }
                  }}
                >
                  <img src={closePopupImg} alt="" />
                </span>
                <span
                  className={
                    "a avatar right-sidebar-collapse" +
                    (this.shouldShowCloseBtn() ||
                    this.props.isCreateNameOpen ||
                    // this.props.isCreateNameAndAvatarOpen ||
                    this.props.isCreateAvatarOpen
                      ? " d-none"
                      : " d-block d-lg-none")
                  }
                  onClick={() => {
                    this.props.profileSidebarOpen();
                  }}
                >
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                      <a href="https://plus.miggster.com" target="_blank" rel="noopener noreferrer">
                        <img 
                          alt="link to plus.miggster.com" 
                          src={mPlus} 
                          className="profile-icon profile-pic-48"
                          style={{}}
                        />
                      </a>
                    <figure>
                      <img
                        src={this.props.avtarUrl}
                        className="profile-pic-48"
                        alt=""
                        onError={this.addDefaultSrc}
                      />
                      <figcaption className="online-48" />
                    </figure>
                  </div>
                </span>
              </span>
              <ul className="navbar-nav d-none d-lg-flex align-items-center">
                <li className={this.props.match.path === "/" + lang + "/dashboard" ? "nav-item active logged-in" : "nav-item"}>
                  <Link
                    to={"/" + lang + "/dashboard"}
                    className={
                      "nav-link " +
                      (this.props.match.path === "/dashboard"
                        ? " active"
                        : "") +
                      envClass
                    }
                  >
                    {t("navDashboard")}
                  </Link>
                </li>
                <li className={this.props.match.path === "/" + lang + "/my-tournaments" ? "nav-item active logged-in" : "nav-item"}>
                  <Link
                    to={"/" + lang + "/my-tournaments"}
                    className={
                      "nav-link " +
                      (this.props.match.path === "/my-tournaments"
                        ? " active"
                        : "") +
                      envClass
                    }
                  >
                    {t("myTournament")}
                  </Link>
                </li>
                { 
                  !isNgCountry() ? 
                  <li className={this.props.match.path === "/" + lang + "/giveaways" ? "nav-item active logged-in" : "nav-item"}>
                      <Link
                        to={"/" + lang + "/giveaways"}
                        className={
                          "nav-link " +
                          (this.props.match.path === "/giveaways" ? " active" : "") +
                          envClass
                        }
                      >
                        {t("giveaways")}
                      </Link>
                    </li>
                  : <></>
                }
                {/* <li className={this.props.match.path === "/" + lang + "/about-us" ? "nav-item active logged-in" : "nav-item"}>
                  <Link
                    to={"/" + lang + "/about-us"}
                    className={
                      "nav-link " +
                      (this.props.match.path === currentLang + "/about-us"
                        ? " active"
                        : "") +
                      envClass
                    }
                  >
                    {t("navAboutUs")}
                  </Link>
                </li> */}
                <li className={this.props.match.path === "/" + lang + "/freetoplay" ? "nav-item active logged-in" : "nav-item"}>
                  <Link
                    to={"/" + lang + "/freetoplay"}
                    className={
                      "nav-link " +
                      (this.props.match.path === currentLang + "/freetoplay"
                        ? " active"
                        : "") +
                      envClass
                    }
                  >
                    {t("navGames")}
                  </Link>
                </li>
                <li className={this.props.match.path === "/" + lang + "/news" ? "nav-item active logged-in" : "nav-item"}>
                  <Link
                    to={"/" + lang + "/news"}
                    className={
                      "nav-link " +
                      (this.props.match.path === currentLang + "/news"
                        ? " active"
                        : "") +
                      envClass
                    }
                  >
                    {t("navNews")}
                  </Link>
                </li>
                { isEnabledLanguageChange() ? <RedirectLang {...this.props} /> : <></> }
                {/* <li className="nav-item">
                  <span
                  className="a nav-link"
                  onClick={() => {
                    this.props.logoutUserIfNeeded();
                  }}
                  >
                  LogOut
                  </span>
                </li> */}
                <li className="nav-item">
                  <span
                    className="a nav-link icon-link chat"
                    onClick={() => {
                      this.props.messageListSidebarOpen();
                    }}
                  >
                    <span className="icon-container"></span>
                    {this.props.messagehistory.unread > 0 ? (
                      <span
                        className={
                          "badge text-white message-count  bg-danger " +
                          ( "bg-" + platformParams['linkClassNoti'] )
                        }
                      >
                        {this.props.messagehistory.unread}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className="a nav-link icon-link notifications"
                    onClick={() => {
                      this.props.notificationListSidebarOpen();
                      this.props.openNotificationSidebar();
                    }}
                  >
                    <span className="icon-container"></span>
                    {totalNoficationUnread > 0 ? (
                      <span
                        className={
                          "badge text-white message-count bg-danger " +
                          ( "bg-" + platformParams['linkClassNoti'])
                        }
                      >
                        {totalNoficationUnread}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className="a nav-link right-sidebar-collapse"
                    onClick={() => {
                      this.props.profileSidebarOpen();
                    }}
                  >
                    {/* <figure>
                      <img
                        src={this.props.avtarUrl}
                        className="profile-pic-48"
                        alt=""
                        onError={this.addDefaultSrc}
                      />
                      <figcaption className="online-48" />
                    </figure> */}
                    <a href="https://plus.miggster.com" target="_blank" rel="noopener noreferrer">
                      <img 
                        alt="link to plus.miggster.com" 
                        src={mPlus} 
                        className="profile-icon profile-pic-48"
                        style={{marginRight: '20px'}}
                      />
                    </a>
                    <figure>
                      <img
                        src={this.props.avtarUrl}
                        className="profile-icon profile-pic-48"
                        alt=""
                        onError={this.addDefaultSrc}
                      />
                      <figcaption className="online-48" />
                    </figure>
                    
                  </span>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <LeftSidebarWithLogin />
        <RightSidebarProfile />
        <RightSidebarEditProfile />
        <FriendList />
        <MessageList />
        <ChatList />
        <UserList />
        <NotificationList />
      </>
    );
  }
}
const mapDispatchToProps = {
  leftSidebarOpen: leftSidebar.open,
  leftSidebarClose: leftSidebar.close,
  profileSidebarOpen: profileSidebar.open,
  messageListSidebarOpen: messageListSidebar.open,
  notificationListSidebarOpen: notificationListSidebar.open,
  loginOpen: login.open,
  loginHyveOpen: login.close,
  registerOpen: register.open,
  createNameOpen: CreateName.open,
  // createNameAndAvatarOpen: CreateNameAndAvatar.open,
  createAvtarOpen: createAvatar.open,
  createTutorialOpen: createTutorial.open,
  logoutUserIfNeeded,
  modalCloseAll: modalCloseAll,
  gameFullScreenToggle: toggleGameFullScreen.toggle,
  createTutorialFormSubmit: createTutorialForm.submit,
  openNotificationSidebar: openNotificationSidebar,
  endGameModalClose: endGameModal.close,
  fetchLanguagesIfNeeded: languagesList.fetchIfNeeded,
  invalidateLanguagesIfNeeded: languagesList.invalidate
};

const mapStateToProps = state => {
  return {
    avtarUrl: state.user.item.avatarUrl
      ? state.user.item.avatarUrl
      : "https://dj1sbys2prnc6.cloudfront.net/_general/default_profile.png",
    isProfileSidebarOpen: state.sidebars.isProfileSidebarOpen,
    isMessageListSidebarOpen: state.sidebars.isMessageListSidebarOpen,
    isLoggedIn: state.login.isLoggedIn,
    isAnyModalOpen: state.modals.isAnyModalOpen,
    isEndGameModalOpen: state.modals.isEndGameModalOpen,
    isGameFullScreen: state.toggles.gameFullScreen || false,
    isCreateNameOpen: state.modals.isCreateNameOpen,
    // isCreateNameAndAvatarOpen: state.modals.isCreateNameAndAvatarOpen,
    isCreateAvatarOpen: state.modals.isCreateAvatarOpen,
    isCreateTutorialOpen: state.modals.isCreateTutorialOpen,
    userId: state.user.item.id,
    createTutorialForm: state.forms.createTutorialForm,
    messagehistory: state.messagehistory,
    notifications: state.notifications,
    currentLang: state.login.currentLang,
    languageslist: state.profiles.languagesList.items
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(TopNavBarWithLogin))
);
