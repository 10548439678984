import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	messageListSidebar,
	chatListSidebar,
	userListSidebar,
} from '../../../../actions/sidebars';
import './MessageList.scss';
import navClose from '../../../../assets/images/svg/nav-close.svg';
import MessageListItem from './MessageListItem';
import { openChatHistory } from '../../../../actions/chathistory';
import { convertDateForMessage } from '../../../../utils/date';
import { withTranslation } from 'react-i18next';
import platformParams from '../../../../utils/platformParams';
class MessageList extends Component {
	constructor(props) {
		super(props);
		this.hideRightSidebar = this.hideRightSidebar.bind(this);
		this.showChatListSidebar = this.showChatListSidebar.bind(this);
		this.showUserListSidebar = this.showUserListSidebar.bind(this);
	}

	hideRightSidebar() {
		this.props.messageListSidebarClose();
	}

	showChatListSidebar(profileDetail) {
		this.props.messageListSidebarClose();
		this.props.chatListSidebarOpen();
		this.props.openChatHistory(profileDetail);
	}

	showUserListSidebar() {
		this.props.messageListSidebarClose();
		this.props.userListSidebarOpen();
	}

	getMessageList() {
		const { t } = this.props;
		if (this.props.messagehistory.length > 0) {
			const chatArray = this.props.messagehistory;

			return chatArray.map((chat, index) => {
				if (chat.messages.length > 0) {
					const createdAt = convertDateForMessage(chat.messages[0].createdAt);
					return (
						<MessageListItem
							key={index}
							userId={chat.userId}
							userName={chat.userName}
							profileUrl={chat.profileUrl}
							isRead={chat.isRead}
							message={chat.messages[0].message}
							time={createdAt}
							allOnlineUserList={this.props.allOnlineUserList}
							showChatListSidebar={this.showChatListSidebar}
						/>
					);
				}
			});
		} else {
			return <div className='text-center'>{t('noMessage')}</div>;
		}
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<nav
					className={
						'chat-list-slider' +
						(this.props.isMessageListSidebarOpen ? ' active' : '')
					}
				>
					<div className='fixed-sidebar-scroll-landscap chat-screen'>
						{/* <div className="dismiss">
              <span className="icon-close">
                <img src={navClose} alt="" onClick={this.hideRightSidebar} />
              </span>
            </div> */}
						<section className='d-flex flex-column '>
							<section className='row no-gutters'>
								<div className='col-sm-12 text-center'>
									<div className='sidebar-header'>
										<div className='d-flex  justify-content-between align-items-center message-header'>
											<h6 className='bold'>Message</h6>

											<div>
												<a
													href='javascript:void(0);'
													className={
														'fs-14 text-' +
														platformParams['baseButtonClassSufix'] +
														' align-items-center mr-4'
													}
													onClick={this.showUserListSidebar}
												>
													{t('newmessage')}
												</a>
												<span className='icon-close'>
													<img
														src={navClose}
														alt=''
														onClick={this.hideRightSidebar}
													/>
												</span>
											</div>
										</div>
										<form action='#'>
											<div className='form-group without-lable '>
												<div className='input-group-wrapper search'>
													<div className='effect-1' />
												</div>
											</div>
										</form>
									</div>
								</div>
							</section>

							<section className='message-info'>
								{this.getMessageList()}
							</section>
						</section>
					</div>
				</nav>
			</>
		);
	}
}
const mapDispatchToProps = {
	disableOuterClick: true,
	messageListSidebarOpen: messageListSidebar.open,
	messageListSidebarClose: messageListSidebar.close,
	chatListSidebarOpen: chatListSidebar.open,
	userListSidebarOpen: userListSidebar.open,
	openChatHistory: openChatHistory,
};

const mapStateToProps = (state) => {
	return {
		isMessageListSidebarOpen: state.sidebars.isMessageListSidebarOpen,
		messagehistory: state.messagehistory.items || [],
		allOnlineUserList: state.socketusers.allOnlineUserList,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(MessageList));
