import React, { Component } from 'react';
import MoreSVG from './../../assets/images/svg/more.svg';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { reportPlayer, unfollow } from '../../actions/modals';
import {
	reportPlayerForm,
	followForm,
	unfollowForm,
	addAsFriendForm,
	unFriendForm,
} from '../../actions/forms';
import { openChatHistory } from '../../actions/chathistory';
import { withTranslation } from 'react-i18next';

import { friendListSidebar, chatListSidebar } from '../../actions/sidebars';
import i18n from '../../i18n';
class NiceDropdownComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			display: false,
			visible: false,
			parentHeight: 0,
			parentWidth: 0,
			directionY: 'bottom',
			directionX: 'left',
		};

		this.display = this.display.bind(this);
		this.showChatListSidebar = this.showChatListSidebar.bind(this);
		this.hideProxy = this.hideProxy.bind(this);
	}

	showChatListSidebar(profileDetail) {
		this.props.friendListSidebarClose();
		this.props.chatListSidebarOpen();
		this.props.openChatHistory(profileDetail);
	}

	display() {
		//update report player fields onclick
		//console.log(this.props.reporterId, this.props.reporteeId);
		this.props.reportPlayerFormUpdateField('reporterId', this.props.reporterId);
		this.props.reportPlayerFormUpdateField('reporteeId', this.props.reporteeId);
		this.props.reportPlayerFormUpdateField('reason', '');

		//update unfollow fields onclick
		this.props.unfollowFormUpdateField('followerId', this.props.followerId);
		this.props.unfollowFormUpdateField('followeeId', this.props.followeeId);
		this.props.unfollowFormUpdateField('avatarUrl', this.props.avatarUrl);
		this.props.unfollowFormUpdateField('userName', this.props.userName);

		this.setState((state, props) => {
			return {
				display: !state.display,
				parentHeight: this.parent.offsetHeight,
				parentWidth: this.parent.offsetWidth,
			};
		});
		setTimeout(() => {
			this.setState((state, props) => {
				return {
					directionX:
						window.innerWidth -
							this.parent.offsetWidth -
							this.child.offsetWidth >
						this.parent.getBoundingClientRect().left
							? 'left'
							: 'right',
					directionY: this.props.fixToBottom
						? 'top'
						: window.innerHeight -
								this.parent.offsetHeight -
								this.child.offsetHeight >
						  this.parent.getBoundingClientRect().top
						? 'top'
						: 'bottom',
					visible: !state.visible,
				};
			});
		}, 20);
	}
	hideProxy = (e) => {
		if (
			(this.parent && this.parent.contains(e.target)) ||
			(this.child && this.child.contains(e.target))
		) {
			return;
		}
		this.setState({
			display: false,
			visible: false,
			parentHeight: 0,
			parentWidth: 0,
			directionY: 'bottom',
			directionX: 'left',
		});
		return;
	};

	hideDropdown() {
		this.setState({
			display: false,
			visible: false,
		});
	}

	componentWillMount() {
		document.addEventListener('mousedown', this.hideProxy, false);
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.hideProxy, false);
	}
	render() {
		const { t } = this.props;
		const lang = i18n.language;
		return (
			<div className='dropdown'>
				<div
					className='ripple-effect dropbtn'
					id='dropdownMenuButton'
					data-toggle='dropdown'
					aria-haspopup='true'
					aria-expanded='false'
					ref={(ele) => {
						this.parent = ele;
					}}
					onClick={this.display}
				>
					<img className='more-icon  ripple ' src={MoreSVG} alt='' />
					<span className='ink animate' />
				</div>

				<div
					className={
						(this.state.display
							? 'dropdown-content'
							: 'dropdown-content-hidden') +
						(this.state.visible ? '' : ' dropdown-content-not-visible')
					}
					style={
						this.state.display && this.state.visible
							? {
									[this.state.directionY]: this.state.parentHeight + 'px',
									[this.state.directionX]: '0px',
							  }
							: {}
					}
					ref={(ele) => {
						this.child = ele;
					}}
				>
					<a
						className='dropdown-item text-center text-center mb-1'
						href={'/' + lang + '/profile/' + this.props.profileId}
					>
						{t('viewprofile')}
					</a>

					<span
						className='a dropdown-item text-center text-center mb-1'
						onClick={(e) => {
							this.showChatListSidebar({
								id: this.props.profileId,
								userName: this.props.userName,
								profileUrl: this.props.avatarUrl,
							});
							this.hideDropdown();
						}}
						style={
							this.props.reporterId === this.props.reporteeId
								? { display: 'none' }
								: {}
						}
					>
						Message
					</span>

					<div
						className='border-line w-100 my-3'
						style={
							this.props.reporterId === this.props.reporteeId
								? { display: 'none' }
								: {}
						}
					/>
					{this.props.isFriend === false ? (
						<span
							className='a dropdown-item text-center text-center mb-1'
							onClick={() => {
								this.props.addAsFriendFormSubmit({
									profileSecondId: this.props.reporteeId,
								});
								this.hideDropdown();
							}}
							style={
								this.props.reporterId === this.props.reporteeId
									? { display: 'none' }
									: {}
							}
						>
							{t('addAsFriend')}
						</span>
					) : (
						<span
							className='a dropdown-item text-center text-center mb-1'
							onClick={() => {
								this.props.unFriendFormSubmit({
									profileIdToUnfriend: this.props.reporteeId,
								});
								this.hideDropdown();
							}}
							style={
								this.props.reporterId === this.props.reporteeId
									? { display: 'none' }
									: {}
							}
						>
							{t('removeFriend')}
						</span>
					)}
					{this.props.following === 'true' ? (
						<span
							className='a dropdown-item text-center text-center mb-1'
							onClick={() => {
								this.props.unfollowOpen();
								this.hideDropdown();
							}}
							style={
								this.props.reporterId === this.props.reporteeId
									? { display: 'none' }
									: {}
							}
						>
							{t('unfollow')}
						</span>
					) : (
						<span
							className='a dropdown-item text-center text-center mb-1'
							onClick={() => {
								this.props.followFormSubmit({
									followeeId: this.props.reporteeId,
								});
								this.hideDropdown();
							}}
							style={
								this.props.reporterId === this.props.reporteeId
									? { display: 'none' }
									: {}
							}
						>
							{t('follow')}
						</span>
					)}
					<span
						className='a dropdown-item text-center text-center mb-1'
						onClick={() => {
							this.props.reportPlayerOpen();
							this.hideDropdown();
						}}
						style={
							this.props.reporterId === this.props.reporteeId
								? { display: 'none' }
								: {}
						}
					>
						{t('report')}
					</span>
					<div className='border-line w-100 my-3' />
					<span
						className='a dropdown-item text-center text-center text-center'
						onClick={() => {
							this.hideDropdown();
						}}
					>
						{t('challengePage.cancel')}
					</span>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = {
	reportPlayerOpen: reportPlayer.open,
	reportPlayerFormUpdateField: reportPlayerForm.updateField,
	unfollowFormUpdateField: unfollowForm.updateField,
	unfollowOpen: unfollow.open,
	addAsFriendFormSubmit: addAsFriendForm.submit,
	unFriendFormSubmit: unFriendForm.submit,
	followFormSubmit: followForm.submit,
	openChatHistory: openChatHistory,
	friendListSidebarClose: friendListSidebar.close,
	chatListSidebarOpen: chatListSidebar.open,
};

const mapStateToProps = (state) => {
	return {
		// isLoggedIn: state.user.isLoggedIn,
		// isAnyModalOpen: state.modals.isAnyModalOpen
	};
};
const defaultProps = {
	fixToBottom: false,
};
NiceDropdownComponent.defaultProps = defaultProps;

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withTranslation()(NiceDropdownComponent))
);
