import homeImageMTN from "../assets/images/home-banner-miggster-mobile.jpg";
import homeImageMTNMobile from "../assets/images/home-banner-miggster-mobile.jpg";
import homeImage from "../assets/images/home-banner.png";
import subscribeDevice3xImageMTN from "../assets/images/subscribe-device-miggster.png";
import subscribeDevice3xImage from "../assets/images/subscribe-device@3x.png";
import tagImageMTN from "../assets/images/tag@3x-ticket-mtn.png";
import tagImage from "../assets/images/tag@3x.png";
import Logo from "../assets/images/svg/logo.svg";
import DashboardOn from "../assets/images/svg/dashboard-on.svg";
import DashboardOnMTN from "../assets/images/svg/dashboard-on-mtn.svg";
import TicketOn from "../assets/images/svg/ticket-on.svg";
import TicketOnMTN from "../assets/images/svg/ticket-on-mtn.svg";
import wallpaperMTN from "../assets/images/new-banner-mtn.png";
import wallpaper from "../assets/images/wallpaper-v1.jpg";
import FeaturedImage1 from "../assets/images/featuredLogo4.png";
import FeaturedImage2 from "../assets/images/featuredLogo5.png";
import FeaturedImage3 from "../assets/images/featuredLogo6.png";
import FeaturedImageMTN1 from "../assets/images/featuredLogoMNT1.png";
import FeaturedImageMTN2 from "../assets/images/featuredLogoMNT2.png";
import FeaturedImageMTN3 from "../assets/images/featuredLogoMNT3.png";
import facebookIconMTN from "../assets/images/svg/fb-container-black.svg";
import twitterIconMTN from "../assets/images/svg/twitter-container-black.svg";
import instagramIconMTN from "../assets/images/svg/instagram-container-black.svg";
import facebookIcon from "../assets/images/svg/facebook-container.svg";
import twitterIcon from "../assets/images/svg/twitter-container.svg";
import instagramIcon from "../assets/images/svg/instagram-container.svg";
import navRightArrow from "../assets/images/svg/nav-arrow-right-blue.svg";
import navRightArrowMTN from "../assets/images/svg/nav-arrow-right.svg";
import highwayRiderExtreme from "../assets/images/Highway-Rider-Extreme.png";
import piratesOfIslets from "../assets/images/Pirates-of-Islets.jpg";
import candyBubble from "../assets/images/candy-bubble.jpg";
import VisualTimerImgMTN from "../assets/images/svg/visual-timer-mtn.svg";
import VisualTimerImg from "../assets/images/svg/visual-timer.svg";
import VisualTimerWhite from "../assets/images/svg/visual-timer-white.svg";
import banner1 from "../assets/images/adv/banner-1.jpeg";
import banner1MTN from "../assets/images/adv/challenge-banner-mtn.jpg";
import dashboardBannerMTN from "../assets/images/adv/dashboard-banner-mtn.png";
import aboutBannerMobile from "../assets/images/about-banner@mobile.png";
import aboutBannerMobileMTN from "../assets/images/about-banner@mobile-mtn.png";
import aboutWeb from "../assets/images/about-page-web.png";
import aboutWebMTN from "../assets/images/about-page-web-mtn.png";
import aboutMobile from "../assets/images/about-page-mobile.png";
import aboutMobileMTN from "../assets/images/about-page-mobile-mtn.png";
import gameController3x from "../assets/images/game-controller@3x.png";
import trophy3x from "../assets/images/trophy@3x.png";
import prize3x from "../assets/images/prize@3x.png";
import gameControllerSvg from "../assets/images/svg/game-controller.svg";
import trophySvg from "../assets/images/svg/trophy.svg";
import prizeSvg from "../assets/images/svg/prize.svg";
import gameController3xMTN from "../assets/images/football-game-controller.png";
import trophy3xMTN from "../assets/images/football-trophy.png";
import prize3xMTN from "../assets/images/football-tournaments.png";

import gameControllerSvgMTN from "../assets/images/svg/game-controller-mtn.svg";
import trophySvgMTN from "../assets/images/svg/trophy-mtn.svg";
import prizeSvgMTN from "../assets/images/svg/prize-mtn.svg";
import helpSupportBannerMTN from "../assets/images/help-support-banner-mtn.png";
import privacyPolicyBannerMTN from "../assets/images/privacy-policy-banner-mtn.png";
import termConditionsBannerMTN from "../assets/images/terms-condition-banner-mtn.png";
import contactUsBannerMTN from "../assets/images/contact-banner-mtn2.png";
import prizeIconFourth from "../assets/images/svg/x-4-mtn.svg";
import prizeIconFourthMTN from "../assets/images/svg/x-4-mtn.svg";
import challengeRewardDefault from "../assets/images/poo_default.png";
import challengeRewardDefaultMTN from "../assets/images/pool_default-mtn.png";
import loadingGIF from "../assets/images/Loaders/MiggsterPlusLogo_Loader.gif";
import headerLogo from "../assets/images/headerLogo.png";
import headerLogoSvg from "../assets/images/svg/miggster-logo.svg";
import headerLogoSvgFooter from "../assets/images/svg/miggster-logo-footer.svg";

const platformParams = [];
platformParams["arcadex"] = {
	title: "Arcade",
	titleX: "Arcade X",
	envTitle: "ArcadeX",
	baseColor: "",
	baseButtonClassSufix: "pink",
	baseButtonClassSufix2: "pink",
	shadeButtonClassSufix: "pink",
	className: "arcadex",
	colorClassSufix: "secondary",
	textColorClassSufix: "secondary",
	linkClassSufix: "danger",
	favicon: "favicon.ico",
	bgHomeImageJoinNow: homeImage,
	homeTagImage: tagImage,
	subscribeDeviceImage: subscribeDevice3xImage,
	logoImage: Logo,
	headerLogo: Logo,
	dashboardOnImage: DashboardOn,
	ticketOnImage: TicketOn,
	newsBannerImage: wallpaper,
	featuredImage1: FeaturedImage1,
	featuredImage2: FeaturedImage2,
	featuredImage3: FeaturedImage3,
	gameCardImageHome1: candyBubble,
	gameCardImageHome2: highwayRiderExtreme,
	facebook: facebookIcon,
	twitter: twitterIcon,
	instagram: instagramIcon,
	navRightArrow: navRightArrow,
	visualTimer: VisualTimerImg,
	VisualTimerWhite: VisualTimerWhite,
	challengeDefaultBannerImage: banner1,
	dashboardDefaultBannerImage: banner1,
	helpSupportBanner: helpSupportBannerMTN,
	termConditionsBanner: helpSupportBannerMTN,
	privacyPolicyBanner: helpSupportBannerMTN,
	contactUsBanner: helpSupportBannerMTN,
	aboutBannerMobile: aboutBannerMobile,
	aboutWeb: aboutWeb,
	aboutMobile: aboutMobile,
	privacyTableBgColor: "bg-secondary",
	privacyTableTxtColor: "",
	gameController3x: gameController3x,
	trophy3x: trophy3x,
	prize3x: prize3x,
	gameControllerSvg: gameControllerSvg,
	trophySvg: trophySvg,
	prizeSvg: prizeSvg,
	prizeIconFourth: prizeIconFourth,
	challengeRewardDefault: challengeRewardDefault,
	modalv2Color: "#1a46e5",
	loadingGif: loadingGIF,
};
platformParams["mtn"] = {
	title: "Miggster",
	titleX: "Miggster",
	envTitle: "Miggster",
	miggsterPlus: "Miggster Plus",
	baseColor: "mtnBlack",
	baseButtonClassSufix: "mtnThemeMainColor",
	baseButtonClassSufix2: "mtnBlack",
	shadeButtonClassSufix: "shade-orange",
	className: "mtn",
	colorClassSufix: "mtnBlack",
	textColorClassSufix: "mtn-light-black",
	linkClassSufix: "white",
	linkClassNoti: "mtnThemeMainColor",
	favicon: "favicon.ico",
	bgHomeImageJoinNow: homeImageMTN, // miggsterLogo,
	bgHomeImageMobile: homeImageMTNMobile, // miggsterLogo,
	homeTagImage: tagImageMTN,
	subscribeDeviceImage: subscribeDevice3xImageMTN,
	logoImage: headerLogoSvg,
	logoFooterImage: headerLogoSvgFooter,
	headerLogo: headerLogo,
	headerLogoSvg: headerLogoSvg,
	dashboardOnImage: DashboardOnMTN,
	ticketOnImage: TicketOnMTN,
	newsBannerImage: wallpaperMTN,
	featuredImage1: FeaturedImageMTN1,
	featuredImage2: FeaturedImageMTN2,
	featuredImage3: FeaturedImageMTN3,
	gameCardImageHome1: candyBubble,
	gameCardImageHome2: piratesOfIslets,
	facebook: facebookIconMTN,
	twitter: twitterIconMTN,
	instagram: instagramIconMTN,
	navRightArrow: navRightArrowMTN,
	visualTimer: VisualTimerImgMTN,
	visualTimerWhite: VisualTimerWhite,
	challengeDefaultBannerImage: banner1MTN,
	dashboardDefaultBannerImage: dashboardBannerMTN,
	helpSupportBanner: helpSupportBannerMTN,
	termConditionsBanner: termConditionsBannerMTN,
	privacyPolicyBanner: privacyPolicyBannerMTN,
	aboutBannerMobile: aboutBannerMobileMTN,
	contactUsBanner: contactUsBannerMTN,
	aboutWeb: aboutWebMTN,
	aboutMobile: aboutMobileMTN,
	privacyTableBgColor: "bg-mtn-orange",
	privacyTableTxtColor: "bg-white",
	gameController3x: gameController3xMTN,
	trophy3x: trophy3xMTN,
	prize3x: prize3xMTN,
	gameControllerSvg: gameControllerSvgMTN,
	trophySvg: trophySvgMTN,
	prizeSvg: prizeSvgMTN,
	prizeIconFourth: prizeIconFourthMTN,
	challengeRewardDefault: challengeRewardDefaultMTN,
	modalv2Color: "#000000",
	// 'loadingGif': loadingGIFMTN,
	loadingGif: loadingGIF,
};

platformParams["miggster"] = {
	$miggsterBGDarkGrey: "#020202",
	$miggsterBGLightGrey: "#121319",
	$miggsterTextHeading: "#E2E2E2",
	$miggsterTextParagraph: "#868686",
	$miggsterButtonPrimary: "#C9123F",
	$miggsterButtonPrimaryText: "#FFFFFF",
	$miggsterButtonSecondary: "#14151E",
	$miggsterButtonSecondaryText: "#464672",
	$miggsterBGSection: "#202129",
	$miggsterTextAccent: "#EA3A54",
};
export default platformParams[process.env.REACT_APP_PLATFORM]
	? platformParams[process.env.REACT_APP_PLATFORM]
	: platformParams["arcadex"];
