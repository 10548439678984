import React, { Component } from "react";
import "./PrivacyPolicyComponent.scss";
import platformParams from "../../utils/platformParams";

class PrivacyContent extends Component {
  render() {
    return (
      <>
        <section className={this.props.setclass}>
          <div
            className={
              this.props.setclass !== "container-fluid" ? "padder-bottom" : ""
            }
          >
            <div className="downloads-container" style={{justifyContent: "center"}}>
              <a href={"https://miggster.freshdesk.com/en/support/solutions/67000388168"} target="_blank" rel="noopener noreferrer" className="terms-btn">Privacy Policy</a>
            </div>
             {/* 
              !!!!!!!!NB!!!!!!!!!
              if you are looking for the old Privacy Policy look back in the version control to any commit before this one

              commit before this one: 7157014d54d07d49a44737bcb59ceaef9b29327b
            */}

           

          </div>
        </section>
      </>
    );
  }
}

export default PrivacyContent;
