import socket from '../socket';
import { clearNotificationLogout } from './notification';
import { socketOnMap } from '../redux-config/socketOnMap';
import { resetSocketState } from './socketusers';
import { messageHistoryRequest, resetMessageHistory } from './messagehistory';
import { closeChatHistory } from './chathistory';

export const emitInitSocket = () => (dispatch, getState) => {
	let userDetail = getState().user.item;
	if (userDetail.id) {
		socket.emit('user logged in', {
			userId: userDetail.id,
			token: userDetail.token,
		});
	}
};

export const onInitSocket = () => (dispatch, getState) => {
	let userDetail = getState().user.item;
	if (userDetail.id) {
		socketOnMap.map((socketOn) => {
			return socket.on(socketOn.name, (data) =>
				dispatch(socketOn.action(data))
			);
		});
	}
};

export const initialiseSocket = () => (dispatch, getState) => {
	let userDetail = getState().user.item;
	if (userDetail.id) {
		socket.connect();
		dispatch(emitInitSocket());
		dispatch(onInitSocket());
	}
};

export const onStopSocket = () => (dispatch, getState) => {
	socket.disconnect();
	dispatch(resetMessageHistory());
	dispatch(closeChatHistory());
	dispatch(clearNotificationLogout());
	dispatch(resetSocketState());
};
