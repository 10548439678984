import React, { Component } from "react";
import { connect } from "react-redux";
import { login, register, forgotPassword } from "../../../actions/modals";
import {
  togglePasswordEyeLogin,
  toggleHyveRegister
} from "../../../actions/toggles";
import { loginForm, loginHyveForm } from "../../../actions/forms";
import "./LoginModal.scss";
import ModalV2 from "../ModalV2";
import { validateEmail, validateMsisdn } from "../../../utils/validators";
import { APP_MESSAGES } from "../../../services/config/messages";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import { withTranslation } from "react-i18next";

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.showLogin = this.showLogin.bind(this);
    this.hideLogin = this.hideLogin.bind(this);
    this.state = {
      isLandScape: false
    };
  }
  showLogin() {
    this.props.loginOpen();
  }
  hideLogin() {
    this.props.loginClose();
  }
  render() {
    const { t } = this.props;
    let Error;
    let Success;
    if (this.props.isLoginType !== "hyve") {
      Success = this.props.loginForm.fields.email.successMsg
        ? APP_MESSAGES[this.props.loginForm.fields.email.successMsg](
            this.props.loginForm.fields.email.successPayload,
            () => {
              this.props.loginFormResetSuccess("email");
            }
          )
        : props => <>{""}</>;
      Error = this.props.loginForm.fields.email.errorMsg
        ? APP_MESSAGES[this.props.loginForm.fields.email.errorMsg](
            this.props.loginForm.fields.email.errorPayload,
            () => {
              this.props.loginFormResetError("email");
            }
          )
        : props => <>{""}</>;
    } else {
      Success = this.props.loginHyveForm.fields.email.successMsg
        ? APP_MESSAGES[this.props.loginHyveForm.fields.email.successMsg](
            this.props.loginHyveForm.fields.email.successPayload,
            () => {
              this.props.loginHyveFormResetSuccess("email");
            }
          )
        : props => <>{""}</>;

      Error = this.props.loginHyveForm.fields.email.errorMsg
        ? APP_MESSAGES[this.props.loginHyveForm.fields.email.errorMsg](
            this.props.loginHyveForm.fields.email.errorPayload,
            () => {
              this.props.loginHyveFormResetError("email");
            }
          )
        : props => <>{""}</>;
    }

    return (
      <>
        <ModalV2
          handleHide={this.hideLogin}
          isOpen={this.props.isLoginOpen}
          disableOuterClick={false}
          withError={
            this.props.loginForm.fields.email.error ||
            this.props.loginHyveForm.fields.email.error ||
            this.props.loginForm.fields.email.success ||
            this.props.loginHyveForm.fields.email.success
          }
          showSocialBtns={false}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="bold">{t("signin")}</h4>
              </div>
            </div>
            {/* <div className="row gutter-space">
              <div className="col pr-1">
                <button className="btn btn-outline-secondary social-login align-items-center justify-content-center  btn-block">
                  <span className="social-icon facebook mr-2">
                    <img src={IconFacebook} alt="" />
                  </span>{" "}
                  facebook
                </button>
              </div>
              <div className="col pl-1">
                <button className="btn btn-outline-secondary social-login d-flex align-items-center justify-content-center  btn-block">
                  <span className="social-icon google mr-2">
                    <img src={IconGoogle} alt="" />
                  </span>{" "}
                  Google
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mt-4 mb-0 mt-sm-5 mb-sm-3 devider d-flex justify-content-center">
                <div className="dropdown-divider w-100" />
                <span className="position-absolute mt-nagative-4">OR</span>
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    if (
                      !(
                        this.props.loginForm.isSubmitting ||
                        this.props.loginHyveForm.isSubmitting
                      )
                    ) {
                      if (this.props.isLoginType === "hyve") {
                        let postParam;
                        if (!this.props.loginHyveForm.fields.email.value) {
                          this.props.loginHyveFormSetError(
                            "email",
                            "NOT_EMPTY"
                          );
                          return false;
                        } else if (
                          !isNaN(this.props.loginHyveForm.fields.email.value)
                        ) {
                          if (
                            validateMsisdn(
                              this.props.loginHyveForm.fields.email.value
                            )
                          ) {
                            let newMobileno = validateMsisdn(
                              this.props.loginHyveForm.fields.email.value
                            );
                            postParam = {
                              email: null,
                              password: this.props.loginHyveForm.fields.password
                                .value,
                              msisdn: newMobileno
                            };
                          } else {
                            this.props.loginHyveFormSetError("email", "MOBILE");
                            return false;
                          }
                        } else {
                          if (
                            !validateEmail(
                              this.props.loginHyveForm.fields.email.value
                            )
                          ) {
                            this.props.loginHyveFormSetError("email", "EMAIL");
                            return false;
                          } else {
                            postParam = {
                              email: this.props.loginHyveForm.fields.email
                                .value,
                              password: this.props.loginHyveForm.fields.password
                                .value,
                              msisdn: null
                            };
                          }
                        }
                        this.props.loginHyveFormSubmit(postParam);
                      } else {
                        let emailAddress = this.props.loginForm.fields.email
                          .value;
                        emailAddress = emailAddress.trim();

                        if (!emailAddress) {
                          this.props.loginFormSetError("email", "NOT_EMPTY");
                          return false;
                        }
                        if (!validateEmail(emailAddress)) {
                          this.props.loginFormSetError("email", "EMAIL");
                          return false;
                        }

                        this.props.loginFormSubmit({
                          email: emailAddress,
                          password: this.props.loginForm.fields.password.value
                        });
                      }
                    }
                  }}
                >
                  <AppInputComponent
                    labelText={t("labelEmailAdd")}
                    divClass="form-group with-lable mt-3"
                    value={
                      this.props.isLoginType === "hyve"
                        ? this.props.loginHyveForm.fields.email.value
                        : this.props.loginForm.fields.email.value
                    }
                    onChange={event => {
                      if (this.props.isLoginType === "hyve") {
                        this.props.loginHyveFormSetError("email", "");
                        this.props.loginHyveFormUpdateField(
                          "email",
                          event.target.value.trim()
                        );
                      } else {
                        this.props.loginFormSetError("email", "");
                        this.props.loginFormUpdateField(
                          "email",
                          event.target.value.trim()
                        );
                      }
                    }}
                  />

                  <AppInputComponent
                    type={this.props.isPasswordVisible ? "text" : "password"}
                    autoComplete="true"
                    labelText={t("labelPassword")}
                    divClass="form-group with-lable mt-5"
                    value={
                      this.props.isLoginType === "hyve"
                        ? this.props.loginHyveForm.fields.password.value
                        : this.props.loginForm.fields.password.value
                    }
                    onChange={event => {
                      if (this.props.isLoginType === "hyve") {
                        this.props.loginHyveFormSetError("email", "");
                        this.props.loginHyveFormUpdateField(
                          "password",
                          event.target.value
                        );
                      } else {
                        this.props.loginFormSetError("email", "");
                        this.props.loginFormUpdateField(
                          "password",
                          event.target.value
                        );
                      }
                    }}
                    inputAddon={() => (
                      <span
                        toggle="#password-field"
                        className={
                          "field-icon toggle-password" +
                          (this.props.isPasswordVisible
                            ? " eye-close"
                            : " eye-open")
                        }
                        onClick={this.props.passwordEyeToggle}
                      />
                    )}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      this.props.forgotPasswordOpen();
                    }}
                    className="btn btn-link forgot-password mt-1"
                  >
                    {t("forgotPassword")}
                  </button>
                  <button
                    type="submit"
                    className={
                      "btn btn-pink dark btn-block mt-2 mt-sm-5" +
                      (this.props.loginForm.isSubmitting ||
                      this.props.loginHyveForm.isSubmitting
                        ? " disabled"
                        : "")
                    }
                  >
                    {this.props.loginForm.isSubmitting ||
                    this.props.loginHyveForm.isSubmitting
                      ? "Signing In..."
                      : t("signin")}
                  </button>
                </form>
              </div>
            </div>
            <div className="cross-div">
              <div className="row">
                <div className="col-sm-12 text-center mt-2 mt-sm-4">
                  <button
                    className="btn btn-link text-white creat-account"
                    onClick={() => {
                      this.props.resetHyveRegister();
                      if (this.props.isLoginType === "hyve") {
                        this.props.toggleHyveRegister();
                      }

                      this.props.registerOpen();
                    }}
                  >
                    {t("or")} {t("createAccount")}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-center mt-2 pb-3 pb-sm-0 mt-sm-5">
                  <small className="text-white">
                    <a
                      className="text-white"
                      href={"https://miggster.freshdesk.com/en/support/solutions/67000388168"} target="_blank" rel="noopener noreferrer"
                    >
                      {t("privacyPolicy")}
                    </a>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </ModalV2>
      </>
    );
  }
}
const mapDispatchToProps = {
  passwordEyeToggle: togglePasswordEyeLogin.toggle,
  loginOpen: login.open,
  loginClose: login.close,
  forgotPasswordOpen: forgotPassword.open,
  registerOpen: register.open,
  toggleHyveRegister: toggleHyveRegister.toggle,
  resetHyveRegister: toggleHyveRegister.reset,

  loginFormSubmit: loginForm.submit,
  loginFormReset: loginForm.reset,
  loginFormResetField: loginForm.resetField,
  loginFormUpdateField: loginForm.updateField,
  loginFormSetError: loginForm.setError,
  loginFormSetSuccess: loginForm.setSuccess,
  loginFormResetError: loginForm.resetError,
  loginFormResetSuccess: loginForm.resetSuccess,

  loginHyveFormSubmit: loginHyveForm.submit,
  loginHyveFormReset: loginHyveForm.reset,
  loginHyveFormResetField: loginHyveForm.resetField,
  loginHyveFormUpdateField: loginHyveForm.updateField,
  loginHyveFormSetError: loginHyveForm.setError,
  loginHyveFormSetSuccess: loginHyveForm.setSuccess,
  loginHyveFormResetError: loginHyveForm.resetError,
  loginHyveFormResetSuccess: loginHyveForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isLoginOpen: state.modals.isLoginOpen,
    isLoginType: state.toggles.hyveLogin ? "hyve" : "simple",
    isPasswordVisible: state.toggles.pwEyeLogin,
    loginForm: state.forms.loginForm,
    loginHyveForm: state.forms.loginHyveForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginModal));
