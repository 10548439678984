import React, {Component} from "react";
import {connect} from "react-redux";
import {maintenanceModeModal} from "../../../actions/modals";
import "./MaintenanceModeModal.scss";
import Modal from "../Modal";
import {withTranslation} from "react-i18next";
import {axios} from "../../../utils/axios";


class MaintenanceModeModal extends Component {
    state = {
        statusData: {
            "title": "",
            "description": "",
            "imageUrl": ""
        }
    }

    constructor(props) {
        super(props);
        this.showMaintenanceModeModal = this.showMaintenanceModeModal.bind(this);
        this.hideMaintenanceModeModal = this.hideMaintenanceModeModal.bind(this);
    }

    showMaintenanceModeModal() {
        this.props.maintenanceModeModalOpen();
    }

    hideMaintenanceModeModal() {
        this.props.maintenanceModeModalClose();
    }

    componentDidMount() {
        // Check if modal should pop up and show it if needs be
        this.shouldShowMaintenanceModeModal()
            .then(shouldShow => {
                if(shouldShow){
                    this.showMaintenanceModeModal();
                }
            });
    }

    shouldShowMaintenanceModeModal() {
        let ttlInMilliseconds = 60000; // 1 minute (This is how long we will trust the status info)
        let storeKey = "maintenance_check";
        let now = new Date().getTime();

        // Check if there is data set in localStorage for ttl
        let storedData = localStorage.getItem(storeKey);

        // Assign function to variable as we're going to use it twice
        // (1. For when no info has been persisted to local store; and
        //  2. For when the local store ttl has expired)
        let updateStateFromAPI = () => {
            return this.fetchStatusInfoForEnvironment(process.env.REACT_APP_ENV)
                .then(statusDetails => {
                    if(!statusDetails){
                        console.log(`Environment [${process.env.REACT_APP_ENV}] NOT found in status file!`);
                        return Promise.resolve(false);
                    }
                    let ttl = now + ttlInMilliseconds;
                    localStorage.setItem("maintenance_check", JSON.stringify({
                        ttl: ttl,
                        statusDetails: statusDetails
                    }));
                    this.setState({statusData: statusDetails});
                    return Promise.resolve(statusDetails.isMainternanceMode);
                });
        }

        if (null != storedData) {
            storedData = JSON.parse(storedData);

            // TTL has expired
            if(now >= storedData.ttl){
               // Fetch data, set state and resolve promise with value
                return updateStateFromAPI();
            } else {
                // TTL is still valid; Set state to stored data and resolve based on data
                this.setState({statusData: storedData.statusDetails});
                return Promise.resolve(storedData.statusDetails.isMainternanceMode);
            }
        } else {
            // Nothing in local storage yet; Fetch data, set state and resolve promise with value
            return updateStateFromAPI();
        }
    }

    fetchStatusInfoForEnvironment(environmentString) {
        let v = (new Date()).getTime();
        return axios
            .request({
                url: `https://miggster-maintenance-manager.s3.eu-west-2.amazonaws.com/status/status.json?v=${v}`,
                method: "get",
                headers: {
                    "content-type": "application/json"
                }
            })
            .then(response => {
                let environmentKey;

                switch (environmentString) {
                    case 'DEV_MTNX':
                        environmentKey = "dev";
                        break;
                    case 'STAGING_MTNX':
                        environmentKey = "staging";
                        break;
                    default:
                        environmentKey = "production";
                }

                if (!response.data.hasOwnProperty(environmentKey)) {
                    console.log(`Environment [${environmentKey}] not set in status file`);
                    return false;
                }

                return response.data[environmentKey];
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <Modal
                    showCloseBtn={false}
                    isOpen={this.props.isMaintenanceModeModalOpen}
                    disableOuterClick={true}
                    modalClass="modal"
                    modalCustomClass="bg-miggsterBGDarkGrey modal-maintenance-mode"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 align-self-center">
                                <h1>{this.state.statusData.title}</h1>
                                <h3>{this.state.statusData.description}</h3>
                            </div>
                            {/*<div className="col-sm-4">*/}
                            {/*    <img src={this.state.statusData.imageUrl} className="img-fluid float-right" alt="Maintenance Mode image"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                </Modal>
            </>
        );
    }
}

const mapDispatchToProps = {
    maintenanceModeModalOpen: maintenanceModeModal.open,
    maintenanceModeModalClose: maintenanceModeModal.close,
};

const mapStateToProps = state => {
    return {
        isMaintenanceModeModalOpen: state.modals.isMaintenanceModeModalOpen
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(MaintenanceModeModal));
