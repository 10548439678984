import React, { Component } from "react";
import { connect } from "react-redux";
import { createAvatar } from "../../../actions/modals";
import "./CreateAvtarModal.scss";
import ModalV2 from "../ModalV2";
import avtar from "../../../assets/images/avatar-demo.png";
import camerasvg from "../../../assets/images/svg/camera.svg";
import { createAvatarForm } from "../../../actions/forms";
import { fileUpload } from "../../../services/fileUpload.service";
import { APP_MESSAGES } from "../../../services/config/messages";
import defaultAvatar from "../../../assets/images/svg/default-avatar.svg";
import addIcon from "../../../assets/images/svg/icon-plus.svg";
import editIcon from "../../../assets/images/svg/icon-edit-avatar.svg";
import navClose from "../../../assets/images/svg/nav-close.svg";
import { withTranslation, Trans } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class CreateAvtarModal extends Component {
  constructor(props) {
    super(props);
    this.showCreateAvtar = this.showCreateAvtar.bind(this);
    this.hideCreateAvtar = this.hideCreateAvtar.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.state = {
      isLandScape: false,
      isLoading: false,
    };
  }
  showCreateAvtar() {
    this.props.createAvatarOpen();
  }
  hideCreateAvtar() {
    this.props.createAvatarClose();
  }
  uploadFile(event) {
    if (event.target.files.length > 0) {
      this.setState(prevState => ({
        isLoading: true,
      }))
      const fileSize = event.target.files[0].size / 1000000;
      const fileType = event.target.files[0].type.split("/")[1].toLowerCase();

      if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        this.props.createAvatarFormSetError("avatar", "FILE_TYPE");
        this.setState(prevState => ({
          isLoading: false,
        }))
        return false;
      } else if (fileSize > 2) {
        this.props.createAvatarFormSetError("avatar", "FILE_SIZE");
        this.setState(prevState => ({
          isLoading: false,
        }))
        return false;
      }
      this.props.createAvatarFormResetError("avatar");
      fileUpload(event.target.files)
        .then(url => {
          this.props.createAvatarFormUpdateField("avatar", url).then(function(){
            setTimeout(() => {
              this.setState(prevState => ({
                isLoading: false,
              }))
            }, 1000)
          });
        })
        .catch(() => {
          this.setState(prevState => ({
            isLoading: false,
          }))
        });
      this.props.createAvatarFormReset(createAvatarForm);
    } else {
      this.props.createAvatarFormUpdateField("avatar", "");
      this.setState(prevState => ({
        isLoading: false,
      }))
    }
  }
  render() {
    const {isLoading} = this.state;
    let Error;
    Error = this.props.createAvatarForm.fields.avatar.errorMsg
      ? APP_MESSAGES[this.props.createAvatarForm.fields.avatar.errorMsg](
          this.props.createAvatarForm.fields.avatar.errorPayload,
          () => {
            this.props.createAvatarFormResetError("avatar");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    return (
      <>
        <ModalV2
          showCloseBtn={false}
          modalCrossSvgClass={true}
          handleHide={this.hideCreateAvtar}
          isOpen={this.props.isCreateAvatarOpen}
          // isOpen={true}
          disableOuterClick={true}
          withError={this.props.createAvatarForm.fields.avatar.error}
        >
          <div className="card-popup create-avatar">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="mb-3 bold">{t("createYourAvatar")}</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Error />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!this.props.createAvatarForm.isSubmitting) {
                      this.props.createAvatarFormSubmit({
                        avatarUrl: this.props.createAvatarForm.fields.avatar
                          .value
                      });
                    }
                  }}
                >
                  <div className="form-group avatar d-flex justify-content-center">
                    <input
                      type="file"
                      accept="image/*"
                      name="img[]"
                      className="file"
                      onChange={this.uploadFile}
                      ref={input => (this.inputElement = input)}
                    />
                   
                    <div className="avatar-wrapper">
                      {isLoading ? (
                        <div className="loading-avatar profile-pic-200"></div>
                      ) : (
                        <>
                          <img
                            src={
                              this.props.createAvatarForm.fields.avatar.value
                              ? this.props.createAvatarForm.fields.avatar.value
                              : defaultAvatar
                            }
                            className="profile-pic-200"
                            alt=""
                          />
                          {this.props.createAvatarForm.fields.avatar.value && !isLoading ? (
                            <div
                              className="a remove-avatar"
                              onClick={() => this.inputElement.click()}
                            >
                              <img src={editIcon} alt="" />
                            </div>
                          ) : (
                            ""
                          )}
                          {!this.props.createAvatarForm.fields.avatar.value && !isLoading ? (
                            <div
                              className="a add-avatar"
                              onClick={() => this.inputElement.click()}
                            >
                              <img src={addIcon} alt="" />
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={
                      "btn btn-block mt-5 iphone-SE-top"
                    }
                    disabled = {
                      this.props.createAvatarForm.isSubmitting || isLoading
                      ? "disabled" : null
                    }
                  >
                    {t("next")}
                  </button>
                </form>
              </div>
            </div>
            <div className="row privacy-policy">
              <div className="col-sm-12 text-center mt-4">
                <small className="text-white">
                  <Trans i18nKey="max2MbJpgFormat">
                    Max. 2 MB (JPG or PNG formats) <br /> Recommended size:
                    210px x 210px
                  </Trans>
                </small>
              </div>
            </div>
          </div>
        </ModalV2>
      </>
    );
  }
}
const mapDispatchToProps = {
  createAvatarOpen: createAvatar.open,
  createAvatarClose: createAvatar.close,
  createAvtarClose: createAvatar.modalclose,

  createAvatarFormSubmit: createAvatarForm.submit,
  createAvatarFormReset: createAvatarForm.reset,
  createAvatarFormResetField: createAvatarForm.resetField,
  createAvatarFormUpdateField: createAvatarForm.updateField,
  createAvatarFormSetError: createAvatarForm.setError,
  createAvatarFormSetSuccess: createAvatarForm.setSuccess,
  createAvatarFormResetError: createAvatarForm.resetError,
  createAvatarFormResetSuccess: createAvatarForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    userId: state.user.item ? state.user.item.id : 0,
    createAvatarForm: state.forms.createAvatarForm,
    isCreateAvatarOpen: state.modals.isCreateAvatarOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateAvtarModal));
