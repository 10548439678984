import React, { Component } from "react";
import { connect } from "react-redux";
import IconClose from "../../assets/images/svg/nav-close.svg";
import "./ModalOnboarding.scss";
import { SHOWN_PROFILES } from "../../types/tutorials";
import { createTutorialForm } from "../../actions/forms";
import platformParams from "../../utils/platformParams";

class ModalOnboarding extends Component {
  constructor(props) {
    super(props);
    this.hideProxy = this.hideProxy.bind(this);
  }
  componentWillMount() {
    if (!this.props.disableOuterClick) {
      document.addEventListener("mousedown", this.hideProxy, false);
    }
  }
  componentDidUpdate() {
    // if (this.props.isAnyModalOpen) document.body.classList.add("modal-open");
    // else document.body.classList.remove("modal-open");
  }
  componentWillUnmount() {
    if (!this.props.disableOuterClick) {
      document.removeEventListener("mousedown", this.hideProxy, false);
    }
  }
  hideProxy = e => {
    if (this.props.disableOuterClick) {
      return;
    }
    if (this.modal && this.modal.contains(e.target)) {
      return;
    }
    if (this.props.isOpen) {
      this.props.handleHide();
    }
    return;
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.modalClass +
            " fade modal-onboarding" +
            (this.props.isOpen ? " show" : "") +
            (this.props.withFooter ? "" : " without-footer")
          }
          style={this.props.isOpen ? { display: "block" } : { display: "none" }}
          tabIndex="-1"
          role="dialog"
          id={this.props.id}
          aria-labelledby="login"
        >
          <div className={this.props.modalDialogClass} role="document">
            <div
              className={this.props.modalContentClass}
              ref={modal => {
                this.modal = modal;
              }}
            >
              <div
                className={
                  "modal-body landscape " + this.props.modalCustomClass
                }
              >
                <div
                  className="popup-dismiss  d-lg-block"
                  onClick={this.props.handleHide}
                >
                  {this.props.showCloseBtn ? (
                    this.props.closeBtnType === "skip" ? (
                      <span
                        onClick={e => {
                          e.preventDefault();
                          this.props.createTutorialFormSubmit({
                            shownTutorial: SHOWN_PROFILES
                          });
                        }}
                      >
                        Skip
                      </span>
                    ) : (
                      <img src={IconClose} alt="" />
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="modal-head">
                    <div></div>
                    <img
                      src={platformParams['headerLogoSvg']}
                      alt=""
                      className="mainLogo"
                    />
                    <div></div>
                </div>
                {this.props.withHeader ? this.props.header : ""}
                <div className="slider-container">
                  {this.props.children}
                </div>
                {this.props.withFooter ? (
                  <div className="modal-next-container">
                    {this.props.footer}
                    <span
                        onClick={e => {
                          e.preventDefault();
                          this.props.createTutorialFormSubmit({
                            shownTutorial: SHOWN_PROFILES
                          });
                        }}
                        className="skip-btn"
                      >
                        Skip
                    </span>
                  </div>
                ) : ""}
              </div>
              
            </div>
          </div>
        </div>
        {this.props.backdrop && this.props.isOpen ? (
          <div className="modal-backdrop fade show" />
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapDispatchToProps = {
  createTutorialFormSubmit: createTutorialForm.submit
};
const defaultProps = {
  disableOuterClick: false,
  backdrop: true,
  isOpen: false,
  handleHide: () => {},
  withFooter: true,
  withHeader: true,
  footer: null,
  modalClass: "modal",
  modalDialogClass: "modal-dialog modal-dialog-centered",
  modalContentClass: "modal-content",
  showCloseBtn: true,
  closeBtnType: "cross"
};

const mapStateToProps = state => {
  return {
    isAnyModalOpen: state.modals.isAnyModalOpen,
    userId: (state.user && state.user.item && state.user.item.id) || 0,
    createTutorialForm: state.forms.createTutorialForm
  };
};

ModalOnboarding.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(ModalOnboarding);
