import io from "socket.io-client";
import { SOCKET_URL } from "./services/config/apiEndpoints";

export default io(SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ["websocket"]
});

// export default io('http://192.168.0.40:5000',{
//       reconnection: true,
//       reconnectionDelay: 1000,
//       reconnectionDelayMax: 5000,
//       reconnectionAttempts: Infinity,
//       transports: ["websocket"]
//     }
//   );
