import React, { Component } from "react";
import Swiper from "./Swiper";
import "swiper/css/swiper.css";
import UpdateDimension from "../Utils/UpdateDimension";

let executedOnce = false;

class SwiperContainer extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {
    this.swiper.updateSwiper();
    if (this.props.shouldSlideTo) {
      if (!executedOnce) {
        this.swiper.swiper.slideTo(0);
        executedOnce = true;
      }
    }
  }
  render() {
    const { children, ...rest } = this.props;
    return (
      <>
        <UpdateDimension onUpdate={this.updateDimensions}>
          <Swiper
            {...rest}
            ref={swiper => {
              this.swiper = swiper;
            }}
          >
            {children}
          </Swiper>
        </UpdateDimension>
      </>
    );
  }
}
SwiperContainer.defaultProps = {
  shouldSlideTo: true
};

export default SwiperContainer;
