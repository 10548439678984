import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import i18n from "../../../../i18n";
import { leftSidebar } from "../../../../actions/sidebars";

import {
  login,
  register,
  // createNameAndAvatar,
  createName,
  createAvatar,
  createTutorial,
  activateUserModal,
  modalCloseAll
} from "../../../../actions/modals";
import closePopupImg from "../../../../assets/images/svg/nav-close.svg";
import MenuIcon from "../../../../assets/images/svg/nav-menu.svg";
import LeftSidebarWithoutLogin from "../../sidebars/LeftSidebar/LeftSidebarWithoutLogin";
import { logoutUserIfNeeded } from "../../../../actions/user";
import { languagesList } from "../../../../actions/profiles";
import {
  toggleHyveLogin,
  toggleHyveRegister
} from "../../../../actions/toggles";
import Select2ComponentId from "../../../../components/Select2/Select2ComponentId";
import { isNgCountry, isEnabledLanguageChange } from "../../../../utils/misc";
import platformParams from "../../../../utils/platformParams";

const RedirectLang = props => {
  const lang = i18n.language;
  const { location } = props;

  const selectLanguageOptions = [
    { id: 1, value: "en" },
    { id: 2, value: "fr" }
  ];
  let pathNames = location.pathname;
  const changeLanguageI18 = lng => {
    if (pathNames.search("/" + lang + "/") > -1) {
      pathNames = pathNames.replace("/" + lang + "/", "/" + lng + "/");
    } else {
      pathNames = pathNames.replace("/" + lang, "/" + lng);
    }
    props.history.push(pathNames);
  };
  return (
    <>
      <span className="a nav-link" style={{ width: "135px" }}>
        <Select2ComponentId
          selectOptions={selectLanguageOptions}
          onChange={event => {
            changeLanguageI18(event.text);
            localStorage.setItem("language", event.id);
          }}
          selectedValue={lang.toUpperCase()}
          labelText="lang"
        />
      </span>
    </>
  );
};
class TopNavBarWithoutLogin extends Component {
  constructor(props) {
    super(props);
    this.showLeftSidebar = this.showLeftSidebar.bind(this);
    this.hideLeftSidebar = this.hideLeftSidebar.bind(this);
  }
  showLeftSidebar() {
    this.props.leftSidebarOpen();
  }
  hideLeftSidebar() {
    this.props.leftSidebarClose();
  }

  render() {
    const { t } = this.props;
    const lang = i18n.language;
    let envClass = platformParams['className'];
    return (
      <>
        <header>
          <nav
            className={
              "navbar navbar-expand-lg navbar-light bg-miggsterBGDarkGrey fixed-top" +
              (this.props.isAnyModalOpen ? " isAnyModalOpen" : "")
            }
          >
            <div className="container">
              <span
                className="icon-menu d-block d-lg-none left-sidebar-collapse"
                onClick={this.showLeftSidebar}
              >
                <img src={MenuIcon} alt="" />
              </span>
              <Link to={"/" + lang} className="navbar-brand d-flex">
                <img
                  src={platformParams['headerLogoSvg']}
                  alt=""
                  className="mainLogo"
                />
              </Link>
              <span style={{ width: "20px" }}>
                <img
                  alt=""
                  className={
                    this.props.isAnyModalOpen ? " d-block d-lg-none" : " d-none"
                  }
                  src={closePopupImg}
                  onClick={this.props.modalCloseAll}
                />
              </span>
              <ul className="navbar-nav d-none d-lg-flex align-items-center">
                <li className={this.props.match.path === "/" + lang + "/about-us" ? "nav-item active" : "nav-item"}>
                  <Link
                    to={"/" + lang + "/about-us"}
                    className={"nav-link " + envClass}
                  >
                    {t("navAboutUs")}
                  </Link>
                </li>
                {
                  !isNgCountry() ?
                    <li className={this.props.location.hash === "#giveaways" ? "nav-item active" : "nav-item"}>
                      <a
                        href={"/" + lang + "/#giveaways"}
                        className={"nav-link " + envClass}
                      >
                        {t("giveaways")}
                      </a>
                    </li>
                    : <></>
                }
                <li className={this.props.location.hash === "#tournaments" ? "nav-item active" : "nav-item"}>                 
                  <a
                    href={"/" + lang + "/#tournaments"}
                    className={"nav-link " + envClass}
                  >
                    {t("navTournaments")}
                  </a>
                </li>

                <li className={this.props.match.path === "/" + lang + "/news" ? "nav-item active" : "nav-item"}>
                  <a
                    href={"/" + lang + "/news"}
                    className={"nav-link " + envClass}
                  >
                    {t("navNews")}
                  </a>
                </li>
                <li className={this.props.match.path === "/" + lang + "/contact-us" ? "nav-item active" : "nav-item"}>
                  <a
                    href={"/" + lang + "/contact-us"}
                    className={"nav-link " + envClass}
                  >
                    {t("navContactUs")}
                  </a>
                </li>
                {!this.props.isLoggedIn ? (
                  <>
                    {
                      process.env.REACT_APP_PLATFORM != "arcadex" ?
                        <li className="nav-item">
                          <a
                            href={"/" + lang + "/billing-login"}
                            className={"nav-link " + envClass}
                          >
                            {t("signin")}
                          </a>
                        </li>
                      :
                        <></>
                    }
                  </>
                ) : (
                    <li className="nav-item">
                      <span
                        className="a nav-link"
                        onClick={() => {
                          this.props.logoutUserIfNeeded();
                        }}
                      >
                        Sign out
                    </span>
                    </li>
                  )}
                {
                    isEnabledLanguageChange() ?
                        <li className="nav-item">
                            <RedirectLang {...this.props} />
                        </li>
                    : <></>
                }

                {/* <button
                  className="btn btn-pink light btn-xs ml-3"
                  onClick={() => {
                    this.props.resetHyveRegister();
                    this.props.registerOpen();
                  }}
                >
                  {t("joinNow")}
                </button> */}
                {/* <a
                  href="javascript:void(0)"
                  className={"btn btn-" +  platformParams['baseButtonClassSufix'] + " light ml-3"}
                  onClick={() => {
                   this.props.history.push("/en#join-now");
                  }}
                >
                  {t("joinNow")}
                </a> */}

                <a
                  href="https://staging.miggster.join.arcadex.co"
                  className={"btn btn-" +  platformParams['baseButtonClassSufix'] + " light ml-3"}
                >
                  {t("joinNow")}
                </a>

                {/* <button
                  className="btn btn-pink light btn-xs ml-3"
                  onClick={() => {
                    this.props.resetHyveRegister();
                    this.props.toggleHyveRegister();
                    this.props.registerOpen();
                  }}
                >
                  Join Hyve!
                </button> */}
              </ul>
            </div>
          </nav>
          <LeftSidebarWithoutLogin />
        </header>
      </>
    );
  }
}

const mapDispatchToProps = {
  leftSidebarOpen: leftSidebar.open,
  leftSidebarClose: leftSidebar.close,
  loginOpen: login.open,
  registerOpen: register.open,
  createNameOpen: createName.open,
  // createNameAndAvatarOpen: createNameAndAvatar.open,
  createAvatarOpen: createAvatar.open,
  createTutorialOpen: createTutorial.open,
  logoutUserIfNeeded,
  modalCloseAll: modalCloseAll,
  toggleHyveLogin: toggleHyveLogin.toggle,
  resetHyveLogin: toggleHyveLogin.reset,
  toggleHyveRegister: toggleHyveRegister.toggle,
  resetHyveRegister: toggleHyveRegister.reset,
  activateUserModalOpen: activateUserModal.open,
  activateUserModalClose: activateUserModal.close,
  fetchLanguagesIfNeeded: languagesList.fetchIfNeeded,
  invalidateLanguagesIfNeeded: languagesList.invalidate
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    isAnyModalOpen: state.modals.isAnyModalOpen,
    currentLang: state.login.currentLang,
    languageslist: state.profiles.languagesList.items
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(TopNavBarWithoutLogin))
);
