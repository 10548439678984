import React, { Component } from "react";
import "./ModalERROR.scss";
import { withRouter } from "react-router";

class ModalERROR extends Component {
  constructor(props) {
    super(props);
    // this.hideProxy = this.hideProxy.bind(this);
    // this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      isPortrait: false
    };
  }
  // componentWillMount() {
  //   if (!this.props.disableOuterClick) {
  //     document.addEventListener("mousedown", this.hideProxy, false);
  //   }
  // }
  // componentWillUnmount() {
  //   if (!this.props.disableOuterClick) {
  //     document.removeEventListener("mousedown", this.hideProxy, false);
  //   }
  // }
  // updateDimensions() {
  //   this.setState({
  //     isPortrait: window.innerHeight >= 530 && window.innerWidth > 320
  //   });
  // }
  componentDidMount() {
    // this.updateDimensions();
  }
  // hideProxy = e => {
  //   if (this.props.disableOuterClick) {
  //     return;
  //   }
  //   if (
  //     (this.modalWeb && this.modalWeb.contains(e.target)) ||
  //     (this.modalMobile && this.modalMobile.contains(e.target))
  //   ) {
  //     return;
  //   }
  //   if (this.props.isOpen) {
  //     this.props.handleHide();
  //   }
  //   return;
  // };

  render() {
    return (
      <>
        <div
          className={"modal error-popup"}
          tabIndex="-1"
          style={this.props.isOpen ? { display: "block" } : { display: "none" }}
        >
          <div
            className="modal-dialog modal-custom modal-dialog-centered"
            ref={modal => {
              this.modalWeb = modal;
            }}
          >
            <div className="modal-content">
              <div className={this.props.modalBodyClass}>
                <div className="error-message px-custom-8 d-flex justify-content-center align-items-center flex-column text-center">
                  {/* <img
                    src={ErrorIcon}
                    className="img-fluid"
                    width="50px"
                    alt=""
                  /> */}
                  <h3 className="text-mtnThemeSecondaryColor bold font-size-30 my-3">
                    Oh Snap!
                  </h3>
                  <div className="semibold font-size-21 mb-0">
                    {this.props.children}
                  </div>
                </div>
              </div>
              <div
                className="modal-footer bg-modal-mtn-main-color d-flex justify-content-center a"
                onClick={() => {
                  this.props.redirectTo &&
                    this.props.history.push(this.props.redirectTo);
                  this.props.handleHide();
                }}
              >
                <span className="text-white bold font-size-25">OK</span>
              </div>
            </div>
          </div>
        </div>
        {this.props.backdrop && this.props.isOpen ? (
          <div className="modal-backdrop fade show" />
        ) : (
          ""
        )}
      </>
    );
  }
}
const defaultProps = {
  disableOuterClick: false,
  backdrop: true,
  isOpen: false,
  handleHide: () => {},
  modalClass: "modal",
  modalDialogClass: "modal-dialog modal-dialog-centered",
  modalContentClass: "modal-content",
  modalBodyClass: "modal-body",
  modalCrossSvgClass: false,
  showCloseBtn: true,
  withError: false,
  showSocialBtns: true,
  redirectTo: null
};
ModalERROR.defaultProps = defaultProps;
export default withRouter(ModalERROR);
